import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { standarPrice } from "../../../utils/standard";
import { Button } from "reactstrap";

function HeaderStep4(props) {
  const { title1, title2, title3, text1, text2, text3, text4, text5 } =
    translate.step4.header;
  const { customer1 } = translate.step2;
  const { id, total, typeClient, handleClose } = props;

  return (
    <div className="s4-headerStep4-container">
      <div className="s4-headerStep4-img">
        <img src="/img/prepaiment-step4.webp" alt="" />
      </div>
      <div className="s4-headerStep4-text">
        {typeClient === customer1 ? (
          <>
            <h5>{text3}</h5>
            <p>{text4}</p>
          </>
        ) : (
          <>
            <h5>{title1}</h5>
            <p>
              {text1} {text2}
            </p>
          </>
        )}
        <div className="s4-headerStep4-div">
          <div className="s4-headerStep4-card">
            <div>
              <img src="/icon/tag.svg" alt="" />
              <p>{title2}</p>
            </div>
            <div className="s4-headerStep4-hr"></div>
            <div>
              <span>{id}</span>
            </div>
          </div>
          <div className="s4-headerStep4-card">
            <div>
              <img src="/icon/refresh.svg" alt="" />
              <p>{title3}</p>
            </div>
            <div className="s4-headerStep4-hr"></div>
            <div>
              <span>
                1 USD = {standarPrice(total.CurrencyConverted)}{" "}
                {total.CurrencyCode}
              </span>
            </div>
          </div>
        </div>
        <Button color="primary" className="step4-cancel" onClick={handleClose}>
          {text5}
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    typeClient: state.step2.typeClient,
  };
};

export default connect(mapStateToProps)(HeaderStep4);
