import { useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import DropdownFlame from "../atoms/dropdownFlame";
import translate from "../../../translates/translations";
import {
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { filterCompany } from "../../../redux/actions/step1";
import { resetFleet, setFleet } from "../../../redux/actions/filter";

function FilterFleet(props) {
  const { title, text1, text2, text3, item3_1, item3_2, button1 } =
    translate.step1.filter;
  const {
    filterCompany,
    resetFleet,
    setFleet,
    selectGroup,
    selectPassenger,
    fleet,
  } = props;
  const [transmission, setTransmission] = useState("");
  const [car, setCar] = useState("");
  const [passenger, setPassenger] = useState("");
  const [checked, setChecked] = useState({
    Avis: false,
    Budget: false,
  });

  const infoCar = {
    title: text1,
    items: selectGroup,
  };
  const infoTransmission = {
    title: text3,
    items: [item3_1, item3_2],
  };
  const infoPassenger = {
    title: text2,
    items: selectPassenger,
  };

  const byGroup = (group, fleet) => {
    const newFleet = [];
    fleet.map((auto) => {
      for (let key in auto) {
        if (auto[key].VehGroup === group.replace("Grupo ", "")) {
          newFleet.push(auto);
          break;
        }
      }
    });
    return setFleet(newFleet);
  };

  const byTransmition = (transmition, fleet) => {
    const newFleet = [];
    fleet.map((auto) => {
      for (let key in auto) {
        if (auto[key].TransmissionType === transmition) {
          newFleet.push(auto);
        }
      }
    });
    return setFleet([...new Set(newFleet)]);
  };

  const byPassenger = (passenger, fleet) => {
    const newFleet = [];
    fleet.map((auto) => {
      for (let key in auto) {
        if (
          auto[key].Features2.seats === passenger.replace(" passageiros", "")
        ) {
          newFleet.push(auto);
        }
      }
    });
    return setFleet([...new Set(newFleet)]);
  };

  const handleTransmission = (e) => {
    setTransmission(e);
    const transmition = e.value === item3_1 ? "Automatic" : "Manual";
    byTransmition(transmition, fleet);
  };
  const handleCar = (e) => {
    setCar(e);
    byGroup(e.value, fleet);
  };
  const handlePassenger = (e) => {
    setPassenger(e);
    byPassenger(e.value, fleet);
  };

  const handleOnChange = (check, name, reset) => {
    if (check) {
      filterCompany(name);
      setChecked((prev) => ({
        [name]: true,
        [reset]: false,
      }));
    } else {
      filterCompany("Both");
      setChecked((prev) => ({
        ...prev,
        [name]: false,
        [reset]: false,
      }));
    }
  };

  const reset = () => {
    document.getElementById("rates").reset();
    resetFleet();
    setTransmission("");
    setCar("");
    setPassenger("");
    handleOnChange(false, "Avis", "Budget");
  };

  return (
    <Container fluid className="s1-filter">
      <Row>
        <Col
          sm="6"
          md="2"
          className="d-flex align-items-center p-0 s1-filter-hr"
        >
          <h6>{title}</h6>
        </Col>
        <Col
          sm="6"
          md="2"
          className="d-flex justify-content-between flex-column p-0 checkButtons-Container"
        >
          <div className="d-flex align-items-start my-2 checkboxcustom">
            <FormGroup check className="m-0">
              <Label check>
                <Input
                  type="checkbox"
                  onClick={(e) =>
                    handleOnChange(e.target.checked, "Avis", "Budget")
                  }
                  checked={checked.Avis}
                />
                <span className="form-check-sign"></span>
              </Label>
            </FormGroup>
            <span>Avis</span>
          </div>
          <div className="d-flex align-items-start my-2 checkboxcustom">
            <FormGroup check className="m-0">
              <Label check>
                <Input
                  type="checkbox"
                  onClick={(e) =>
                    handleOnChange(e.target.checked, "Budget", "Avis")
                  }
                  checked={checked.Budget}
                />
                <span className="form-check-sign"></span>
              </Label>
            </FormGroup>
            <span>Budget</span>
          </div>
        </Col>
        <Col
          sm="6"
          md="4"
          className="d-flex justify-content-between flex-column p-0 pl-2 selectGruop1-Filters"
        >
          <DropdownFlame
            info={selectGroup && infoCar}
            handle={handleCar}
            state={car}
            className="filter1"
          />
          <DropdownFlame
            info={selectPassenger && infoPassenger}
            handle={handlePassenger}
            state={passenger}
            className="filter2"
          />
        </Col>
        <Col
          sm="6"
          md="4"
          className="d-flex justify-content-between flex-column p-0 pl-2 selectGruop2-Filters"
        >
          <DropdownFlame
            info={infoTransmission}
            handle={handleTransmission}
            state={transmission}
            className="filter3"
          />
          <Button color="primary" className="m-0" onClick={() => reset()}>
            {button1}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    place_pickup: state.home.place_pickup,
    place_dropoff: state.home.place_dropoff,
    info_user: state.home.info_user,
    fleet: state.filter.fleet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterCompany: (payload) => dispatch(filterCompany(payload)),
    resetFleet: () => dispatch(resetFleet()),
    setFleet: (payload) => dispatch(setFleet(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterFleet);
