import { useState, useEffect } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { to } from "../../../utils/redirect";
import translate from "../../../translates/translations";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { handleError } from "../../../utils/error";
import { pay, installments, saleStatus } from "../../../services/api";
import pagarme from "pagarme";
import { Label, FormGroup, Input, Container, Row, Col } from "reactstrap";
import Cleave from "cleave.js/react";
import { lastNumber } from "../../../redux/actions/step4";
import { changeSaleStatus } from "../../../redux/actions/step4";
import ButtonLoader from "../atoms/ButtonLoader";

function CreditCard(props) {
  const {
    price,
    type,
    id,
    company,
    lastNumber,
    changeSaleStatus,
    clientType,
    prepaid,
  } = props;
  const {
    title,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text9,
    button1,
    text10,
    text11,
    text12,
    text13,
  } = translate.step4.opcion2;
  const { error12, error13, error22 } = translate.general.error;
  let history = useHistory();
  const [chooseCity, setChooseCity] = useState("");
  const [parcelas, setParcelas] = useState("");
  const [renderDues, setRenderDues] = useState([]);
  const [loader, setLoader] = useState(false);
  const { customer3 } = translate.step2;
  const isAgency = customer3 === clientType;
  const { conditions1, conditions2 } = translate.step4;
  const [accept, setAccept] = useState(true);
  const handleClick = () => setAccept(!accept);
  const pdf = prepaid === 1 ? to.pre : to.pod;

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm();

  const watch_card_number = watch("card");
  const watch_card = watch_card_number && watch_card_number.replaceAll(" ", "");

  const key = process.env.REACT_APP_PAGARME;

  useEffect(() => {
    if (watch_card) {
      if (watch_card.length === 16) {
        const dues = {
          language: "pt",
          card_number: watch_card,
          amount: isAgency
            ? type === "net"
              ? price.EstimatedTotalAmountPrepaidNetoCCTax
              : price.EstimatedTotalAmountPrepaid
            : price.EstimatedTotalAmountPrepaid,
          currency: price.CurrencyCode,
        };
        getDues(dues);
      }
    }
  }, [watch_card, type]);

  const getDues = (dues) => {
    installments(dues)
      .then((response) => response.data)
      .then((res) => tranform(res))
      .catch((error) => console.log(error, "mal"));
  };

  const tranform = async (array) => {
    const render = await array.map((obj) => {
      obj.label = obj.description;
      obj.value = obj.installments;
      return obj;
    });
    await setRenderDues(render);
  };

  const formCredit = (data) => {
    setLoader(true);

    const number = data.card.replaceAll(" ", "");

    const card = {
      card_holder_name: data.name,
      card_cvv: data.cvv,
      card_expiration_date: data.ven,
      card_number: number,
    };
    const dataForm = {
      language: "pt",
      id_reservation: id.id,
      passenger_lastname: id.last_name,
      card_holder_name: data.name,
      card_holder_id: data.cpf,
      card_holder_email: data.email,
      card_holder_phone_number: data.numero,
      card_holder_street: data.address,
      card_holder_number: data.addressNum,
      card_holder_state: data.estado,
      card_holder_city: data.city,
      card_holder_zipcode: data.cep,
      amount: parcelas.total_amount,
      currency: price.CurrencyCode,
      rate: price.CurrencyConverted,
      type: type,
      installments: parcelas.installments,
    };
    const cardValidations = pagarme.validate({ card: card });
    const array = Object.values(cardValidations.card);
    const even = (element) => element === false;
    const validation = array.some(even);
    if (!validation) {
      payment(dataForm, card);
    } else {
      handleError(error13, 1);
      setLoader(false);
    }
  };

  const payment = (form, card) => {
    pagarme.client
      .connect({ encryption_key: key })
      .then((client) => client.security.encrypt(card))
      .then((card_hash) => {
        pay({ card_hash, ...form })
          .then((response) => response.data)
          .then((data) => {
            if (data.message === "Pagamento com sucesso") {
              handleError(data.message, 2);
              lastNumber(data.card_data.last4);
              next();
            } else {
              handleError(data.message, 1);
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error.response?.status === 500) {
              handleError(
                error.response.data?.exception?.message || error22,
                1
              );
            } else {
              handleError(error22, 1);
            }
            console.log(error.response.data);
            setLoader(false);
          });
      })
      .catch((error) => {
        console.log(error, "error encriptacion");
        setLoader(false);
        handleError(error22, 1);
      });
  };

  const next = () => {
    setLoader(false);

    const body = {
      passenger_lastname: id.last_name,
      id_reservation: id.id,
      company: company === 2 ? "Budget" : "Avis",
      language: "pt",
      gateway_id: 1,
      type: type,
    };
    saleStatus(body)
      .then((response) => response.data)
      .then((res) => {
        if (res === "estado cambiado") {
          changeSaleStatus(body.gateway_id);
          history.push(to.step5);
        } else {
          handleError(error12, 1);
        }
      })
      .catch((error) => {
        handleError(error12, 1);
        console.log(error);
      });
  };

  const maxControl = (e) => {
    if (e.target.value.length > e.target.maxLength)
      e.target.value = e.target.value.slice(0, e.target.maxLength);
  };

  return (
    <Container fluid className="s4-CreditCard-container">
      <Row>
        <Col>
          <h6>{title}</h6>
        </Col>
      </Row>
      <Row>
        <form
          key={1}
          onSubmit={handleSubmit(formCredit)}
          id="formCredit"
          className="w-100"
        >
          <Container fluid className="s4-CreditCard-inputs">
            <Row>
              <Col sm={12} md={6} className="mb-3 mb-md-0">
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      placeholder={text3}
                      onChange={onChange}
                      value={value}
                      className={errors.name && "help"}
                      autoComplete="off"
                    />
                  )}
                />
              </Col>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="cpf"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: { value: 11, message: "Minímo 11 digítos" },
                    maxLength: { value: 11, message: "Maxímo 11 digítos" },
                    pattern: { value: /\d/gm, message: "Debe ser un número" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="number"
                      maxlength="11"
                      onInput={(e) => maxControl(e)}
                      placeholder={text1}
                      onChange={onChange}
                      value={value}
                      className={errors.cpf && "help"}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.cpf && <span>{errors.cpf.message}</span>}
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Debe ser un email valido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      placeholder="E-mail"
                      onChange={onChange}
                      value={value}
                      className={errors.email && "help"}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.email && <span>{errors.email.message}</span>}
              </Col>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="numero"
                  control={control}
                  rules={{
                    required: true,
                    minLength: { value: 11, message: "Minímo 11 digítos" },
                    maxLength: { value: 14, message: "Maxímo 11 digítos" },
                    pattern: {
                      value: /\d/gm,
                      message: "Debe ser un número valido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Cleave
                      maxlength="13"
                      onInput={(e) => maxControl(e)}
                      options={{ prefix: "+55", numericOnly: true }}
                      onChange={onChange}
                      value={value}
                      autoComplete="off"
                      className={`form-control ${errors.numero && "help"}`}
                    />
                  )}
                />
                {errors.numero && <span>{errors.numero.message}</span>}
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      placeholder={text9}
                      onChange={onChange}
                      value={value}
                      className={errors.address && "help"}
                      autoComplete="off"
                    />
                  )}
                />
              </Col>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="addressNum"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: { value: 1, message: "Minímo 1 digítos" },
                    maxLength: { value: 5, message: "Maxímo 5 digítos" },
                    pattern: {
                      value: /\d/gm,
                      message: "Debe ser un número válido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="number"
                      maxlength="5"
                      onInput={(e) => maxControl(e)}
                      placeholder={text10}
                      onChange={onChange}
                      value={value}
                      className={errors.addressNum && "help"}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.addressNum && <span>{errors.addressNum.message}</span>}
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="estado"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  value={chooseCity}
                  render={() => (
                    <Select
                      className={`react-select react-select-info mt-2 ${
                        errors.estado && "help8"
                      }`}
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      placeholder={text11}
                      value={chooseCity}
                      onChange={(e) => {
                        setValue("estado", e.value);
                        setChooseCity(e);
                      }}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                      options={[
                        { label: "Acre", value: "ac" },
                        { label: "Alagoas", value: "al" },
                        { label: "Amapá", value: "ap" },
                        { label: "Amazonas", value: "am" },
                        { label: "Bahia", value: "ba" },
                        { label: "Ceará", value: "ce" },
                        { label: "Distrito Federal", value: "df" },
                        { label: "Espírito Santo", value: "es" },
                        { label: "Goiás", value: "go" },
                        { label: "Maranhão", value: "ma" },
                        { label: "Mato Grosso", value: "mt" },
                        { label: "Mato Grosso do Sul", value: "ms" },
                        { label: "Minas Gerais", value: "mg" },
                        { label: "Paraná", value: "pr" },
                        { label: "Paraíba", value: "pb" },
                        { label: "Pará", value: "pa" },
                        { label: "Pernambuco", value: "pr" },
                        { label: "Piauí", value: "pi" },
                        { label: "Rio Grande do Norte", value: "rn" },
                        { label: "Rio Grande do Sul", value: "rs" },
                        { label: "Rio de Janeiro", value: "rj" },
                        { label: "Rondônia", value: "ro" },
                        { label: "Roraima", value: "rr" },
                        { label: "Santa Catarina", value: "sc" },
                        { label: "Sergipe", value: "se" },
                        { label: "São Paulo", value: "sp" },
                        { label: "Tocantins", value: "to" },
                      ]}
                    />
                  )}
                />
              </Col>
              <Col md="4" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      placeholder={text12}
                      onChange={onChange}
                      value={value}
                      className={errors.city && "help"}
                      autoComplete="off"
                    />
                  )}
                />
              </Col>
              <Col md="4" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="cep"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: { value: 8, message: "Minímo 8 digítos" },
                    pattern: {
                      value: /\d/gm,
                      message: "Debe ser un número valido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      maxlength="8"
                      onInput={(e) => maxControl(e)}
                      placeholder={text13}
                      onChange={onChange}
                      value={value}
                      className={errors.cep && "help"}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.cep && <span>{errors.cep.message}</span>}
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="card"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: { value: 16, message: "Minímo 16 digítos" },
                    maxLength: { value: 19, message: "Maxímo 16 digítos" },
                    pattern: {
                      value: /\d/gm,
                      message: "Debe ser un número valido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Cleave
                      placeholder={text2}
                      onChange={onChange}
                      value={value}
                      autoComplete="off"
                      options={{ creditCard: true }}
                      className={`form-control ${errors.card && "help"}`}
                    />
                  )}
                />
                {errors.card && <span>{errors.card.message}</span>}
              </Col>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="ven"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: { value: 5, message: "Minímo 4 digítos" },
                    maxLength: { value: 5, message: "Maxímo 4 digítos" },
                    pattern: {
                      value: /\d/gm,
                      message: "Debe ser un número valido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Cleave
                      placeholder={text5}
                      options={{ date: true, datePattern: ["m", "y"] }}
                      onChange={onChange}
                      value={value}
                      autoComplete="off"
                      className={`form-control ${errors.ven && "help"}`}
                    />
                  )}
                />
                {errors.ven && <span>{errors.ven.message}</span>}
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="cvv"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: { value: 3, message: "Minímo 3 digítos" },
                    maxLength: { value: 4, message: "Maxímo 4 digítos" },
                    pattern: {
                      value: /\d/gm,
                      message: "Debe ser un número valido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="number"
                      maxlength="4"
                      onInput={(e) => maxControl(e)}
                      placeholder={text6}
                      onChange={onChange}
                      value={value}
                      className={errors.cvv && "help"}
                    />
                  )}
                />
                {errors.cvv && <span>{errors.cvv.message}</span>}
              </Col>
              <Col md="6" sm="12" className="mb-3 mb-md-0">
                <Controller
                  name="parcelas"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={() => (
                    <Select
                      className={`react-select react-select-info mt-2 ${
                        errors.parcelas && "help8"
                      }`}
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      placeholder={text4}
                      value={parcelas}
                      onChange={(e) => {
                        setValue("parcelas", e.installments);
                        setParcelas(e);
                      }}
                      options={renderDues}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="mb-0">
              <Col sm={12} md={6}>
                <div className="s2-confirm-checkbox align-items-start">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        onClick={(e) => handleClick(e)}
                        id="checkbox-validation"
                        checked={!accept}
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                  <p className="checkbox-p">
                    {conditions1}{" "}
                    <a href={pdf} target="blank" className="s2-confirm-link">
                      {conditions2}
                    </a>
                    .
                  </p>
                </div>
              </Col>
              <Col md="6" sm="12" className="buttons4Payment">
                <ButtonLoader
                  loader={loader}
                  text={button1}
                  disabled={accept}
                  submit="submit"
                  form="formCredit"
                />
              </Col>
            </Row>
          </Container>
        </form>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    price: state.step4.data.car.TotalCharge,
    type: state.step4.type,
    id: state.step4.reservation,
    company: state.step1.companySelected,
    clientType: state.step2.typeClient,
    prepaid: state.step4.data.reservation.prepaid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    lastNumber: (payload) => dispatch(lastNumber(payload)),
    changeSaleStatus: (payload) => dispatch(changeSaleStatus(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
