import {
  TYPE_CLIENT,
  PASSENGER,
  AGENCY,
  CORPORATION,
  VALIDATION,
  WIZARD,
  CUPOM,
  REGARDS_NUMBER,
  DISCOUNT_NUMBER,
  ADD_EQUIPMENT,
  TOTAL_TAX,
  TOTAL_CHARGE,
  TAXES,
  USER_ID,
  LOADER_STEP2,
  SET_PARTNER,
  CLEAR_STEP2,
} from "../actions/step2";

const initialState = {
  typeClient: "",
  passenger: "",
  agency: "",
  corporation: "",
  validation: false,
  wizard: "",
  cupom: "",
  regardsNumber: "",
  discountNumber: "",
  optionalEquipment: [],
  totalTax: "",
  totalCharge: "",
  taxes: [],
  userId: "",
  loaderStep2: false,
  partner: "",
};

const step2 = (state = initialState, action) => {
  switch (action.type) {
    case TYPE_CLIENT: {
      return {
        ...state,
        typeClient: action.payload,
      };
    }
    case SET_PARTNER: {
      return {
        ...state,
        partner: action.payload,
      };
    }
    case PASSENGER: {
      return {
        ...state,
        passenger: action.payload,
      };
    }
    case AGENCY: {
      return {
        ...state,
        agency: action.payload,
      };
    }
    case CORPORATION: {
      return {
        ...state,
        corporation: action.payload,
      };
    }
    case VALIDATION: {
      return {
        ...state,
        validation: true,
      };
    }
    case WIZARD: {
      return {
        ...state,
        wizard: action.payload,
      };
    }
    case CUPOM: {
      return {
        ...state,
        cupom: action.payload,
      };
    }
    case REGARDS_NUMBER: {
      return {
        ...state,
        regardsNumber: action.payload,
      };
    }
    case DISCOUNT_NUMBER: {
      return {
        ...state,
        discountNumber: action.payload,
      };
    }
    case ADD_EQUIPMENT: {
      return {
        ...state,
        optionalEquipment: action.payload,
      };
    }
    case TOTAL_TAX: {
      return {
        ...state,
        totalTax: action.payload,
      };
    }
    case TOTAL_CHARGE: {
      return {
        ...state,
        totalCharge: {
          ...state.totalCharge,
          ...action.payload,
        },
      };
    }
    case TAXES: {
      return {
        ...state,
        taxes: action.payload,
      };
    }
    case USER_ID: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case LOADER_STEP2: {
      return {
        ...state,
        loaderStep2: action.payload,
      };
    }
    case CLEAR_STEP2: {
      return {
        typeClient: "",
        passenger: "",
        agency: "",
        corporation: "",
        validation: false,
        wizard: "",
        cupom: "",
        regardsNumber: "",
        discountNumber: "",
        optionalEquipment: [],
        totalTax: "",
        totalCharge: "",
        taxes: [],
        userId: "",
        loaderStep2: false,
        partner: "",
      };
    }
    default: {
      return state;
    }
  }
};

export default step2;
