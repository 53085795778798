import { Container, Row, Col } from "reactstrap";
import translate from "../../../translates/translations";

function ModalPod(props) {
  const { handleClose } = props;
  const { title1, text1, text2 } = translate.modal.pod;

  return (
    <Container fluid className="modalpre-container fade-in">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100">
          <Container fluid className="modalpre">
            <Row className="d-flex justify-content-between w-100 modalpre-row1">
              <Col sm="10" className="w-100 p-0">
                <span>{title1}</span>
              </Col>
              <Col sm="2" className="d-flex justify-content-end cursor p-0">
                <img
                  src="/icon/x_black.svg"
                  alt=""
                  onClick={() => handleClose()}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="modalpre-img">
                <img
                  src="/img/imagen-modal.webp"
                  alt="friends"
                  width="100%"
                  height="auto"
                />
              </Col>
            </Row>
            <Row className="w-100 modalpre-row2">
              <Col sm="12" className="modalpre-text p-0">
                <div>
                  <p>
                    {text1} {text2}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalPod;
