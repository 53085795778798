import { connect } from "react-redux";
import Select from "react-select";

function DropdownFlame(props) {
  const { state, handle, info } = props;

  return (
    <Select
      className="react-select react-select-info mt-2"
      onChange={(value) => handle(value)}
      classNamePrefix="react-select"
      placeholder={info.title}
      value={state}
      name=""
      closeMenuOnSelect={true}
      isSearchable={false}
      options={info.items.map((item, i) => ({
        value: `${item}`,
        label: `${item}`,
        key: `${i}${item}`,
      }))}
    ></Select>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
  };
};

export default connect(mapStateToProps)(DropdownFlame);
