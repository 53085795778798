import RightNamePrice from "./rightNamePrice";
import { standarPrice } from "../../../utils/standard";

export default function CardPrice({ info }) {
  return (
    <div className="ge-CardPrice">
      <div>
        <h6>{info.title}</h6>
        <div className="mt-3">
          {info.items &&
            info.items.map((item, i) => (
              <RightNamePrice
                price={item.Amount}
                title={item.name}
                key={`${item.name}${i}`}
                currency={item.CurrencyCode}
              />
            ))}
        </div>
      </div>
      <div className="ge-CardPrice-total">
        <hr />
        <div>
          <h6>{info.total}</h6>
          <span>
            {info.currency} {info.price ? standarPrice(info.price) : "0.00"}
          </span>
        </div>
      </div>
    </div>
  );
}
