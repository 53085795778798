import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useState } from "react";
import translate from "../../../translates/translations";
import { useForm, Controller } from "react-hook-form";
import { handleError } from "../../../utils/error";
import { newsletter } from "../../../services/api";
import ButtonLoader from "../atoms/ButtonLoader";

function Subscribe() {
  const { text1, text2, text3, text4, button1 } =
    translate.general.footer.subscribe;
  const { error2 } = translate.general.error;
  const [loader, setLoader] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const formDubscribe = (data) => {
    setLoader(true);
    reset();
    newsletter({ email: data.email, full_name: data.nome })
      .then((res) => res.data)
      .then((response) => {
        handleError(text4, 2);
        setLoader(false);
        // console.log(response);
      })
      .catch((error) => {
        handleError(error2, 1);
        console.log(console.error);
        setLoader(false);
      });
  };

  return (
    <Container fluid className="m-0 p-0 ge-fo-su">
      <form
        key={1}
        onSubmit={handleSubmit(formDubscribe)}
        id="form-subscribe"
        className="w-100 "
      >
        <Row className="d-flex justify-content-center align-items-center w-100 m-0 flex-md-row flex-sm-column ">
          <Col
            sm="12"
            md="3"
            className="pl-0 newsLetter-Footer ge-fo-su__responsive subscribe-fixpadding"
          >
            <p className="m-0">{text1}</p>
          </Col>
          <Col
            sm="12"
            md="3"
            className="d-flex  ge-fo-su__responsive subscribe-fixpadding"
          >
            <div className={`w-100 ${errors.nome && "help2"}`}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="ge-in-img">
                    <img
                      src={"/icon/user-plus.svg"}
                      alt=""
                      className="ge-in-ico"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Controller
                  name="nome"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      placeholder={text2}
                      className="ge-in-pla"
                      name="nome"
                      onChange={onChange}
                      value={value}
                      type="text"
                      autoComplete="off"
                    />
                  )}
                />
              </InputGroup>
            </div>
          </Col>
          <Col sm="12" md="3" className="subscribe-fixpadding">
            <div className={errors.email && "help2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="ge-in-img">
                    <img src={"/icon/mail.svg"} alt="" className="ge-in-ico" />
                  </InputGroupText>
                </InputGroupAddon>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Debe ser un email válido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      placeholder={text3}
                      className="ge-in-pla"
                      name="email"
                      onChange={onChange}
                      value={value}
                      type="text"
                      autoComplete="off"
                    />
                  )}
                />
              </InputGroup>
            </div>
          </Col>
          <Col sm="12" md="3" className="pr-0 subscribe-fixpadding">
            <ButtonLoader
              form="form-subscribe"
              submit="submit"
              loader={loader}
              text={button1}
            />
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export default Subscribe;
