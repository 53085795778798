import React, { useState, useEffect } from "react"; // eslint-disable-line
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { to } from "./utils/redirect";

// Container

import Home from "./view/container/home";
import Step1 from "./view/container/step1";
import Step2 from "./view/container/step2";
import Step3 from "./view/container/step3";
import Step4 from "./view/container/step4";
import Step5 from "./view/container/step5";
import NavBar from "./view/components/molecules/navbar";
import Footer from "./view/components/organism/footer";
import Error from "./view/container/error";
import Offices from "./view/container/offices";
import FAQ from "./view/container/FAQ";
// import Cancelation from './view/container/cancelation';
// import Conditions from './view/container/conditions';
// import Cookies from './view/container/cookies';
// import Privacy from './view/container/privacy';
import Contact from "./view/container/contact";
import WaitingReserve from "./view/components/organism/waitingReserve";
import SearchReserve from "./view/components/organism/searchReserve";

function Routes() {
  return (
    <BrowserRouter>
      <Route path={to.home} render={() => <NavBar />} />
      <Route path={to.home} render={() => <SearchReserve />} />
      <Switch>
        <Route exact path={to.home} render={() => <Home />} />
        <Route exact path={to.parceiros} render={() => <Home />} />
        <Route exact path={to.step1} render={() => <Step1 />} />
        <Route exact path={to.step2} render={() => <Step2 />} />
        <Route exact path={to.step3} render={() => <Step3 />} />
        <Route exact path={to.step4} render={() => <Step4 />} />
        <Route exact path={to.step5} render={() => <Step5 />} />
        {/* <Route exact path={to.cancellationPolicy} render={() => <Cancelation />} />
        <Route exact path={to.privacyPolicy} render={() => <Privacy />} />
        <Route exact path={to.conditions} render={() => <Conditions />} />
        <Route exact path={to.cookies} render={() => <Cookies />} /> */}
        <Route exact path={to.contact} render={() => <Contact />} />
        <Route exact path={to.faq} render={() => <FAQ />} />
        <Route exact path={to.offices} render={() => <Offices />} />
        <Route exact path={to.reserve} render={() => <WaitingReserve />} />
        <Route path="*" render={() => <Error />} />
      </Switch>
      <Route path={to.home} render={() => <Footer />} />
    </BrowserRouter>
  );
}

export default Routes;
