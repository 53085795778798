
export const FLEET = 'FLEET';
export const RESET_FLEET = 'RESET_FLEET';
export const BACKUP_FLEET = 'BACKUP_FLEET';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export const setFleet = (payload) => {
    return {
        type: FLEET,
        payload,
    }
}

export const resetFleet = () => {
    return {
        type: RESET_FLEET,
    }
}

export const backupFleet = (payload) => {
    return {
        type: BACKUP_FLEET,
        payload,
    }
}

export const clear_filter = () => {
    return {
        type: CLEAR_FILTER
    }
}
