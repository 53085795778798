import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { DropdownItems } from "../atoms/DropdownItems";
import { conditions } from "../../../utils/conditions";

const leftIcon = (
  <svg
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="leftIcon"
  >
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);
const rightIcon = (
  <svg
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="rightIcon"
  >
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

export const DropDownAvisBudged = (props) => {
  const { openDropdown, setOpenDropdown } = props;

  const DropdownMenu = () => {
    const [activeMenu, setActiveMenu] = useState("main");
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);
    const [menuClasses, setMenuClasses] = useState("slide-in-top");

    //// Settea bien la altura del div para que haga bien la tranformacion////
    useEffect(() => {
      setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
    }, []);

    function calcHeight(el) {
      const height = el.offsetHeight;
      setMenuHeight(height);
    }

    //// Hook de click fuera del div ////
    function useOutsideHandler(refNode) {
      useEffect(() => {
        function handleClickOutside(event) {
          //handler
          if (refNode.current && !refNode.current.contains(event.target)) {
            setMenuClasses("slide-out-top");
            setTimeout(() => {
              setOpenDropdown();
            }, 300);
          }
        }

        // agrega el event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind el event listener
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [refNode]);
    }

    //llama a la funcion
    useOutsideHandler(dropdownRef);

    function DropdownItemRight(props) {
      return (
        <a
          href="#"
          className="menu-item"
          onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
        >
          {props.children}
          <span className="icon-right">{props.rightIcon}</span>
        </a>
      );
    }

    function DropdownItemLeft(props) {
      return (
        <a
          href="#"
          className="menu-item backButton"
          onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
        >
          <span className="icon-left">{props.leftIcon}</span>
          {props.children}
        </a>
      );
    }

    return (
      <div
        className={`dropdown ${menuClasses}`}
        style={{ height: menuHeight }}
        ref={dropdownRef}
      >
        <CSSTransition
          in={activeMenu === "main"}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <div className="separador">
              <DropdownItemRight rightIcon={rightIcon} goToMenu="regionsAvis">
                Avis
              </DropdownItemRight>
              <DropdownItemRight rightIcon={rightIcon} goToMenu="regionsBudget">
                Budget
              </DropdownItemRight>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "regionsAvis"}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="main" leftIcon={leftIcon}>
              Avis
            </DropdownItemLeft>
            <div className="separador">
              <DropdownItemRight
                rightIcon={rightIcon}
                goToMenu="avisLatinAmerica"
              >
                {conditions.avis.latinAmerica.name}
              </DropdownItemRight>
              <DropdownItemRight rightIcon={rightIcon} goToMenu="avisCaribe">
                {conditions.avis.caribbean.name}
              </DropdownItemRight>
              <DropdownItemRight rightIcon={rightIcon} goToMenu="avisEuropa">
                {conditions.avis.europe.name}
              </DropdownItemRight>
              <DropdownItems object={conditions.avis.Canada} />
              <DropdownItems object={conditions.avis.EstadosUnidos} />
              <DropdownItems object={conditions.avis.Israel} />
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "regionsBudget"}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="main" leftIcon={leftIcon}>
              Budget
            </DropdownItemLeft>
            <div className="separador">
              <DropdownItemRight
                rightIcon={rightIcon}
                goToMenu="budgetLatinAmerica"
              >
                {conditions.budget.latinAmerica.name}
              </DropdownItemRight>
              <DropdownItemRight rightIcon={rightIcon} goToMenu="budgetCaribe">
                {conditions.budget.caribbean.name}
              </DropdownItemRight>
              <DropdownItemRight rightIcon={rightIcon} goToMenu="budgetEuropa">
                {conditions.budget.europe.name}
              </DropdownItemRight>
              <DropdownItems object={conditions.budget.Canada} />
              <DropdownItems object={conditions.budget.EstadosUnidos} />
              <DropdownItems object={conditions.budget.Marrocos} />
            </div>
          </div>
        </CSSTransition>

        {/* ///////////////////////////////////////////////
                            AVIS MENUS //
                         ////////////// */}

        <CSSTransition
          in={activeMenu === "avisLatinAmerica"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="regionsAvis" leftIcon={leftIcon}>
              {conditions.avis.latinAmerica.name}
            </DropdownItemLeft>
            <div className="separador">
              {Object.entries(conditions.avis.latinAmerica).map(
                (
                  [key, data] //slice para que saltee el name
                ) => (
                  <DropdownItems key={data.name + key} object={data} />
                )
              )}
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "avisCaribe"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="regionsAvis" leftIcon={leftIcon}>
              {conditions.avis.caribbean.name}
            </DropdownItemLeft>
            <div className="separador">
              {Object.entries(conditions.avis.caribbean).map(
                (
                  [key, data] //slice para que saltee el name
                ) => (
                  <DropdownItems key={data.name + key} object={data} />
                )
              )}
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={activeMenu === "avisEuropa"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="regionsAvis" leftIcon={leftIcon}>
              {conditions.avis.europe.name}
            </DropdownItemLeft>
            <div className="separador">
              {Object.entries(conditions.avis.europe).map(
                (
                  [key, data] //slice para que saltee el name
                ) => (
                  <DropdownItems key={data.name + key} object={data} />
                )
              )}
            </div>
          </div>
        </CSSTransition>

        {/* ///////////////////////////////////////////////
                            BUDGET MENUS //
                            ////////////// */}

        <CSSTransition
          in={activeMenu === "budgetLatinAmerica"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="regionsBudget" leftIcon={leftIcon}>
              {conditions.budget.latinAmerica.name}
            </DropdownItemLeft>
            <div className="separador">
              {Object.entries(conditions.budget.latinAmerica).map(
                (
                  [key, data] //slice para que saltee el name
                ) => (
                  <DropdownItems key={data.name + key} object={data} />
                )
              )}
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "budgetCaribe"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="regionsBudget" leftIcon={leftIcon}>
              {conditions.budget.caribbean.name}
            </DropdownItemLeft>
            <div className="separador">
              {Object.entries(conditions.budget.caribbean).map(
                (
                  [key, data] //slice para que saltee el name
                ) => (
                  <DropdownItems key={data.name + key} object={data} />
                )
              )}
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={activeMenu === "budgetEuropa"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="menu">
            <DropdownItemLeft goToMenu="regionsBudget" leftIcon={leftIcon}>
              {conditions.budget.europe.name}
            </DropdownItemLeft>
            <div className="separador">
              {Object.entries(conditions.budget.europe).map(
                (
                  [key, data] //slice para que saltee el name
                ) => (
                  <DropdownItems key={data.name + key} object={data} />
                )
              )}
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  };

  return <DropdownMenu></DropdownMenu>;
};
