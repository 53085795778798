import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { to } from "../../../utils/redirect";
import translate from "../../../translates/translations";
import { Col, Container, Row, FormGroup, Label, Input } from "reactstrap";
import { saleStatus } from "../../../services/api";
import { handleError } from "../../../utils/error";
import { changeSaleStatus } from "../../../redux/actions/step4";
import ButtonLoader from "./ButtonLoader";

function Transfer(props) {
  const { company, data, type, changeSaleStatus, prepaid } = props;
  const { title, text1, text2, text3, text4, text5, button1 } =
    translate.step4.opcion1;
  const { error11 } = translate.general.error;
  const { conditions1, conditions2 } = translate.step4;
  const [accept, setAccept] = useState(true);
  const [loader, setLoader] = useState(false);
  const handleClick = () => setAccept(!accept);
  const pdf = prepaid === 1 ? to.pre : to.pod;

  let history = useHistory();

  const body = {
    passenger_lastname: data.last_name,
    id_reservation: data.id,
    company: company === 2 ? "Budget" : "Avis",
    language: "pt",
    gateway_id: 3,
    type: type,
  };

  const next = () => {
    setLoader(true);
    saleStatus(body)
      .then((response) => response.data)
      .then((res) => {
        if (res === "estado cambiado") {
          changeSaleStatus(body.gateway_id);
          history.push(to.step5);
        } else {
          handleError(error11, 1);
        }
        setLoader(false);
      })
      .catch((error) => {
        handleError(error11, 1);
        console.log(error);
        setLoader(false);
      });
  };

  return (
    <Container className="s4-Transfer-container">
      <Row className="w-100 m-0">
        <Col className="s4-Transfer-container-header">
          <h6>{title}</h6>
          <p>
            • {text1} <span>{text4}</span> {text5}
          </p>
          <p>• {text2}</p>
          <p>• {text3}</p>
        </Col>
      </Row>
      <Row className="w-100 mt-3 m-0">
        <Col sm={12} md={6} className="p-0">
          <div className="s2-confirm-checkbox align-items-start">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  onClick={(e) => handleClick(e)}
                  id="checkbox-validation"
                  checked={!accept}
                ></Input>
                <span className="form-check-sign"></span>
              </Label>
            </FormGroup>
            <p className="mb-0">
              {conditions1}{" "}
              <a href={pdf} target="blank" className="s2-confirm-link">
                {conditions2}
              </a>
              .
            </p>
          </div>
        </Col>
        <Col sm={12} md={6} className="alertButton-container">
          <ButtonLoader
            loader={loader}
            text={button1}
            disabled={accept}
            onClick={next}
          />
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    company: state.step1.companySelected,
    data: state.step4.reservation,
    type: state.step4.type,
    prepaid: state.step4.data.reservation.prepaid,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeSaleStatus: (payload) => dispatch(changeSaleStatus(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfer);
