import { connect } from "react-redux";
import ProgressBar from "../atoms/progressBar";
import translate from "../../../translates/translations";
import { to } from "../../../utils/redirect";
import { useHistory } from "react-router-dom";
import { clearStep2 } from "../../../redux/actions/step2";
import { clearStep1 } from "../../../redux/actions/step1";
import useScreenSize from "../../../utils/useScreenSize";

function StepProgress(props) {
  const { valueOne, valueTwo, valueThree, clearStep2 } = props;
  const { text1, text2, text3 } = translate.general.progressbar;
  const history = useHistory();
  const { mobileView } = useScreenSize(495);

  const back = () => {
    history.push(to.step1);
    clearStep2();
  };

  if (mobileView) {
    return (
      <div className="ge-StepProgress-container">
        <ProgressBar
          value={valueOne}
          // text={text1}
          back={back}
        />

        <ProgressBar
          value={valueTwo}
          // text={text2}
        />
        <ProgressBar
          value={valueThree}
          // text={text3}
        />
      </div>
    );
  } else {
    return (
      <div className="ge-StepProgress-container">
        <ProgressBar value={valueOne} text={text1} back={back} />

        <ProgressBar value={valueTwo} text={text2} />
        <ProgressBar value={valueThree} text={text3} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearStep2: () => {
      dispatch(clearStep2());
      dispatch(clearStep1());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepProgress);
