
export const CLEAR_STEP4 = 'CLEAR_STEP4';
export const TYPE_PAYMENT = 'TYPE_PAYMENT';
export const RESERVATION = 'RESERVATION';
export const DATA = 'DATA';
export const AGENCY_GROSS_NET = 'AGENCY_GROSS_NET';
export const LAST_NUMBER = 'LAST_NUMBER';
export const SALE_STATUS = 'SALE_STATUS';

export const typePayment = (payload) => {
    return {
        type: TYPE_PAYMENT,
        payload
    }
}

export const reservation = (payload) => {
    return {
        type: RESERVATION,
        payload
    }
}

export const data = (payload) => {
    return {
        type: DATA,
        payload
    }
}

export const agencyGrossNet = (payload) => {
    return {
        type: AGENCY_GROSS_NET,
        payload
    }
}

export const lastNumber = (payload) => {
    return {
        type: LAST_NUMBER,
        payload
    }
}

export const changeSaleStatus = (payload) => {
    return {
        type: SALE_STATUS,
        payload
    }
}

export const clearStep4 = () => {
    return {
        type: CLEAR_STEP4
    }
}
