import { Progress } from "reactstrap";

function ProgressBar(props) {
  const { value, text, back } = props;

  return (
    <div onClick={back} className={back ? `cursor` : ""}>
      <Progress value={value} className="ge-progress-bar" />
      <span className={value === 100 && "active"}>{text}</span>
    </div>
  );
}

export default ProgressBar;
