import { Container, Row, Col } from "reactstrap";
import translate from "../../../translates/translations";

function ModalInclusive(props) {
  const { handleClose, rate, inclusive } = props;
  const { title1 } = translate.modal.inclusive;

  return (
    <Container fluid className="modal-inclusive-container fade-in">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100">
          <Container fluid className="modal-inclusive">
            <Row className="d-flex justify-content-between w-100 modal-inclusive-row1">
              <Col xs={10} sm="10" className="w-100 p-0">
                <span>{title1}</span>
              </Col>
              <Col
                xs={2}
                sm="2"
                className="d-flex justify-content-end cursor p-0"
              >
                <img
                  src="/icon/x_black.svg"
                  alt=""
                  onClick={() => handleClose()}
                />
              </Col>
            </Row>
            <Row className="w-100 p-0">
              <Col sm="12" className="modal-inclusive-img">
                <span>
                  {rate} - {inclusive.name}{" "}
                </span>
              </Col>
            </Row>
            <Row className="w-100 modal-inclusive-row2">
              <Col sm="12" className="modal-inclusive-text p-0">
                {inclusive.inclusions &&
                  inclusive.inclusions.map((x, i) => (
                    <div key={`${i}${x}`}>
                      <img src="/icon/chevron-right.svg" alt="" />
                      <p>{x}</p>
                    </div>
                  ))}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalInclusive;
