import { connect } from "react-redux";
import NamePrice from "./namePrice";
import translate from "../../../translates/translations";

function Summary2({ totalCharge, stateSwitch, cotization }) {
    const { text2, text3 } = translate.step2.prices;

    return (
        <div className="s2-summary s2-summary-fix">
            <div className="s2-summary-icon">
                <img src="/icon/calendar.svg" alt="" />
                <p>{text2}</p>
            </div>
            <div className="s2-summary-price">
                <NamePrice
                    text={text3}
                    currency={totalCharge.CurrencyCode}
                    price={totalCharge.RateTotalAmount}
                    stateSwitch={stateSwitch}
                    cotization={cotization}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
        totalCharge: state.step2.totalCharge,
        cotization: state.step1.cotization,
    };
};

export default connect(mapStateToProps)(Summary2);
