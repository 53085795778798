import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { standarPrice } from "../../../utils/standard";

function HeaderInvoiced(props) {
  const { data, price, type } = props;
  const { opcion3 } = translate.step5;

  return (
    <div className="s5-HeaderInvoiced-container">
      <div className="s5-HeaderInvoiced-img">
        <img src="/img/invoice-payment.webp" alt="" />
      </div>
      <div>
        <>
          <h5>{opcion3.title3}</h5>
          <p>{opcion3.title1}</p>
        </>
        <p>
          {opcion3.text1}
          <span>
            {" "}
            {price.CurrencyCode}{" "}
            {type === "gross"
              ? standarPrice(price.EstimatedTotalAmountPrepaid)
              : standarPrice(price.EstimatedTotalAmountPrepaidNeto)}{" "}
          </span>
          {opcion3.text2}
        </p>
        <p>
          {opcion3.text3}: <span> {data.customer.email}</span>
        </p>
        <p>
          {opcion3.text5}
          <span></span>
        </p>
        <div className="s5-HeaderInvoiced-card">
          <div>
            <img src="/icon/tag.svg" alt="" />
            <p>{opcion3.title2}</p>
          </div>
          <div className="s5-HeaderInvoiced-hr"></div>
          <div>
            <span>{data.id}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    type: state.step4.type,
  };
};

export default connect(mapStateToProps)(HeaderInvoiced);
