import { useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import { Input, Label, FormGroup, Container, Row, Col } from "reactstrap";
import translate from "../../../translates/translations";
import {
    carSelected,
    ratesSelected,
    companySelected,
    setPrepaid,
} from "../../../redux/actions/step1";
import { standarPriceSwitch } from "../../../utils/standard";
import ModalPre from "../atoms/modalPre";
import ModalPod from "../atoms/modalPod";
import ModalInclusive from "../atoms/modalInclusive";
import useScreenSize from "../../../utils/useScreenSize";
import Carousel from "react-elastic-carousel";

function Rates(props) {
    const {
        car,
        carType,
        carSelected,
        ratesSelected,
        company,
        companySelected,
        setPrepaid,
        cotization,
    } = props;
    const { title1, title2, title3, text } = translate.step1.card;
    const { mobileView } = useScreenSize(576);
    const rates = Object.keys(car).map((key) => [key, car[key]]);
    const [modal, setModal] = useState({
        pre: false,
        pod: false,
        inclusive: false,
    });

    const handleClose = () => {
        setModal({
            pre: false,
            pod: false,
            inclusive: "",
        });
    };

    const set = (e, name) => {
        let pre_pod;
        if (name === "EstimatedTotalAmountPrepaid") {
            pre_pod = 1;
        }
        if (name === "EstimatedTotalAmount") {
            pre_pod = 0;
        }

        const selecteRate = {
            rate: e[0],
            payment: e[1][name],
            name: name,
        };

        companySelected(company);
        carSelected(carType);
        ratesSelected(selecteRate);
        setPrepaid(pre_pod);
    };

    return (
        <Container fluid className="p-0">
            {!mobileView ? (
                <>
                    <Row className="s1-rates-title m-0 px-4 py-2 d-flex justify-content-between">
                        <Col sm="6" className="p-0 ml-">
                            <p>{title1}</p>
                        </Col>
                        <Col
                            sm="3"
                            className="p-0 onlinePayment-Title align-items-center"
                            onClick={() =>
                                setModal((prev) => ({ ...prev, pre: true }))
                            }
                        >
                            <img src={"/icon/alert-circle.svg"} alt="" />
                            <p>{title2}</p>
                        </Col>
                        <Col
                            sm="3"
                            className="p-0 destinyPayment-Title align-items-start"
                            onClick={() =>
                                setModal((prev) => ({ ...prev, pod: true }))
                            }
                        >
                            <img src={"/icon/alert-circle.svg"} alt="" />
                            <p>{title3}</p>
                        </Col>
                    </Row>
                    {rates &&
                        rates.map((x, key) => (
                            <Row
                                className="d-flex justify-content-between mx-4 py-3 s1-rates"
                                key={key}
                            >
                                <Col
                                    sm="3"
                                    className="d-flex p-0 align-items-center"
                                >
                                    <p className="mb-0">
                                        {x[0]} - {x[1].RateData.name}
                                    </p>
                                </Col>
                                <Col
                                    sm="3"
                                    className="d-flex p-0 align-items-center"
                                >
                                    <p
                                        className="s1-rates-inclusoes mb-0 cursor"
                                        onClick={() =>
                                            setModal((prev) => ({
                                                ...prev,
                                                inclusive: x,
                                            }))
                                        }
                                    >
                                        {text}
                                    </p>
                                </Col>
                                <Col
                                    sm="3"
                                    className="d-flex p-0 align-items-center onlinePayment"
                                >
                                    <FormGroup
                                        check
                                        className="form-check-radio mt-0"
                                    >
                                        <Label check className="p-0">
                                            <Input
                                                name="rates"
                                                type="radio"
                                                name="rates"
                                                onClick={() =>
                                                    set(
                                                        x,
                                                        "EstimatedTotalAmountPrepaid"
                                                    )
                                                }
                                            ></Input>
                                            <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                    <p className="mb-0">
                                        <span className="span-price">
                                            {standarPriceSwitch(
                                                x[1]
                                                    .EstimatedTotalAmountPrepaid,
                                                cotization
                                            )}
                                        </span>
                                    </p>
                                </Col>
                                <Col
                                    sm="3"
                                    className="d-flex p-1 align-items-center destinyPayment"
                                >
                                    <FormGroup
                                        check
                                        className="form-check-radio mt-0"
                                    >
                                        <Label check className="p-0">
                                            <Input
                                                name="exampleRadios"
                                                type="radio"
                                                name="rates"
                                                onClick={() =>
                                                    set(
                                                        x,
                                                        "EstimatedTotalAmount"
                                                    )
                                                }
                                            ></Input>
                                            <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                    <p className="mb-0">
                                        <span className="span-price">
                                            {standarPriceSwitch(
                                                x[1].EstimatedTotalAmount,
                                                cotization
                                            )}
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        ))}
                </>
            ) : (
                <>
                    <Row className="s1-rates-title m-0 px-4 py-2 d-flex justify-content-between">
                        <Col
                            sm="6"
                            className="p-0 d-flex justify-content-center"
                        >
                            <p className="text-center">{title1}</p>
                        </Col>
                    </Row>
                    <Carousel
                        showArrows={false}
                        pagination={true}
                        enableAutoPlay={false}
                    >
                        {rates &&
                            rates.map((x, key) => (
                                <Row
                                    className="d-flex justify-content-between mx-4 py-3 s1-rates flex-column"
                                    key={key}
                                >
                                    <Col
                                        sm="12"
                                        className="d-flex p-0 align-items-center justify-content-center"
                                    >
                                        <p className="mb-0">
                                            <b>
                                                {x[0]} - {x[1].RateData.name}
                                            </b>
                                        </p>
                                    </Col>
                                    <Col
                                        sm="12"
                                        className="d-flex p-0 align-items-center justify-content-center border-rates"
                                    >
                                        <p
                                            className="s1-rates-inclusoes mb-0 cursor"
                                            onClick={() =>
                                                setModal((prev) => ({
                                                    ...prev,
                                                    inclusive: x,
                                                }))
                                            }
                                        >
                                            {text}
                                        </p>
                                    </Col>
                                    <Row sm="12" className="d-flex w-100 mt-3">
                                        <Col
                                            xs="6"
                                            className="p-0 d-flex justify-content-center align-items-center "
                                            onClick={() =>
                                                setModal((prev) => ({
                                                    ...prev,
                                                    pre: true,
                                                }))
                                            }
                                        >
                                            <img
                                                src={"/icon/alert-circle.svg"}
                                                alt=""
                                            />
                                            <p className="mb-0 pl-1 font-title-rates">
                                                {title2}
                                            </p>
                                        </Col>
                                        <Col
                                            xs="6"
                                            className="p-0  d-flex justify-content-center align-items-center"
                                            onClick={() =>
                                                setModal((prev) => ({
                                                    ...prev,
                                                    pod: true,
                                                }))
                                            }
                                        >
                                            <img
                                                src={"/icon/alert-circle.svg"}
                                                alt=""
                                            />
                                            <p className="mb-0 pl-1 font-title-rates">
                                                {title3}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row sm="12" className="d-flex w-100">
                                        <Col
                                            xs="6"
                                            className="d-flex p-0 align-items-center justify-content-center border-pre"
                                        >
                                            <FormGroup
                                                check
                                                className="form-check-radio mt-0"
                                            >
                                                <Label check className="p-0">
                                                    <Input
                                                        name="rates"
                                                        type="radio"
                                                        name="rates"
                                                        onClick={() =>
                                                            set(
                                                                x,
                                                                "EstimatedTotalAmountPrepaid"
                                                            )
                                                        }
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                            <p className="mb-0">
                                                <span className="span-price">
                                                    {standarPriceSwitch(
                                                        x[1]
                                                            .EstimatedTotalAmountPrepaid,
                                                        cotization
                                                    )}{" "}
                                                </span>
                                            </p>
                                        </Col>
                                        <Col
                                            xs="6"
                                            className="d-flex p-1 align-items-center justify-content-center"
                                        >
                                            <FormGroup
                                                check
                                                className="form-check-radio mt-0"
                                            >
                                                <Label check className="p-0">
                                                    <Input
                                                        name="exampleRadios"
                                                        type="radio"
                                                        name="rates"
                                                        onClick={() =>
                                                            set(
                                                                x,
                                                                "EstimatedTotalAmount"
                                                            )
                                                        }
                                                    ></Input>
                                                    <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                            <p className="mb-0">
                                                <span className="span-price">
                                                    {standarPriceSwitch(
                                                        x[1]
                                                            .EstimatedTotalAmount,
                                                        cotization
                                                    )}
                                                </span>
                                            </p>
                                        </Col>
                                    </Row>
                                </Row>
                            ))}
                    </Carousel>
                </>
            )}
            {modal.pre && <ModalPre handleClose={handleClose} />}
            {modal.inclusive && (
                <ModalInclusive
                    handleClose={handleClose}
                    rate={modal.inclusive[0]}
                    inclusive={modal.inclusive[1].RateData}
                />
            )}
            {modal.pod && <ModalPod handleClose={handleClose} />}
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
        cotization: state.step1.cotization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        carSelected: (payload) => dispatch(carSelected(payload)),
        ratesSelected: (payload) => dispatch(ratesSelected(payload)),
        companySelected: (payload) => dispatch(companySelected(payload)),
        setPrepaid: (payload) => dispatch(setPrepaid(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rates);
