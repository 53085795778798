import React, { useState, useEffect } from "react"; // eslint-disable-line
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

// Redux

import store from "./redux/store";
import { Provider } from "react-redux";
import App from "./App";
import { isProduction } from "./services/api";

// style
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/index.css";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    {!isProduction && (
      <div className="environment">
        <img src="/icon/alert-circle-env.svg" />
        Environment : {process.env.REACT_APP_ENV}
      </div>
    )}
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
