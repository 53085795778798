import {
    CAR_SELECTED,
    RATES_SELECTED,
    COMPANY_SELECTED,
    PLACE_SELECTED,
    SETPREPAID,
    FILTER_COMPANY,
    RENDER_FLEET,
    RELOAD,
    COTIZATION,
    CLEAR_STEP1,
} from "../actions/step1";

const initialState = {
    cotization: "",
    carSelected: "",
    ratesSelected: "",
    companySelected: "",
    placeSelected: "",
    prepaid: "",
    filterCompany: "Both",
    reload: false,
    renderFleet: false,
};

const step1 = (state = initialState, action) => {
    switch (action.type) {
        case CAR_SELECTED:
            return {
                ...state,
                carSelected: action.payload,
            };
        case RATES_SELECTED:
            return {
                ...state,
                ratesSelected: action.payload,
            };
        case COMPANY_SELECTED:
            return {
                ...state,
                companySelected: action.payload,
            };
        case PLACE_SELECTED:
            return {
                ...state,
                placeSelected: action.payload,
            };
        case SETPREPAID:
            return {
                ...state,
                prepaid: action.payload,
            };
        case FILTER_COMPANY:
            return {
                ...state,
                filterCompany: action.payload,
            };
        case RELOAD:
            return {
                ...state,
                reload: !state.reload,
            };
        case RENDER_FLEET:
            return {
                ...state,
                renderFleet: action.payload,
            };
        case COTIZATION:
            return {
                ...state,
                cotization: action.payload,
            };
        case CLEAR_STEP1:
            return {
                cotization: "",
                carSelected: "",
                ratesSelected: "",
                companySelected: "",
                placeSelected: "",
                prepaid: "",
                filterCompany: "Both",
                reload: false,
                renderFleet: false,
            };
        default: {
            return state;
        }
    }
};

export default step1;
