import { useState } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Input, Container, Row, Col } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  passenger,
  wizard,
  totalCharge,
  totalTax,
  taxes,
  loaderStep2,
} from '../../../redux/actions/step2';
import { reservation } from '../../../redux/actions/step4';
import { sumEquipment, createReserve } from '../../../services/api';
import { handleError } from '../../../utils/error';
import translate from '../../../translates/translations';
import Select from 'react-select';
import ButtonLoader from '../atoms/ButtonLoader';

function DataClient(props) {
  const {
    passenger,
    wizard,
    airlines,
    frequent,
    body,
    reservation,
    image,
    partner,
    totalCharge,
    totalTax,
    taxes,
    optionalEquipment,
    codes,
    vehGroup,
    userId,
    loaderStep2,
  } = props;
  const { info1 } = translate.step2;
  const { error5, success2, error23 } = translate.general.error;
  const [wizardCode, setWizardCode] = useState({
    loader: false,
    value: '',
  });
  let history = useHistory();

  const handleWizardCode = (e, boolean) => {
    if (boolean) {
      setWizardCode((prev) => ({
        ...prev,
        loader: boolean,
      }));
    } else {
      setWizardCode((prev) => ({
        ...prev,
        value: e,
      }));
    }
  };

  const setInfoFligh = (array) => {
    const newArray = [];
    for (let key in array) {
      const obj = {
        label: array[key],
        value: key,
      };
      newArray.push(obj);
    }
    return newArray;
  };

  const infoCompany = {
    title: info1.text5,
    items: setInfoFligh(airlines),
  };
  const infoFrequent = {
    title: info1.text6,
    items: setInfoFligh(frequent),
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    setError,
  } = useForm();

  function setObj() {
    const equipment = {};
    optionalEquipment.map((x) => {
      if (x.CoverageType) {
        equipment[x.CoverageType] = x.Code;
      } else {
        equipment[x.id] = x.quantity ? x.quantity : 1;
      }
    });
    return equipment;
  }

  const form = (data) => {
    loaderStep2(true);
    const equipment = setObj();

    const allData = {
      ...body,
      passenger_name: data.firstName,
      passenger_lastname: data.lastname,
      passenger_email: data.email,
      discount_number: codes.discountNumber ? codes.discountNumber : '',
      coupon: codes.cupom ? codes.cupom : '',
      wizard: codes.wizard ? codes.wizard : '',
      equipment: equipment,
      vehicle_group: vehGroup,
      user_id: userId ? userId : '',
      reward: codes.regardsNumber ? codes.regardsNumber : '',
      image: image,
      airline_iata: data.company.value,
      airline_flight: data.voo,
      frequent_flyer_program_id: data.frequent.value,
      frequent_flyer_membership: data.membro,
      partner_code: partner.partner_code ? partner.partner_code : '',
    };

    //console.log(JSON.stringify(allData), 'api/create');
    createReserve(allData)
      .then((response) => response.data)
      .then((res) => {
        if (res.reservation) {
          reservation(res.reservation);
          if (!userId) {
            if (allData.prepaid == 1) {
              history.push('/step4');
            } else {
              history.push('/step3');
            }
          } else {
            history.push('/step3');
          }
          loaderStep2(false);
          passenger(data);
        } else {
          handleError(res, 1);
        }
      })
      .catch((error) => {
        reset({
          firstName: data.firstName,
          lastname: data.lastname,
          email: data.email,
          company: '',
          voo: '',
          frequent: '',
          membro: '',
        });
        if (error.response.status === 422) {
          handleError(error.response.data.message, 1);
        }
        if (error.response.status === 500) {
          handleError(error.response.data, 1);
        } else {
          handleError(error23, 1);
        }
        console.log(error.response);
        loaderStep2(false);
      });
  };

  const onCodes = () => {
    if (!wizardCode.value) {
      return setError('wizard', {
        type: 'manual',
        message: 'Required',
      });
    } else {
      handleWizardCode(null, true);
      const code = {
        wizard: wizardCode.value,
        wizard_validate: 'true',
      };

      sumEquipment({ ...body, ...code })
        .then((response) => response.data)
        .then((res) => {
          handleError(success2, 2);
          totalCharge(res.TotalCharge);
          totalTax(res.total_tax);
          taxes(res.taxes);
          wizard(wizardCode.value);
          handleWizardCode(null, false);
        })
        .catch((error) => {
          setWizardCode({
            value: '',
            loader: false,
          });
          if (error.response.status === 500) {
            handleError(error5, 1);
          }
        });
    }
  };

  const maxControl = (e) => {
    if (e.target.value.length > e.target.maxLength)
      e.target.value = e.target.value.slice(0, e.target.maxLength);
  };

  return (
    <Container className='s2-ClientType-client'>
      <h6>{info1.title2}</h6>
      <hr />
      <form onSubmit={handleSubmit(form)} id='CreateForm' name='CreateForm'>
        <Row className='s2-ClientType-div1 m-0'>
          <Col xs={12} sm={6} className='m-0 p-0 pr-0 pr-sm-3'>
            <Controller
              name='firstName'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  autoFocus
                  type='text'
                  placeholder={info1.text1}
                  onChange={onChange}
                  value={value}
                  className={errors.firstName && 'help'}
                  tabIndex='1'
                  id='inputFirstName'
                  autoComplete='off'
                />
              )}
            />
            {/* { errors.firstName && handleError(errors.firstName.message, 1)} */}
          </Col>
          <Col xs={12} sm={6} className='m-0 p-0'>
            <Controller
              name='lastname'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  type='text'
                  placeholder={info1.text2}
                  onChange={onChange}
                  value={value}
                  className={errors.lastname && 'help'}
                  tabIndex='2'
                  autoComplete='off'
                />
              )}
            />
            {/* { errors.lastname && handleError(errors.lastname.message, 1)}   */}
          </Col>
          <Col xs={12} sm={6} className='m-0 p-0 pr-0 pr-sm-3'>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Debe ser un email válido',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  type='text'
                  placeholder={info1.text3}
                  onChange={onChange}
                  value={value}
                  className={errors.email && 'help'}
                  tabIndex='3'
                  autoComplete='off'
                />
              )}
            />
            {/* { errors.email && handleError(errors.email.message, 1)}          */}
          </Col>
          <Col xs={12} sm={6} className='m-0 p-0'>
            <div className='s2-ClientType-aplicar'>
              <Controller
                name='wizard'
                control={control}
                defaultValue=''
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type='text'
                    placeholder={info1.text4}
                    tabIndex='4'
                    onChange={(e) => handleWizardCode(e.target.value)}
                    className={errors.wizard && 'help'}
                  />
                )}
              />
              <ButtonLoader
                text={info1.button1}
                onClick={onCodes}
                loader={wizardCode.loader}
              />
            </div>
          </Col>
        </Row>
        <Row className='s2-ClientType-voo mx-0 p-0'>
          <h6>{info1.title3}</h6>
          <Row className='s2-ClientType-div2 mx-0 p-0'>
            <Col className='s2-ClientType-divIn p-0 pr-0 pr-sm-3'>
              <Controller
                name='company'
                control={control}
                defaultValue=''
                render={({ field: { onChange, value } }) => (
                  <Select
                    className={`react-select react-select-info mt-2 ${
                      errors.company && 'help'
                    }`}
                    classNamePrefix='react-select'
                    closeMenuOnSelect={false}
                    placeholder={infoCompany.title}
                    onChange={onChange}
                    value={value}
                    options={infoCompany.items}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6} className='m-0 p-0'>
              <Controller
                name='voo'
                control={control}
                defaultValue=''
                render={({ field: { onChange, value } }) => (
                  <Input
                    maxLength='8'
                    onInput={(e) => maxControl(e)}
                    placeholder={info1.text7}
                    onChange={onChange}
                    value={value}
                    className={errors.voo && 'help'}
                  />
                )}
              />
            </Col>
            <Col
              className='s2-ClientType-divIn m-0 p-0 pr-0 pr-sm-3'
              xs={12}
              sm={6}
            >
              <Controller
                name='frequent'
                control={control}
                defaultValue=''
                render={({ field: { onChange, value } }) => (
                  <Select
                    className={`react-select react-select-info mt-2 ${
                      errors.frequent && 'help'
                    }`}
                    classNamePrefix='react-select'
                    closeMenuOnSelect={false}
                    placeholder={infoFrequent.title}
                    onChange={onChange}
                    value={value}
                    options={infoFrequent.items}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6} className='m-0 p-0'>
              <Controller
                name='membro'
                control={control}
                defaultValue=''
                render={({ field: { onChange, value } }) => (
                  <Input
                    type='text'
                    placeholder={info1.text8}
                    onChange={onChange}
                    value={value}
                    className={errors.membro && 'help'}
                  />
                )}
              />
            </Col>
          </Row>
        </Row>
      </form>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    optionalEquipment: state.step2.optionalEquipment,
    codes: state.step2,
    vehGroup: state.step1.carSelected.VehGroup,
    userId: state.step2.userId,
    image: state.step1.carSelected.PictureURL,
    partner: state.step2.partner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    passenger: (payload) => dispatch(passenger(payload)),
    wizard: (payload) => dispatch(wizard(payload)),
    totalCharge: (payload) => dispatch(totalCharge(payload)),
    totalTax: (payload) => dispatch(totalTax(payload)),
    taxes: (payload) => dispatch(taxes(payload)),
    reservation: (payload) => dispatch(reservation(payload)),
    loaderStep2: (payload) => dispatch(loaderStep2(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataClient);
