import { useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { Button } from "reactstrap";
import Map from "../molecules/googleMaps";
import ModalLoja from "../atoms/modalLoja";

function CardMap(props) {
  const { location, title, date } = props;
  const { button1, text1 } = translate.step2.location;

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="s2-cardMaps-container">
      <div>
        <h6>{title}</h6>
      </div>
      <hr />
      <div className="s2-cardMaps-icon">
        <img src="/icon/calendar.svg" alt="" />
        <p>{date}</p>
      </div>
      <div className="s2-cardMaps-google">
        <Map
          lat={parseFloat(location.Latitude)}
          lon={parseFloat(location.Longitude)}
          zoom={12}
        />
      </div>
      <div className="s2-cardMaps-address">
        <span>
          {location.Name} ({location.Code})
        </span>
        <p>{location.Address.StreetNmbr}</p>
        <p>
          {location.Address.CityName} - {location.Address.PostalCode}
          {location.Address.CountryName &&
            ` - ${location.Address.CountryName.Code}`}
        </p>
        <p>
          {text1}: {location.Telephone}
        </p>
      </div>
      <Button color="primary" onClick={() => setShowModal(true)}>
        {button1}
      </Button>
      {showModal && <ModalLoja place={location} handleClose={handleClose} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
  };
};

export default connect(mapStateToProps)(CardMap);
