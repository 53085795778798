import React, { useEffect } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import Carousel from "../components/molecules/carousel";
import Banners from "../components/molecules/banners";
import Widget from "../components/organism/widget";
import { Container, Row } from "reactstrap";

function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Carousel />
      <Container fluid className="home p-0 m-0">
        <Row className="home">
          <Widget />
          {/* </Col> */}
        </Row>
        <h1 style={{ visibility: "hidden", marginBottom: 0 }}>
          Avis Budget GSA
        </h1>
        <Row className="home">
          <Container
            sm="12"
            md="10"
            lg="7"
            className="m-0 p-0 widthFixedDesktopBanners"
          >
            <Banners />
          </Container>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  };
};

export default connect(mapStateToProps)(Home);
