import { useEffect } from "react"; // eslint-disable-line
import translate from "../../../translates/translations";
import { connect } from "react-redux";
import { companySelected } from "../../../redux/actions/step1";
import {
  reservation,
  typePayment,
  data,
  agencyGrossNet,
  lastNumber,
} from "../../../redux/actions/step4";
import { viewReserve } from "../../../services/api";
import { useHistory } from "react-router-dom";
import { to } from "../../../utils/redirect";
import { handleError } from "../../../utils/error";
import Loader from "../atoms/loader";
import { useLocation } from "react-router-dom";

function WaitingReserve(props) {
  const {
    reservation,
    typePayment,
    data,
    companySelected,
    agencyGrossNet,
    lastNumber,
  } = props;
  const { opcion1, opcion2, opcion3 } = translate.step4.typepayment;
  const { error14, error15, error2 } = translate.general.error;
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    const passenger_lastname = query.get("passenger_lastname");
    const id_reservation = query.get("id_reservation");

    if (passenger_lastname && id_reservation) {
      onSubmit({
        passenger_lastname: passenger_lastname,
        id_reservation: id_reservation,
        language: "pt",
      });
    } else {
      history.push(to.home);
    }
  }, []);

  const objTypePayment = {
    1: opcion2,
    2: opcion3,
    3: opcion1,
    4: opcion3,
    5: opcion1,
  };

  const onSubmit = (info) => {
    const reserve = {
      id: info.id_reservation,
      last_name: info.passenger_lastname,
    };
    viewReserve(info)
      .then((response) => response.data)
      .then((res) => {
        reservation(reserve);
        const company = res.reservation.company === "Budget" ? 2 : 1;
        companySelected(company);
        if (res.reservation.sale_status === 0) {
          const step = res.reservation.prepaid == 1 ? "step4" : "step3";
          history.push(to[step]);
        } else {
          res.reservation.card_data &&
            lastNumber(res.reservation.card_data.last4 || "XXXX");
          typePayment({ value: objTypePayment[res.reservation.sale_status] });
          agencyGrossNet(res.reservation.sale_type);
          data(res);
          history.push(to.step5);
        }
      })
      .catch((error) => {
        history.push(to.home);
        if (error.response.status === 404) {
          handleError(error14, 1);
        }
        if (error.response.status === 422) {
          handleError(error15, 1);
        }
        if (error.response.status === 500) {
          handleError(error2, 1);
        }

        console.log(error);
      });
  };

  return <Loader />;
}

const mapDispatchToProps = (dispatch) => {
  return {
    reservation: (payload) => dispatch(reservation(payload)),
    typePayment: (payload) => dispatch(typePayment(payload)),
    data: (payload) => dispatch(data(payload)),
    companySelected: (payload) => dispatch(companySelected(payload)),
    agencyGrossNet: (payload) => dispatch(agencyGrossNet(payload)),
    lastNumber: (payload) => dispatch(lastNumber(payload)),
  };
};

export default connect(null, mapDispatchToProps)(WaitingReserve);
