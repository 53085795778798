import React, { useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { DropdownItems } from "../atoms/DropdownItems";
import { to } from "../../../utils/redirect";
import { conditions } from "../../../utils/conditions";
import { NavbarBrand, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { clear_home } from "../../../redux/actions/home";
import { clearStep1 } from "../../../redux/actions/step1";
import { clearStep2 } from "../../../redux/actions/step2";
import { clearStep4 } from "../../../redux/actions/step4";
import { clear_filter } from "../../../redux/actions/filter";

const leftIcon = (
  <svg
    viewBox="0 0 24 24"
    width="17"
    height="17"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="leftIcon"
  >
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);
const rightIcon = (
  <svg
    viewBox="0 0 24 24"
    width="17"
    height="17"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="rightIcon"
  >
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

function DropDownMobile(props) {
  const {
    home,
    offices,
    searchReserve,
    signUp,
    signIn,
    contact,
    setOpenDropdown,
    clearApp,
  } = props;
  const allClean = () => {
    clearApp();
    setOpenDropdown();
  };

  const searchClick = () => {
    searchReserve[1]();
    setOpenDropdown();
  };

  const DropdownMenu = () => {
    const [activeMenu, setActiveMenu] = useState("main");
    const dropdownRef = useRef(null);
    const [menuClasses, setMenuClasses] = useState(
      "slide-in-blurred-right-menu-mobile"
    );

    //// Hook de click fuera del div ////
    function useOutsideHandler(refNode) {
      function handleClickOutside(event) {
        //handler
        if (refNode.current && !refNode.current.contains(event.target)) {
          setMenuClasses("slide-out-blurred-right-menu-mobile");
          setTimeout(() => {
            setOpenDropdown();
          }, 400);
        }
      }

      // agrega el event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind el event listener
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }

    //llama a la funcion
    useOutsideHandler(dropdownRef);

    function DropdownItemRight(props) {
      return (
        <a
          href="#"
          className="nav-link-mobile"
          onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
        >
          {props.children}
          <span className="icon-right">{props.rightIcon}</span>
        </a>
      );
    }

    function DropdownItemLeft(props) {
      return (
        <a
          href="#"
          className="menu-item backButton"
          onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
        >
          <span className="icon-left">{props.leftIcon}</span>
          {props.children}
        </a>
      );
    }

    return (
      <div className={`dropdown-mobile ${menuClasses}`} ref={dropdownRef}>
        <NavbarBrand className="navBarLogo">
          <Link to={to.home} onClick={allClean}>
            {" "}
            <img
              src={"/img/avisbudget_logo.svg"}
              alt="Avis Budget"
              className="ge-img"
            />
          </Link>
        </NavbarBrand>

        <CSSTransition
          in={activeMenu === "main"}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
        >
          <div className="menu-mobile">
            <NavItem>
              <NavLink>
                <Link
                  to={to.home}
                  onClick={allClean}
                  className="nav-link-mobile"
                >
                  {home}
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link
                  to={to.offices}
                  onClick={() => setOpenDropdown()}
                  className="nav-link-mobile"
                >
                  {offices}
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link onClick={searchClick} className="nav-link-mobile">
                  {searchReserve}
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="dropDownMenuContainer dropDownMenuContainer-condiciones"
                onClick={() => setActiveMenu("conditions")}
              >
                <Link className="nav-link-mobile nav-link-mobile-condiciones">
                  {signUp}
                </Link>
                {rightIcon}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <a
                  className="nav-link-mobile"
                  href={to.access}
                  onClick={() => setOpenDropdown()}
                  target="blank"
                >
                  {signIn}
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link
                  to={to.contact}
                  onClick={() => setOpenDropdown()}
                  className="nav-link-mobile"
                >
                  {contact}
                </Link>
              </NavLink>
            </NavItem>
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "conditions"}
          timeout={500}
          classNames="menu-conditions"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="main" leftIcon={leftIcon}>
              {signUp}
            </DropdownItemLeft>
            <DropdownItemRight rightIcon={rightIcon} goToMenu="regionsAvis">
              Avis
            </DropdownItemRight>
            <DropdownItemRight rightIcon={rightIcon} goToMenu="regionsBudget">
              Budget
            </DropdownItemRight>
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "regionsAvis"}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="conditions" leftIcon={leftIcon}>
              Avis
            </DropdownItemLeft>

            <DropdownItemRight
              rightIcon={rightIcon}
              goToMenu="avisLatinAmerica"
            >
              {conditions.avis.latinAmerica.name}
            </DropdownItemRight>
            <DropdownItemRight rightIcon={rightIcon} goToMenu="avisCaribe">
              {conditions.avis.caribbean.name}
            </DropdownItemRight>
            <DropdownItemRight rightIcon={rightIcon} goToMenu="avisEuropa">
              {conditions.avis.europe.name}
            </DropdownItemRight>
            <DropdownItems object={conditions.avis.Canada} />
            <DropdownItems object={conditions.avis.EstadosUnidos} />
            <DropdownItems object={conditions.avis.Israel} />
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "regionsBudget"}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="conditions" leftIcon={leftIcon}>
              Budget
            </DropdownItemLeft>

            <DropdownItemRight
              rightIcon={rightIcon}
              goToMenu="budgetLatinAmerica"
            >
              {conditions.budget.latinAmerica.name}
            </DropdownItemRight>
            <DropdownItemRight rightIcon={rightIcon} goToMenu="budgetCaribe">
              {conditions.budget.caribbean.name}
            </DropdownItemRight>
            <DropdownItemRight rightIcon={rightIcon} goToMenu="budgetEuropa">
              {conditions.budget.europe.name}
            </DropdownItemRight>
            <DropdownItems object={conditions.budget.Canada} />
            <DropdownItems object={conditions.budget.EstadosUnidos} />
            <DropdownItems object={conditions.budget.Marrocos} />
          </div>
        </CSSTransition>

        {/* ///////////////////////////////////////////////
                            AVIS MENUS //
                         ////////////// */}

        <CSSTransition
          in={activeMenu === "avisLatinAmerica"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="regionsAvis" leftIcon={leftIcon}>
              {conditions.avis.latinAmerica.name}
            </DropdownItemLeft>

            {Object.entries(conditions.avis.latinAmerica).map(
              (
                [key, data] //slice para que saltee el name
              ) => (
                <DropdownItems key={data.name + key} object={data} />
              )
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "avisCaribe"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="regionsAvis" leftIcon={leftIcon}>
              {conditions.avis.caribbean.name}
            </DropdownItemLeft>

            {Object.entries(conditions.avis.caribbean).map(
              (
                [key, data] //slice para que saltee el name
              ) => (
                <DropdownItems key={data.name + key} object={data} />
              )
            )}
          </div>
        </CSSTransition>
        <CSSTransition
          in={activeMenu === "avisEuropa"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="regionsAvis" leftIcon={leftIcon}>
              {conditions.avis.europe.name}
            </DropdownItemLeft>

            {Object.entries(conditions.avis.europe).map(
              (
                [key, data] //slice para que saltee el name
              ) => (
                <DropdownItems key={data.name + key} object={data} />
              )
            )}
          </div>
        </CSSTransition>

        {/* ///////////////////////////////////////////////
                            BUDGET MENUS //
                            ////////////// */}

        <CSSTransition
          in={activeMenu === "budgetLatinAmerica"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="regionsBudget" leftIcon={leftIcon}>
              {conditions.budget.latinAmerica.name}
            </DropdownItemLeft>

            {Object.entries(conditions.budget.latinAmerica).map(
              (
                [key, data] //slice para que saltee el name
              ) => (
                <DropdownItems key={data.name + key} object={data} />
              )
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "budgetCaribe"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="regionsBudget" leftIcon={leftIcon}>
              {conditions.budget.caribbean.name}
            </DropdownItemLeft>

            {Object.entries(conditions.budget.caribbean).map(
              (
                [key, data] //slice para que saltee el name
              ) => (
                <DropdownItems key={data.name + key} object={data} />
              )
            )}
          </div>
        </CSSTransition>
        <CSSTransition
          in={activeMenu === "budgetEuropa"}
          timeout={500}
          classNames="menu-third"
          unmountOnExit
        >
          <div className="menu-mobile">
            <DropdownItemLeft goToMenu="regionsBudget" leftIcon={leftIcon}>
              {conditions.budget.europe.name}
            </DropdownItemLeft>

            {Object.entries(conditions.budget.europe).map(
              (
                [key, data] //slice para que saltee el name
              ) => (
                <DropdownItems key={data.name + key} object={data} />
              )
            )}
          </div>
        </CSSTransition>
      </div>
    );
  };

  return (
    <>
      <DropdownMenu></DropdownMenu>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearApp: () => {
      dispatch(clear_home());
      dispatch(clearStep1());
      dispatch(clearStep2());
      dispatch(clearStep4());
      dispatch(clear_filter());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropDownMobile);
