import React, { useState, useEffect } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import HeaderStep3 from "../components/atoms/headerStep3";
import Confirmation from "../components/molecules/confirmation";
import Taxes2 from "../components/atoms/taxes2";
import CarSelected from "../components/molecules/carSelected";
import Loja from "../components/atoms/loja";
import Agency from "../components/atoms/agency";
import Codes from "../components/atoms/codes";
import InfoRate from "../components/atoms/infoRate";
import StepProgress from "../components/molecules/stepProgress";
import translate from "../../translates/translations";
import { viewReserve } from "../../services/api";
import Loader from "../components/atoms/loader";
import { typeClient } from "../../redux/actions/step2";
import { to } from "../../utils/redirect";
import { useHistory } from "react-router-dom";
import { handleError } from "../../utils/error";
import { data, changeSaleStatus } from "../../redux/actions/step4";
import { setPrepaid } from "../../redux/actions/step1";
import { Container, Row } from "reactstrap";

function Step3(props) {
  const { card, location, info } = translate.step3;
  const { customer1, customer2, customer3 } = translate.step2;
  const {
    dataReservation,
    company,
    typeClient,
    setPrepaid,
    data,
    changeSaleStatus,
  } = props;
  const [infoReserve, setInfoReserve] = useState("");
  const history = useHistory();

  window.onbeforeunload = function (e) {
    return "Texto de aviso";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const body = dataReservation && {
    passenger_lastname: dataReservation.last_name,
    id_reservation: dataReservation.id,
    company: company == 2 ? "Budget" : "Avis",
    language: "pt",
  };

  useEffect(() => {
    viewReserve(body)
      .then((response) => response.data)
      .then((res) => {
        setInfoReserve(res);
        data(res);
        setPrepaid(res.reservation.prepaid);
        changeSaleStatus(res.reservation.sale_status);
        if (res.reservation.organization) {
          if (res.reservation.organization.type === 1) {
            typeClient(customer3);
          } else {
            typeClient(customer2);
          }
        } else {
          typeClient(customer1);
        }
      })
      .catch((error) => {
        history.push(to.step2);
        console.log(error);
        handleError(error, 1);
      });
  }, []);

  let agency = {
    title: info.title1,
    item1: info.text1,
    item2: info.text2,
    item3: info.text3,
    item4: info.text4,
  };
  let voo = {
    title: info.title2,
    item1: info.text5,
    item2: info.text6,
    item3: info.text7,
    item4: info.text8,
  };

  if (infoReserve) {
    if (infoReserve.reservation?.organization) {
      agency = {
        ...agency,
        result1: infoReserve.reservation.organization.name,
        result2: infoReserve.reservation.organization.email,
        result3: infoReserve.reservation.organization.rewards,
        result4: infoReserve.reservation.organization.name_user,
      };

      if (infoReserve.reservation?.organization?.type === 2) {
        agency = {
          ...agency,
          title: info.title3,
          item1: info.text9,
          item2: info.text2,
          item3: info.text10,
          item4: info.text11,
          result3: infoReserve.reservation.organization.identifier_user,
        };
      }
    }

    voo = {
      ...voo,
      result1: infoReserve.reservation?.airline?.name,
      result2: infoReserve.reservation?.airline?.flight_number,
      result3: infoReserve.reservation?.frequent_flier?.program,
      result4: infoReserve.reservation?.frequent_flier?.code,
    };

    return (
      <Container fluid className="m-0 p-0">
        <Row className="d-flex justify-content-center align-items-center w-100 m-0">
          <Container
            sm="12"
            md="10"
            lg="7"
            className="m-0 p-0 widthFixedDesktop"
          >
            <StepProgress valueOne={100} valueTwo={100} valueThree={100} />
            <HeaderStep3 reservation={infoReserve} />
            <Confirmation
              reservation={infoReserve.reservation}
              location={infoReserve.location}
              car={infoReserve.car}
              rental={infoReserve.rental_core}
              body={body}
            />
            <Taxes2 car={infoReserve.car} />
            <div className="s3-container-car">
              <CarSelected
                car={infoReserve.car}
                company={body.company}
                step={false}
              />
              <div className="s3-container-tarifa">
                <h6>{card.title3}</h6>
                <hr />
                <InfoRate car={infoReserve.car} />
              </div>
            </div>
            <div className="s3-container-loja">
              <h6>{location.title3}</h6>
              <div className="s3-container-divide">
                <Loja
                  title={location.title1}
                  location={infoReserve.location.PickUpLocation}
                />
                <Loja
                  title={location.title2}
                  location={infoReserve.location.ReturnLocation}
                />
              </div>
            </div>
            <div className="s3-container-div">
              <Agency info={agency} />
              <Agency info={voo} />
            </div>
            <Codes info={infoReserve.reservation} />
          </Container>
        </Row>
      </Container>
    );
  } else {
    return <Loader />;
  }
}

const mapStateToProps = (state) => {
  return {
    dataReservation: state.step4.reservation,
    company: state.step1.companySelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    typeClient: (payload) => dispatch(typeClient(payload)),
    setPrepaid: (payload) => dispatch(setPrepaid(payload)),
    data: (payload) => dispatch(data(payload)),
    changeSaleStatus: (payload) => dispatch(changeSaleStatus(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
