import React, { useEffect } from "react"; // eslint-disable-line
import { Col, Container, Row } from "reactstrap";
import { Accordion } from "../components/atoms/Accordion";
import translate from "../../translates/translations";

const {
  title1,
  title2,
  question1,
  question2,
  question3,
  question4,
  question5,
  question6,
  response1_1,
  response2_1,
  response2_2,
  response2_3,
  response2_4,
  response2_5,
  response2_6,
  location1,
  location2,
  response3_1,
  response3_2,
  response3_3,
  response4_1,
  response4_2,
  response4_3,
  response4_4,
  response5_1,
  response5_2,
  response6_1,
  response6_2,
} = translate.faq;

const faq = {
  title: title1,
  subtitle: title2,
  content: [
    {
      title: question1,
      response: response1_1,
    },
    {
      title: question2,
      response: (
        <>
          <p>{response2_1}</p>
          <p>
            <span>{location1} </span>
            {response2_2}
            <br />
            <span>{location2} </span>
            {response2_3}
          </p>
          <p>{response2_4}</p>
          <p>
            <span>{location1} </span>
            {response2_5}
            <br />
            <span>{location2} </span>
            {response2_6}
          </p>
        </>
      ),
    },
    {
      title: question3,
      response: (
        <>
          <p>{response3_1}</p>
          <p>
            <span>{location1} </span>
            {response3_2}
            <br />
            <span>{location2} </span>
            {response3_3}
          </p>
        </>
      ),
    },
    {
      title: question4,
      response: (
        <p>
          {response4_1} <span>{response4_2} </span>
          {response4_3} <span>{response4_4}</span>
        </p>
      ),
    },
    {
      title: question5,
      response: (
        <p>
          {response5_1} <span>{response5_2}</span>
        </p>
      ),
    },
    {
      title: question6,
      response: (
        <p>
          {response6_1} <span>{response6_2}</span>
        </p>
      ),
    },
  ],
};

const questionIcon = (
  <svg
    className="questionIcon"
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="css-i6dzq1"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
    <line x1="12" y1="17" x2="12.01" y2="17"></line>
  </svg>
);

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createAccordion = (dataObject, index) => {
    return <Accordion text={dataObject} index={index} />;
  };

  return (
    <>
      <Container fluid className="m-0 p-0">
        <Row className="d-flex justify-content-center w-100 m-0 p-0">
          <Row className="d-flex justify-content-center w-100 m-0 p-0 heroImageContainer overflow-hidden">
            <img
              src="/img/heroImages/hero1.webp"
              alt="city panoramic"
              width="100%"
              height="auto"
            />
          </Row>
          <Container
            sm="12"
            md="7"
            lg="7"
            className="offices-container__col m-0 p-0 widthFixedDesktopOffices"
          >
            <Col className="justify-content-center m-0 p-0 selectContainer-offices">
              <div className="d-flex titleContainer">
                {questionIcon}
                <span className="faqSub m-0 p-0">{faq.subtitle}</span>
              </div>
              <div className="offices-location-container p-0">
                {faq.content.map((element, index) =>
                  createAccordion(element, index)
                )}
              </div>
            </Col>
          </Container>
        </Row>
      </Container>
    </>
  );
};

export default FAQ;
