import React, { useState, useEffect } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import translate from "../../translates/translations";
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { findLocation } from "../../services/api";
import Map from "../components/molecules/googleMaps";
import Item from "../components/atoms/Item";

const building = (
  <svg
    className="buildingIcon"
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="12"
    viewBox="0 0 512 512"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m204.869 379.739h102.261v132.261h-102.261z" />
      <path d="m165.24 96.309h43.508v43.508h-43.508z" />
      <path d="m303.252 96.309h43.508v43.508h-43.508z" />
      <path d="m165.24 235.954h43.508v43.508h-43.508z" />
      <path d="m303.252 235.954h43.508v43.508h-43.508z" />
      <path d="m482.284 482h-39.573v-467c0-8.284-6.716-15-15-15h-343.423c-8.284 0-15 6.716-15 15v467h-39.572c-8.284 0-15 6.716-15 15s6.716 15 15 15h145.154v-147.261c0-8.284 6.716-15 15-15h132.26c8.284 0 15 6.716 15 15v147.261h145.154c8.284 0 15-6.716 15-15s-6.715-15-15-15zm-105.524-400.691v73.508c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm-138.012 213.153c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm0-139.645c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm49.504 154.645c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15v73.508c0 8.284-6.716 15-15 15z" />
    </g>
  </svg>
);
const plane = (
  <svg
    className="planeIcon"
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="12"
    viewBox="0 0 512 512"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          d="M506.352,5.648c-39.256-13.092-81.806-3.045-111.068,26.217L288.379,138.768L65.64,43.31L23.213,85.735l180.313,137.886
       l-97.46,97.46l-63.64-21.215L0,342.294l106.066,63.64L169.706,512l42.426-42.427l-21.213-63.638l97.46-97.46l137.886,180.313
       l42.426-42.427l-95.458-222.739l106.904-106.904C509.399,87.456,519.446,44.906,506.352,5.648z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M479.297,266.048c-11.716-11.717-30.71-11.717-42.427,0l-8.967,8.967l25.455,59.398l25.939-25.939
       C491.012,296.757,491.012,277.764,479.297,266.048z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M245.952,32.701c-11.715-11.717-30.71-11.717-42.426,0l-25.939,25.94l59.398,25.455l8.967-8.969
       C257.669,63.412,257.669,44.418,245.952,32.701z"
        />
      </g>
    </g>
  </svg>
);

function Offices(props) {
  const { days } = translate.general;
  const { title1, placeholder, text1 } = translate.offices;
  const [location, setLocation] = useState([]);
  const [locationFocus, setLocationFocus] = useState(false);
  const [locationSelected, setLocationSelected] = useState("");
  const [placeSelected, setPlaceSelected] = useState({
    name: "",
    timer: null,
  });

  const [pin, setPin] = useState({
    lat: "",
    lon: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPin({
      lat: locationSelected.lat,
      lon: locationSelected.lon,
    });
  }, [locationSelected]);

  const handleOnChange = (e) =>
    setPlaceSelected((prev) => ({
      ...prev,
      name: e.target.value,
    }));

  useEffect(() => {
    handleCheck();
  }, [placeSelected]);

  const handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(placeSelected.timer);
    placeSelected.timer = setTimeout(() => {
      callApi();
    }, 500);
  };

  const callApi = () => {
    if (placeSelected.name.length > 2 && placeSelected.name.length < 15) {
      findLocation({ location: placeSelected.name })
        .then((response) => response.data)
        .then((res) => setLocation(res))
        .catch((error) => console.log(error));
    }
  };

  const renderList = () => {
    return (
      <ListGroup className="ho-wi-list">
        {location &&
          location.map((place, i) => (
            <ListGroupItem
              key={`${place.name}${i}`}
              onMouseDown={() => handleOnSelect(place)}
            >
              {place.idCompany === 0 && (
                <img
                  src="/img/avisbudget_logo.svg"
                  alt=""
                  className="avisbudget"
                />
              )}
              {place.idCompany === 1 && (
                <img src="/img/avis.svg" alt="" width="500" className="avis" />
              )}
              {place.idCompany === 2 && (
                <img
                  src="/img/budget.svg"
                  alt=""
                  width="500"
                  className="budget"
                />
              )}
              {place.officeData[0].AtAirport ? plane : building}
              {place.name}
            </ListGroupItem>
          ))}
      </ListGroup>
    );
  };

  const handleOnSelect = (place) => {
    setLocationSelected(place);
    setPlaceSelected((prev) => ({
      ...prev,
      name: place.name,
    }));
  };

  const mapSchedule = (Schedule) =>
    Object.keys(Schedule).map((day) => (
      <Item
        icon={false}
        text={`${days[day]}: ${Schedule[day].start} - ${Schedule[day].end}`}
      />
    ));

  return (
    <Container fluid className="m-0 p-0">
      <Row className="d-flex justify-content-center w-100 m-0 p-0">
        <Row className="d-flex justify-content-center w-100 m-0 p-0 heroImageContainer overflow-hidden">
          <img
            src="/img/heroImages/hero2.webp"
            alt="lake panoramic"
            width="100%"
            height="auto"
          />
        </Row>
        <Container
          sm="12"
          md="7"
          lg="7"
          className="offices-container__col m-0 p-0 widthFixedDesktopOffices"
        >
          <Col className="justify-content-center m-0 p-0 selectContainer-offices">
            <div className="d-flex titleContainer">
              <img src="/icon/compass.svg" alt="" className="mr-3" />
              <span>{title1}</span>
            </div>
            <div className="offices-location-container p-0">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="ge-in-img">
                    <img
                      src={"/icon/map-pin.svg"}
                      alt=""
                      className="ge-in-ico"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={placeholder}
                  className="ge-in-pla mb-0"
                  onChange={handleOnChange}
                  onFocus={() => setLocationFocus(true)}
                  onBlur={() => setLocationFocus(false)}
                  type="text"
                  autoComplete="off"
                  value={placeSelected.name}
                />
              </InputGroup>
              {locationFocus && renderList()}
            </div>
          </Col>
        </Container>
      </Row>
      {locationSelected && (
        <Row className="d-flex justify-content-center m-0 mb-4 ">
          <Col className="p-0 d-flex justify-content-center">
            <Container sm="12" md="7" lg="7" className="widthFixedDesktop p-0">
              {locationSelected.officeData.map((place, i) => (
                <Row key={`${place.Name}${i}`} className="office__mobile m-0">
                  <Col className="offices-location-info">
                    <div>
                      {/* <div> */}
                      {/* <img src="" alt=""/> */}
                      <h6 className="negrita mb-4">
                        {place.AtAirport ? plane : building} ({place.Code}){" "}
                        {place.Name}
                      </h6>
                      {/* </div> */}
                      <div className="offices-location-card">
                        <img
                          src={
                            place.Company === "AVIS"
                              ? "/img/logo-avis-negative.svg"
                              : "/img/logo-budget-negative.svg"
                          }
                          alt=""
                          className="mb-4 w-25 logoOfficeLocation"
                        />
                        <p className="negrita mb-3">
                          {text1}: <span>{place.GDS}</span>
                        </p>
                        <div className="d-flex mb-3 align-items-start locationOfficeCards">
                          <img
                            src="/icon/map-pin.svg"
                            alt=""
                            className="mr-3"
                          />
                          <div>
                            <p className="m-0">
                              {place.Address?.StreetNmbr} -{" "}
                              {place.Address?.CityName}{" "}
                            </p>
                            <p className="m-0">
                              {place.Address?.PostalCode} -{" "}
                              {place.Address?.StateCode}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mb-3 phoneOfficeCards">
                          <img
                            src="/icon/phone-call.svg"
                            alt=""
                            className="mr-3"
                          />
                          <span>{place.Telephone || "Sem informação"}</span>
                        </div>
                        <div className="d-flex mb-3 align-items-start schedulesOfficeCards">
                          <img src="/icon/clock.svg" alt="" className="mr-3" />
                          <div className="schedule">
                            <div className="d-flex flex-column column1">
                              {place.Schedule
                                ? mapSchedule(place.Schedule)
                                : "Sem informação"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="p-0 container-map">
                    <Map
                      lat={parseFloat(pin.lat)}
                      lon={parseFloat(pin.lon)}
                      zoom={12}
                    />
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
  };
};

export default connect(mapStateToProps)(Offices);
