import { useState } from "react";
import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { FormGroup, Label, Input } from "reactstrap";
import { handleError } from "../../../utils/error";
import { moveTo, to } from "../../../utils/redirect";
import ButtonLoader from "./ButtonLoader";

function Confirm(props) {
  const { loaderStep2, typeClient, countryCode, companySelected, prepaid } =
    props;
  const { text4, text5, text6, text7, text8, text9, text10, text11, button1 } =
    translate.step2.prices;
  const { error17 } = translate.general.error;
  const [accept, setAccept] = useState(true);
  const handleClick = (e) => {
    if (typeClient) {
      setAccept(!accept);
    } else {
      handleError(error17, 1);
      moveTo("s2-ClientType");
    }
  };

  const conditions = companySelected === 1 ? to.avis : to.budget;
  const pdf = prepaid === 1 ? to.pre : to.pod;

  const handleInput = () => {
    const element = document.getElementById("inputFirstName");
    if (!!element.value === false) {
      moveTo("inputFirstName");
    }
  };

  return (
    <div className="s2-confirm">
      <div>
        <p>
          {text4} {text5}
        </p>
        <p>{text6}</p>
        <p>{text7}</p>
        <br />
      </div>
      <div className="s2-confirm-checkbox">
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onClick={(e) => handleClick(e)}
              id="checkbox-validation"
              checked={!accept}
            ></Input>
            <span className="form-check-sign"></span>
          </Label>
        </FormGroup>
        <p>
          {text8}{" "}
          <a
            href={`${conditions}${countryCode}?language=PT`}
            target="blank"
            className="s2-confirm-link"
          >
            {text9}
          </a>{" "}
          {text10}
          <a href={pdf} target="blank" className="s2-confirm-link">
            {text11}
          </a>{" "}
        </p>
      </div>
      <ButtonLoader
        loader={loaderStep2}
        text={button1}
        disabled={accept}
        form="CreateForm"
        submit="submit"
        onClick={handleInput}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    loaderStep2: state.step2.loaderStep2,
    typeClient: state.step2.typeClient,
    companySelected: state.step1.companySelected,
    prepaid: state.step1.prepaid,
  };
};

export default connect(mapStateToProps)(Confirm);
