import { Container, Row, Col, Button } from "reactstrap";
import translate from "../../../translates/translations";
import ButtonLoader from "./ButtonLoader";
import { standarName } from "../../../utils/standard";

function ModalCancel(props) {
  const { handleClose, deleteReserve, loader, reservation } = props;
  const { title1, title2, title3, text1, text2 } = translate.modal.cancel;

  return (
    <Container fluid className="modal-cancel-container fade-in m-0 p-0">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100 p-0 m-0">
          <Container fluid className="modal-cancel m-0">
            <Row className="d-flex justify-content-start w-100">
              <Col sm="10" className="w-100 p-0 mb-3">
                <h6 className="mb-3">{title1}</h6>
                <p className="m-0">
                  {text2}:{" "}
                  <span>
                    {standarName(
                      reservation.customer.first_name,
                      reservation.customer.last_name
                    )}
                  </span>
                </p>
                <p className="m-0">
                  {text1}: <span>{reservation.id}</span>
                </p>
              </Col>
            </Row>
            <Row className="w-100 p-0 mt-2 d-flex">
              <Col className="p-0 m-0 mr-2">
                <ButtonLoader
                  loader={loader}
                  text={title2}
                  onClick={deleteReserve}
                />
              </Col>
              <Col className="p-0 m-0 ml-2">
                <Button color="primary" onClick={handleClose}>
                  <b>{title3}</b>
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalCancel;
