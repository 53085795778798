export const DropdownItems = (props) => {
  const { name, url } = props.object;

  if (name !== undefined) {
    //saltea nombre
    return (
      <a href={url} target="blank" className="menu-item">
        {name}
      </a>
    );
  } else {
    return "";
  }
};
