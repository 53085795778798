import NamePrice from "./namePrice";

export default function Taxes({ taxes, stateSwitch, cotization }) {
    return (
        <div>
            <div className="s2-taxes">
                {taxes &&
                    taxes.map((tax, i) => (
                        <NamePrice
                            text={tax.Translated}
                            currency={tax.CurrencyCode}
                            price={tax.Amount}
                            key={`${tax}${i}`}
                            stateSwitch={stateSwitch}
                            cotization={cotization}
                        />
                    ))}
            </div>
        </div>
    );
}
