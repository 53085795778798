import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { findFleet } from '../../services/api';
import CardFleet from '../components/organism/cardFleet';
import CardLocation from '../components/molecules/cardLocation';
import FilterFleet from '../components/molecules/filterFleet';
import StepProgress from '../components/molecules/stepProgress';
import translate from '../../translates/translations';
import { to } from '../../utils/redirect';
import { useHistory } from 'react-router-dom';
import Loader from '../components/atoms/loader';
import {
  placeSelected,
  clearStep1,
  renderFleet,
  setCotization,
} from '../../redux/actions/step1';
import { setFleet, backupFleet } from '../../redux/actions/filter';
import { handleError } from '../../utils/error';
import { clear_home } from '../../redux/actions/home';
import { Container, Row, Col } from 'reactstrap';
import { useSwitch } from '../../hooks/useSwitch';
import { SwitchPrice } from '../components/molecules/SwitchPrice';

function Step1(props) {
  const {
    place_pickup,
    place_dropoff,
    info_user,
    companySelected,
    placeSelected,
    clear_home,
    setCotization,
    filter,
    reload,
    renderFleetState,
    renderFleet,
    setFleet,
    backup,
    fleet,
  } = props;
  const [location, setLocation] = useState('');
  const [address, setAddress] = useState('');
  const { title1, title2 } = translate.step1.location;
  const { error4, error12, error19, error20, error18 } =
    translate.general.error;
  const history = useHistory();
  const { price, handleSwitch } = useSwitch();
  const someUndefined = (body) =>
    Object.values(body).some((x) => x === undefined);

  window.onbeforeunload = function (e) {
    return 'Texto de aviso';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (fleet) {
      if (location) {
        if (companySelected === 2) {
          if (location.Budget) {
            setAddress(location.Budget);
          } else {
            setAddress(location.Avis);
            handleError(error20, 3);
          }
        } else {
          if (location.Avis) {
            setAddress(location.Avis);
          } else {
            setAddress(location.Budget);
            handleError(error20, 3);
          }
        }
      }
    }
  }, [location, companySelected, fleet]);

  const body = {
    pickup_location: place_pickup.id,
    pickup_company_id:
      place_pickup.idCompany === 0 ? 1 : place_pickup.idCompany,
    pickup_country_id: place_pickup.idCountry,
    pickup_date: info_user.pickup_date,
    pickup_time: info_user.pickup_time,
    dropoff_location: place_dropoff.id,
    dropoff_company_id:
      place_dropoff.idCompany === 0 ? 1 : place_dropoff.idCompany,
    dropoff_country_id: place_dropoff.idCountry,
    dropoff_date: info_user.dropoff_date,
    dropoff_time: info_user.dropoff_time,
    passenger_country_id: info_user.passenger_country_id,
    passenger_age: info_user.passenger_age,
    language: 'pt',
  };

  useEffect(() => {
    // console.log("api/findFleet", body);
    const error = someUndefined(body);
    if (error) {
      history.push(to.home);
      clear_home();
      handleError(error18, 1);
    } else {
      findFleet(body)
        .then((response) => response.data)
        .then((data) => {
          setLocation(data.location);
          setCotization(data.cotization_data);
          return data;
        })
        .then((res) => Object.values(res.cars))
        .then((array) => setFleet(array))
        .then((info) => renderFleet(false))
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              handleError(error4, 1);
            }
            if (error.response.status === 422) {
              handleError(error19, 1);
            }
          } else {
            handleError(error12, 1);
          }

          history.push(to.home);
          clear_home();
          console.log(error.response);
        });
    }
  }, [reload]);

  const onSubmit = (e) => {
    e.preventDefault();
    placeSelected(address);
    document.getElementById('rates').reset();
    history.push(to.step2);
  };

  //Busca los tipos de vehiculo de la flota
  const selectGroup = () => {
    const grupos = [];
    const gurpoDeVehiculos = backup
      .map((x) => Object.values(x))
      .map((x) => x.map((o) => grupos.push(`Grupo ${o.VehGroup}`)));
    var set = [...new Set(grupos)];
    return set;
  };

  //Busca cantidad de asientos de los vehiculos de la flota
  const selectPassenger = () => {
    const grupos = [];
    const gurpoDeVehiculos = backup
      .map((x) => Object.values(x))
      .map((x) => x.map((o) => grupos.push(o.Features2.seats)));
    var set = [...new Set(grupos)];
    const order = set.sort((a, b) => a - b).map((x) => `${x} passageiros`);
    return order;
  };

  if (fleet && address && !renderFleetState) {
    return (
      <Container fluid className='m-0 p-0'>
        <Row className='d-flex justify-content-center align-items-center w-100 m-0'>
          <Container
            sm='12'
            md='10'
            lg='7'
            className='m-0 p-0 widthFixedDesktop'
          >
            <StepProgress valueOne={100} valueTwo={0} valueThree={0} />
            <div className='s1-cards-container'>
              <div className='s1-cards'>
                <CardLocation
                  title={title1}
                  date={info_user.pickup_date_time}
                  place={address.PickUpLocation}
                />
                <div className='line'></div>
                <CardLocation
                  title={title2}
                  date={info_user.dropoff_date_time}
                  place={address.ReturnLocation}
                />
              </div>
            </div>
            <FilterFleet
              selectGroup={selectGroup()}
              selectPassenger={selectPassenger()}
            />
            <div className='switchPrice__width'>
              <SwitchPrice
                text={translate.step2.switch}
                onclick={handleSwitch}
                state={localStorage.getItem('price')}
              />
            </div>
            <form onSubmit={onSubmit} id='rates' className='d-flex flex-column'>
              {fleet &&
                fleet.map((x, i) => (
                  <div>
                    {filter === 'Both' && (
                      <div key={i}>
                        {body.dropoff_company_id === 2 ? (
                          <div>
                            {x.Budget && (
                              <CardFleet
                                stateSwitch={price}
                                car={x.Budget}
                                logo={'/img/budget.svg'}
                                clase={'logo-budget'}
                                company={2}
                              />
                            )}
                            {x.Avis && (
                              <CardFleet
                                stateSwitch={price}
                                car={x.Avis}
                                logo={'/img/avis.svg'}
                                clase={'logo-avis'}
                                company={1}
                              />
                            )}
                          </div>
                        ) : (
                          <div>
                            {x.Avis && (
                              <CardFleet
                                stateSwitch={price}
                                car={x.Avis}
                                logo={'/img/avis.svg'}
                                clase={'logo-avis'}
                                company={1}
                              />
                            )}
                            {x.Budget && (
                              <CardFleet
                                stateSwitch={price}
                                car={x.Budget}
                                logo={'/img/budget.svg'}
                                clase={'logo-budget'}
                                company={2}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {filter === 'Avis' && (
                      <div key={i}>
                        {x.Avis && (
                          <CardFleet
                            stateSwitch={price}
                            car={x.Avis}
                            logo={'/img/avis.svg'}
                            clase={'logo-avis'}
                            company={1}
                          />
                        )}
                      </div>
                    )}
                    {filter === 'Budget' && (
                      <div key={i}>
                        {x.Budget && (
                          <CardFleet
                            stateSwitch={price}
                            car={x.Budget}
                            logo={'/img/budget.svg'}
                            clase={'logo-budget'}
                            company={2}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </form>
          </Container>
        </Row>
      </Container>
    );
  } else {
    return <Loader />;
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    place_pickup: state.home.place_pickup,
    place_dropoff: state.home.place_dropoff,
    info_user: state.home.info_user,
    companySelected: state.step1.companySelected,
    filter: state.step1.filterCompany,
    fleet: state.filter.fleet,
    backup: state.filter.backupFleet,
    reload: state.step1.reload,
    renderFleetState: state.step1.renderFleet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    placeSelected: (payload) => dispatch(placeSelected(payload)),
    renderFleet: (payload) => dispatch(renderFleet(payload)),
    setCotization: (payload) => dispatch(setCotization(payload)),
    setFleet: (payload) => {
      dispatch(setFleet(payload));
      dispatch(backupFleet(payload));
    },
    clear_home: () => dispatch(clear_home()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
