import { useEffect, useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import RightNamePrice from "../atoms/rightNamePrice";
import CardPrice from "../atoms/cardPrice";
import translate from "../../../translates/translations";
import { standarPrice } from "../../../utils/standard";
import Switch from "react-bootstrap-switch";
import { agencyGrossNet } from "../../../redux/actions/step4";
import ModalIrrf from "../atoms/modalIrrf";
import ModalCreditCard from "../atoms/modalCreditCard";
import ModalPayment from "../atoms/ModalPayment";

function PaymentDetails(props) {
  const {
    text,
    title1,
    title3,
    title4,
    title5,
    title6,
    title7,
    title8,
    title9,
    title10,
    title11,
    title12,
    title14,
    title15,
  } = translate.step4.payment;
  const { customer2, customer3 } = translate.step2;
  const { car, equipment, clientType, agencyGrossNet, GrossNet, creditCard } =
    props;
  const { total_tax } = car.VehicleCharges;
  const { TotalCharge } = car;
  const [showModal, setShowModal] = useState(false);
  const [showModalCC, setShowModalCC] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const isAgency = customer3 === clientType;
  const comissaoFinal =
    isAgency &&
    TotalCharge.Discount_commission.total_comission -
      TotalCharge.Discount_commission.total_cctax;

  const detalle = {
    title: title4,
    total: title6,
    price: TotalCharge.RateTotalAmountPlusTax,
    currency: TotalCharge.CurrencyCode,
    items: [
      {
        name: title8,
        Amount: TotalCharge.RateTotalAmount,
        CurrencyCode: TotalCharge.CurrencyCode,
      },
      {
        name: title9,
        Amount: total_tax.Total_Tax,
        CurrencyCode: total_tax.CurrencyCode,
      },
    ],
  };

  const optional = {
    title: title5,
    items: equipment
      ? [
          ...equipment,
          {
            name: "Imposto de equipamento",
            Amount: TotalCharge.EquipmentTax,
            CurrencyCode: TotalCharge.CurrencyCode,
          },
        ]
      : [],
    total: title7,
    price: TotalCharge.EquipmentPlusTax ? TotalCharge.EquipmentPlusTax : "",
    currency: total_tax.CurrencyCode,
  };

  useEffect(() => {
    if (isAgency && GrossNet === "gross") agencyGrossNet("net");
  }, []);

  const handleClose = () => {
    setShowModalCC(false);
    setShowModal(false);
    setShowModalPayment(false);
  };
  const handleSwitch = (elem, state) =>
    state ? agencyGrossNet("net") : agencyGrossNet("gross");

  return (
    <div className="s4-paymentDetails-container">
      {isAgency ? (
        <div className="s4-paymentDetails-div1">
          <div>
            <h6>{title1}</h6>
            <hr />
            <div>
              <RightNamePrice
                title={GrossNet === "gross" ? title11 : title15}
                currency={TotalCharge.CurrencyCode}
                price={
                  GrossNet === "gross"
                    ? TotalCharge.BaseRatePlusTaxes
                    : creditCard
                    ? TotalCharge.BaseRatePlusTaxes - comissaoFinal
                    : TotalCharge.BaseRatePlusTaxes -
                      TotalCharge.Discount_commission.total_comission
                }
              />
              {/* {GrossNet === "gross" &&
              TotalCharge.Discount_commission.percentage ? (
                <RightNamePrice
                  title={`Desconto  (${TotalCharge.Discount_commission.percentage}%)`}
                  currency={TotalCharge.CurrencyCode}
                  price={TotalCharge.Discount_commission.total}
                />
              ) : null} */}
              {TotalCharge.irrf ? (
                <div className="ge-RightNamePrice">
                  <img src="/icon/chevron-right.svg" alt="chevron-right" />
                  <p>{title10}</p>
                  <img
                    src="/icon/alert.svg"
                    alt="information"
                    className="ml-2 cursor"
                    onClick={() => setShowModal(true)}
                  />
                  <span>
                    {TotalCharge.CurrencyCode} {standarPrice(TotalCharge.irrf)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="s4-paymentDetails-pre">
            {creditCard && (
              <>
                <div className="d-flex justify-content-between m-0">
                  <h6>Pagar Gross</h6>
                  <Switch
                    offText=""
                    onColor=""
                    onText=""
                    onChange={(el, state) => handleSwitch(el, state)}
                    defaultValue={true}
                  />
                  <h6>Pagar Net</h6>
                </div>
                <hr style={{ margin: "20px 0" }} />
              </>
            )}
            <div className="d-flex s4-payment-img">
              <h6>{title3}:</h6>
              <img
                src="/icon/alert.svg"
                alt="information"
                className="ml-2 cursor"
                onClick={() => setShowModalPayment(true)}
              />
              {creditCard ? (
                <>
                  <span className="s4-paymentDetails-span">
                    {TotalCharge.CurrencyCode}{" "}
                    {GrossNet === "net"
                      ? standarPrice(
                          TotalCharge.EstimatedTotalAmountPrepaidNetoCCTax
                        )
                      : standarPrice(TotalCharge.EstimatedTotalAmountPrepaid)}
                  </span>
                </>
              ) : (
                <>
                  <span className="s4-paymentDetails-span">
                    {TotalCharge.CurrencyCode}{" "}
                    {standarPrice(TotalCharge.EstimatedTotalAmountPrepaidNeto)}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="s4-paymentDetails-pre">
            <RightNamePrice
              title={`${title12} (${TotalCharge.Discount_commission.percentage_commission}%)`}
              currency={TotalCharge.CurrencyCode}
              price={TotalCharge.Discount_commission.total_comission}
            />
            {creditCard && (
              <div className="ge-RightNamePrice">
                <img src="/icon/chevron-right.svg" alt="chevron-right" />
                <p>{`${TotalCharge.Discount_commission.text_cctax}  (${TotalCharge.Discount_commission.percentage_cctax}%)`}</p>
                <img
                  src="/icon/alert.svg"
                  alt="information"
                  className="ml-2 cursor"
                  onClick={() => setShowModalCC(true)}
                />
                <span>
                  {`-${TotalCharge.CurrencyCode} ${standarPrice(
                    TotalCharge.Discount_commission.total_cctax
                  )}`}
                </span>
              </div>
            )}
            <div className="ge-CardPrice-total">
              <hr />
              <div>
                <h6>{title14}</h6>
                <span>
                  {TotalCharge.CurrencyCode}{" "}
                  {creditCard
                    ? standarPrice(comissaoFinal)
                    : standarPrice(
                        TotalCharge.Discount_commission.total_comission
                      )}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="s4-paymentDetails-div1">
          <div>
            <h6>{title1}</h6>
            <hr />
            <div>
              <RightNamePrice
                title={title11}
                currency={TotalCharge.CurrencyCode}
                price={TotalCharge.BaseRatePlusTaxes}
              />
              {/* {customer2 === clientType &&
                TotalCharge.Discount_commission &&
                TotalCharge.Discount_commission.percentage && (
                  <RightNamePrice
                    title={`Desconto (${TotalCharge.Discount_commission.percentage}%)`}
                    currency={TotalCharge.CurrencyCode}
                    price={TotalCharge.Discount_commission.total}
                  />
                )} */}
              {TotalCharge.irrf ? (
                <div className="ge-RightNamePrice">
                  <img src="/icon/chevron-right.svg" alt="chevron-right" />
                  <p>{title10}</p>
                  <img
                    src="/icon/alert.svg"
                    alt="information"
                    className="ml-2 cursor"
                    onClick={() => setShowModal(true)}
                  />
                  <span>
                    {TotalCharge.CurrencyCode} {standarPrice(TotalCharge.irrf)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="s4-paymentDetails-pre">
            <div className="d-flex">
              <h6>{title3}:</h6>
              <span className="s4-paymentDetails-span">
                {TotalCharge.CurrencyCode}{" "}
                {standarPrice(TotalCharge.EstimatedTotalAmountPrepaid)}
              </span>
            </div>
            <div className="alertBody">
              <p>{text}</p>
            </div>
          </div>
        </div>
      )}
      <CardPrice info={detalle} />
      <CardPrice info={optional} />
      {showModal && <ModalIrrf handleClose={handleClose} />}
      {showModalCC && <ModalCreditCard handleClose={handleClose} />}
      {showModalPayment && <ModalPayment handleClose={handleClose} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    clientType: state.step2.typeClient,
    GrossNet: state.step4.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    agencyGrossNet: (payload) => dispatch(agencyGrossNet(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
