import { Button } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ButtonLoader = ({
  text,
  submit = "button",
  form = "",
  loader,
  onClick = "",
  disabled = false,
}) => {
  return (
    <Button
      color="primary"
      type={submit}
      form={form}
      onClick={onClick}
      disabled={disabled}
      className={`customBold ${disabled ? "accept" : ""}`}
    >
      {loader ? (
        <PulseLoader color="white" loading={loader} css={override} size={8} />
      ) : (
        text
      )}
    </Button>
  );
};

export default ButtonLoader;
