import translate from "../../../translates/translations";
import Code from "./Code";

export default function Codes({ info }) {
  const { text1, text2, text3 } = translate.step3.codes;

  return (
    <div className="s3-codes-container">
      <Code
        icon={"/icon/percent.svg"}
        text={text1}
        code={info.customer.discount_number}
      />
      <div className="hr"></div>
      <Code icon={"/icon/tag.svg"} text={text2} code={info.customer.coupon} />
      <div className="hr"></div>
      <Code icon={"/icon/user.svg"} text={text3} code={info.customer.wizard} />
    </div>
  );
}
