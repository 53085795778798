import React, { useEffect, useState } from "react"; // eslint-disable-line
import translate from "../../../translates/translations";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { findLocation } from "../../../services/api";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ListGroup,
  ListGroupItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import DropdownFlame from "../atoms/dropdownFlame";
import {
  place_pickup,
  place_dropoff,
  info_user,
} from "../../../redux/actions/home";
import {
  companySelected,
  reload,
  renderFleet,
} from "../../../redux/actions/step1";
import { standarDateTimeText } from "../../../utils/standard";
import { useForm, Controller } from "react-hook-form";
import { setShowReserve } from "../../../redux/actions/home";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import useScreenSize from "../../../utils/useScreenSize";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const building = (
  <svg
    className="buildingIcon"
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="12"
    viewBox="0 0 512 512"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m204.869 379.739h102.261v132.261h-102.261z" />
      <path d="m165.24 96.309h43.508v43.508h-43.508z" />
      <path d="m303.252 96.309h43.508v43.508h-43.508z" />
      <path d="m165.24 235.954h43.508v43.508h-43.508z" />
      <path d="m303.252 235.954h43.508v43.508h-43.508z" />
      <path d="m482.284 482h-39.573v-467c0-8.284-6.716-15-15-15h-343.423c-8.284 0-15 6.716-15 15v467h-39.572c-8.284 0-15 6.716-15 15s6.716 15 15 15h145.154v-147.261c0-8.284 6.716-15 15-15h132.26c8.284 0 15 6.716 15 15v147.261h145.154c8.284 0 15-6.716 15-15s-6.715-15-15-15zm-105.524-400.691v73.508c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm-138.012 213.153c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm0-139.645c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm49.504 154.645c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15v73.508c0 8.284-6.716 15-15 15z" />
    </g>
  </svg>
);
const plane = (
  <svg
    className="planeIcon"
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="12"
    viewBox="0 0 512 512"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          d="M506.352,5.648c-39.256-13.092-81.806-3.045-111.068,26.217L288.379,138.768L65.64,43.31L23.213,85.735l180.313,137.886
       l-97.46,97.46l-63.64-21.215L0,342.294l106.066,63.64L169.706,512l42.426-42.427l-21.213-63.638l97.46-97.46l137.886,180.313
       l42.426-42.427l-95.458-222.739l106.904-106.904C509.399,87.456,519.446,44.906,506.352,5.648z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M479.297,266.048c-11.716-11.717-30.71-11.717-42.427,0l-8.967,8.967l25.455,59.398l25.939-25.939
       C491.012,296.757,491.012,277.764,479.297,266.048z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M245.952,32.701c-11.715-11.717-30.71-11.717-42.426,0l-25.939,25.94l59.398,25.455l8.967-8.969
       C257.669,63.412,257.669,44.418,245.952,32.701z"
        />
      </g>
    </g>
  </svg>
);

function CopyStep0(props) {
  const [value, onChange] = useState([new Date(), new Date()]);
  const mobile = useScreenSize();

  const {
    place_pickup,
    place_dropoff,
    info_user,
    companySelected,
    setShowReserve,
    saveDate,
    reload,
    handleClose,
    renderFleet,
  } = props;
  const { text1, text2, text3, text4, button1 } = translate.home.widget;
  const language = props.lang === "ru" ? "pt" : props.lang;
  let history = useHistory();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const infoDrown = {
    title: text3,
    items: ["+25", "24", "23", "22", "21", "20", "19", "18"],
  };

  useEffect(() => {
    let cero = moment(value[0]).format("YYYY-MM-DD");
    let one = moment(value[1]).format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");

    if (cero !== today && one !== today) {
      setDate((prevState) => ({
        ...prevState,
        startDate: cero,
        endDate: one,
      }));
      setValue(`startDate`, cero);
      setValue(`endDate`, one);
    }
  }, [value]);

  const [date, setDate] = useState({
    startDate: "",
    startTime: "10:00",
    endDate: "",
    endTime: "10:00",
  });
  const [age, setAge] = useState("");
  const [startLocation, setStartLocation] = useState([]);
  const [endLocation, setEndLocation] = useState([]);
  const [startLocationFocus, setStartLocationFocus] = useState(false);
  const [endLocationFocus, setEndLocationFocus] = useState(false);
  const [placeSelected, setPlaceSelected] = useState("");
  const [dateSelected, setDateSelected] = useState({
    startLocation: "",
    endLocation: "",
    timer: null,
    item: "",
  });

  const todayMinDate = new Date();
  const tomorrowMinDate = new Date(todayMinDate);
  tomorrowMinDate.setDate(tomorrowMinDate.getDate() + 1);

  useEffect(() => {
    handleDown({
      value: saveDate.info_user.passenger_age,
      label: saveDate.info_user.passenger_age,
    });
    saveDataHandleOnSelect();
    onChange([
      moment(saveDate.info_user.pickup_date),
      moment(saveDate.info_user.dropoff_date),
    ]);
  }, []);

  const handleDown = (e) => {
    setAge(e);
    setValue("age", e.value);
  };

  function dateTime(e, name) {
    if (name.includes("Time")) {
      let m = moment(e._d).format("HH:mm");
      setDate((prevState) => ({ ...prevState, [name]: m }));
      setValue(`${[name]}`, m);
    }
  }

  const handleOnChange = (e) => {
    setDateSelected((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      item: e.target.name,
    }));
  };

  useEffect(() => {
    handleCheck();
  }, [dateSelected]);

  const handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(dateSelected.timer);
    dateSelected.timer = setTimeout(() => {
      if (dateSelected.item) callApi();
    }, 1000);
  };

  const callApi = () => {
    if (
      dateSelected[dateSelected.item].length > 2 &&
      dateSelected[dateSelected.item].length < 15
    ) {
      if (dateSelected.item === "startLocation") {
        findLocation({ location: dateSelected[dateSelected.item] })
          .then((response) => response.data)
          .then((res) => setStartLocation(res))
          .catch((error) => console.log(error));
      } else {
        findLocation({
          location: dateSelected[dateSelected.item],
          idCompany: placeSelected && placeSelected.idCompany,
        })
          .then((response) => response.data)
          .then((res) => setEndLocation(res))
          .catch((error) => console.log(error));
      }
    }
  };

  const renderList = (name) => {
    if (name === "startLocation") {
      return (
        <ListGroup className="ho-wi-list">
          {startLocation &&
            startLocation.map((place, i) => (
              <ListGroupItem
                key={`${place.name}${i}`}
                onMouseDown={() => handleOnSelect(place, name)}
              >
                {place.idCompany === 0 && (
                  <img
                    src="/img/avisbudget_logo.svg"
                    alt=""
                    className="avisbudget"
                  />
                )}
                {place.idCompany === 1 && (
                  <img
                    src="/img/avis.svg"
                    alt=""
                    width="500"
                    className="avis"
                  />
                )}
                {place.idCompany === 2 && (
                  <img
                    src="/img/budget.svg"
                    alt=""
                    width="500"
                    className="budget"
                  />
                )}
                {place.officeData[0].AtAirport ? plane : building}
                {place.name}
              </ListGroupItem>
            ))}
        </ListGroup>
      );
    } else {
      return (
        <ListGroup className="ho-wi-list">
          {endLocation &&
            endLocation.map((place, i) => (
              <ListGroupItem
                key={`${place.name}${i}`}
                onMouseDown={() => handleOnSelect(place, name)}
              >
                {place.idCompany === 0 && (
                  <img
                    src="/img/avisbudget_logo.svg"
                    alt=""
                    className="avisbudget"
                  />
                )}
                {place.idCompany === 1 && (
                  <img
                    src="/img/avis.svg"
                    alt=""
                    width="500"
                    className="avis"
                  />
                )}
                {place.idCompany === 2 && (
                  <img
                    src="/img/budget.svg"
                    alt=""
                    width="500"
                    className="budget"
                  />
                )}
                {place.officeData[0].AtAirport ? plane : building}
                {place.name}
              </ListGroupItem>
            ))}
        </ListGroup>
      );
    }
  };

  const handleOnSelect = (place, name) => {
    if (name === "startLocation") {
      setDateSelected((prev) => ({
        ...prev,
        startLocation: place.name,
        endLocation: place.name,
      }));
      setValue("retirada", place.name);
      setValue("devolucao", place.name);
      place_pickup(place);
      companySelected(place.idCompany);
      setPlaceSelected(place);
      place_dropoff(place);
    } else {
      setDateSelected((prev) => ({
        ...prev,
        endLocation: place.name,
      }));
      companySelected(place.idCompany);
      place_dropoff(place);
      setValue("devolucao", place.name);
    }
  };

  const saveDataHandleOnSelect = () => {
    setValue("retirada", saveDate.place_pickup.name);
    setValue("devolucao", saveDate.place_dropoff.name);
  };

  const widgetForm = (data) => {
    const body = {
      pickup_date: date.startDate,
      pickup_time: date.startTime,
      dropoff_date: date.endDate,
      dropoff_time: date.endTime,
      passenger_country_id: 31,
      passenger_age: age.value,
      pickup_date_time: standarDateTimeText(
        date.startDate,
        date.startTime,
        language
      ),
      dropoff_date_time: standarDateTimeText(
        date.endDate,
        date.endTime,
        language
      ),
    };
    // console.log(body, "body");
    info_user(body);
    history.push("/step1");
    reload();
    handleClose();
    renderFleet(true);
  };

  return (
    <Container fluid className="ho-wi-step0">
      {!mobile.mobileView ? (
        <form key={1} onSubmit={handleSubmit(widgetForm)} id="widgetForm">
          <Row className="home-div1-container">
            <Col className="p-0 inputLocation-Container">
              <div
                className={`group-location-container ${
                  errors.retirada ? "help2" : ""
                }`}
              >
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="ge-in-img">
                      <img
                        src={"/icon/map-pin.svg"}
                        alt=""
                        className="ge-in-ico"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    name="retirada"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={() => (
                      <Input
                        placeholder={
                          saveDate.place_pickup.name
                            ? saveDate.place_pickup.name
                            : text1
                        }
                        className="ge-in-pla"
                        name="startLocation"
                        onChange={handleOnChange}
                        onFocus={() => setStartLocationFocus(true)}
                        onBlur={() => setStartLocationFocus(false)}
                        value={dateSelected.startLocation}
                        type="text"
                        autoComplete="off"
                        id="startLocation"
                      />
                    )}
                  />
                </InputGroup>
                {startLocationFocus && renderList("startLocation")}
              </div>
            </Col>
            <Col className="group-inputs">
              <div
                className={`group-input-container ${
                  errors.startDate ? "help3" : ""
                }`}
              >
                <div className="group-input-img">
                  <img src="/icon/calendar.svg" alt="" />
                </div>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={() => (
                    <DateRangePicker
                      onChange={onChange}
                      dateFormat={"DD/MM/YYYY"}
                      value={value}
                      calendarClassName="calendar"
                      calendarIcon={null}
                      className="datepickerContainer1 datepickerContainer"
                      clearIcon={null}
                      rangeDivider={null}
                      minDate={tomorrowMinDate}
                      name="startDate"
                      locale="pt-BR"
                    />
                  )}
                />
              </div>
            </Col>
            <Col className="group-inputs">
              <div className={`group-input-container clock`}>
                <div className="group-input-img">
                  <img src="/icon/clock.svg" alt="" />
                </div>
                <Controller
                  name="startTime"
                  control={control}
                  defaultValue=""
                  render={() => (
                    <Datetime
                      dateFormat={false}
                      onChange={(e) => dateTime(e, "startTime")}
                      timeFormat={"HH:mm"}
                      timeConstraints={{ minutes: { step: 30 } }}
                      initialValue={"10:00"}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row className="home-div1-container">
            <Col className="p-0 inputLocation-Container">
              <div
                className={`group-location-container ${
                  errors.devolucao ? "help2" : ""
                }`}
              >
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="ge-in-img">
                      <img
                        src={"/icon/map-pin.svg"}
                        alt=""
                        className="ge-in-ico"
                      />{" "}
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    name="devolucao"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={() => (
                      <Input
                        placeholder={
                          saveDate.place_dropoff.name
                            ? saveDate.place_dropoff.name
                            : text2
                        }
                        name="endLocation"
                        className="ge-in-pla"
                        onChange={handleOnChange}
                        onFocus={() => setEndLocationFocus(true)}
                        onBlur={() => setEndLocationFocus(false)}
                        value={dateSelected.endLocation}
                        autoComplete="off"
                        type="text"
                      />
                    )}
                  />
                </InputGroup>
                {endLocationFocus && renderList("endLocation")}
              </div>
            </Col>
            <Col className="group-inputs">
              <div
                className={`group-input-container ${
                  errors.endDate ? "help3" : ""
                }`}
              >
                <div className="group-input-img">
                  <img src="/icon/calendar.svg" alt="" />
                </div>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={() => (
                    <DateRangePicker
                      dateFormat={"DD/MM/YYYY"}
                      calendarClassName="calendar"
                      calendarIcon={null}
                      className="datepickerContainer2 datepickerContainer"
                      clearIcon={null}
                      onChange={onChange}
                      value={value}
                      rangeDivider={null}
                      minDate={tomorrowMinDate}
                      name="endDate"
                      locale="pt-BR"
                    />
                  )}
                />
              </div>
            </Col>
            <Col className="group-inputs">
              <div className={`group-input-container clock`}>
                <div className="group-input-img">
                  <img src="/icon/clock.svg" alt="" />
                </div>
                <Controller
                  name="endTime"
                  control={control}
                  defaultValue=""
                  render={() => (
                    <Datetime
                      dateFormat={false}
                      onChange={(e) => dateTime(e, "endTime")}
                      timeFormat={"HH:mm"}
                      timeConstraints={{ minutes: { step: 30 } }}
                      initialValue={"10:00"}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row className="home-div2-container">
            <Col className="ageReserve-Container p-0">
              <Col className="p-0">
                <div className={`ho-step0-drown ${errors.age ? "help4" : ""} `}>
                  <Controller
                    name="age"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={() => (
                      <DropdownFlame
                        info={infoDrown}
                        handle={handleDown}
                        state={age}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col className="p-0 reserveHome">
                <div
                  className="ho-wid-pro"
                  onClick={() => setShowReserve(true)}
                >
                  <img src={"/icon/search.svg"} alt="" />
                  <p>{text4}</p>
                </div>
              </Col>
            </Col>
            <Col className="p-0">
              <div className="home-div2-button">
                <Button
                  type="submit"
                  className="ho-widget-step0-button"
                  color="primary"
                >
                  {button1}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      ) : (
        <form key={1} onSubmit={handleSubmit(widgetForm)} id="widgetForm">
          <Row className="home-div1-container">
            <Col className="p-0 inputLocation-Container" sm={12}>
              <div
                className={`group-location-container ${
                  errors.retirada ? "help2" : ""
                }`}
              >
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="ge-in-img">
                      <img
                        src={"/icon/map-pin.svg"}
                        alt=""
                        className="ge-in-ico"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    name="retirada"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={() => (
                      <Input
                        placeholder={
                          saveDate.place_pickup.name
                            ? saveDate.place_pickup.name
                            : text1
                        }
                        className="ge-in-pla"
                        name="startLocation"
                        onChange={handleOnChange}
                        onFocus={() => setStartLocationFocus(true)}
                        onBlur={() => setStartLocationFocus(false)}
                        value={dateSelected.startLocation}
                        type="text"
                        autoComplete="off"
                        id="startLocation"
                      />
                    )}
                  />
                </InputGroup>
                {startLocationFocus && renderList("startLocation")}
              </div>
            </Col>
            <Col className="group-inputs pl-0" sm={6}>
              <div
                className={`group-input-container ${
                  errors.startDate ? "help3" : ""
                }`}
              >
                <div className="group-input-img">
                  <img src="/icon/calendar.svg" alt="" />
                </div>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={() => (
                    <DateRangePicker
                      onChange={onChange}
                      dateFormat={"DD/MM/YYYY"}
                      value={value}
                      calendarClassName="calendar"
                      calendarIcon={null}
                      className="datepickerContainer1 datepickerContainer"
                      clearIcon={null}
                      rangeDivider={null}
                      minDate={tomorrowMinDate}
                      name="startDate"
                      locale="pt-BR"
                    />
                  )}
                />
              </div>
            </Col>
            <Col className="group-inputs" sm={6}>
              <div className={`group-input-container clock`}>
                <div className="group-input-img">
                  <img src="/icon/clock.svg" alt="" />
                </div>
                <Controller
                  name="startTime"
                  control={control}
                  defaultValue=""
                  render={() => (
                    <Datetime
                      dateFormat={false}
                      onChange={(e) => dateTime(e, "startTime")}
                      timeFormat={"HH:mm"}
                      timeConstraints={{ minutes: { step: 30 } }}
                      initialValue={"10:00"}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row className="home-div1-container">
            <Col className="p-0 inputLocation-Container" sm={12}>
              <div
                className={`group-location-container ${
                  errors.devolucao ? "help2" : ""
                }`}
              >
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="ge-in-img">
                      <img
                        src={"/icon/map-pin.svg"}
                        alt=""
                        className="ge-in-ico"
                      />{" "}
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    name="devolucao"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={() => (
                      <Input
                        placeholder={
                          saveDate.place_dropoff.name
                            ? saveDate.place_dropoff.name
                            : text2
                        }
                        name="endLocation"
                        className="ge-in-pla"
                        onChange={handleOnChange}
                        onFocus={() => setEndLocationFocus(true)}
                        onBlur={() => setEndLocationFocus(false)}
                        value={dateSelected.endLocation}
                        autoComplete="off"
                        type="text"
                      />
                    )}
                  />
                </InputGroup>
                {endLocationFocus && renderList("endLocation")}
              </div>
            </Col>
            <Col className="group-inputs pl-0" sm={6}>
              <div
                className={`group-input-container ${
                  errors.endDate ? "help3" : ""
                }`}
              >
                <div className="group-input-img">
                  <img src="/icon/calendar.svg" alt="" />
                </div>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={() => (
                    <DateRangePicker
                      dateFormat={"DD/MM/YYYY"}
                      calendarClassName="calendar"
                      calendarIcon={null}
                      className="datepickerContainer2 datepickerContainer"
                      clearIcon={null}
                      onChange={onChange}
                      value={value}
                      rangeDivider={null}
                      minDate={tomorrowMinDate}
                      name="endDate"
                      locale="pt-BR"
                    />
                  )}
                />
              </div>
            </Col>
            <Col className="group-inputs" sm={6}>
              <div className={`group-input-container clock`}>
                <div className="group-input-img">
                  <img src="/icon/clock.svg" alt="" />
                </div>
                <Controller
                  name="endTime"
                  control={control}
                  defaultValue=""
                  render={() => (
                    <Datetime
                      dateFormat={false}
                      onChange={(e) => dateTime(e, "endTime")}
                      timeFormat={"HH:mm"}
                      timeConstraints={{ minutes: { step: 30 } }}
                      initialValue={"10:00"}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row className="home-div2-container">
            <Col sm={6} className="ageReserve-Container p-0">
              <Col className="p-0">
                <div className={`ho-step0-drown ${errors.age ? "help4" : ""} `}>
                  <Controller
                    name="age"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={() => (
                      <DropdownFlame
                        info={infoDrown}
                        handle={handleDown}
                        state={age}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col className="p-0 reserveHome">
                <div
                  className="ho-wid-pro"
                  onClick={() => setShowReserve(true)}
                >
                  <img src={"/icon/search.svg"} alt="" />
                  <p>{text4}</p>
                </div>
              </Col>
            </Col>
            <Col className="p-0" sm={6}>
              <div className="home-div2-button">
                <Button
                  type="submit"
                  className="ho-widget-step0-button"
                  color="primary"
                >
                  {button1}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    saveDate: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    place_pickup: (payload) => dispatch(place_pickup(payload)),
    place_dropoff: (payload) => dispatch(place_dropoff(payload)),
    info_user: (payload) => dispatch(info_user(payload)),
    companySelected: (payload) => dispatch(companySelected(payload)),
    setShowReserve: (payload) => dispatch(setShowReserve(payload)),
    reload: () => dispatch(reload()),
    renderFleet: (payload) => dispatch(renderFleet(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyStep0);
