import { 
    FLEET,
    RESET_FLEET,
    BACKUP_FLEET,
    CLEAR_FILTER,
} from '../actions/filter';

const initialState = {
    fleet: '',
    backupFleet: '',
    resetFleet: '',
}

const filter = (state = initialState, action) => {
    switch(action.type){
        case FLEET:
            return {
                ...state,
                fleet: action.payload
            }    
        case BACKUP_FLEET:
            return {
                ...state,
                backupFleet: action.payload
            }    
        case RESET_FLEET:
            return {
                ...state,
                fleet: state.backupFleet
            }      
        case CLEAR_FILTER:
            return {
                fleet: '',
                backupFleet: '',
                resetFleet: '',
            }       
        default: {
            return state;
        }
    }    
}

export default filter;