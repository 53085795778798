import { useState, useEffect } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import { Input, Row, Col } from "reactstrap";
import DataClient from "./dataClient";
import DropdownFlame from "../atoms/dropdownFlame";
import {
  typeClient,
  discountNumber,
  regardsNumber,
  cupom,
  corporation,
  validation,
  agency,
  totalCharge,
  totalTax,
  taxes,
  userId,
  setPartner,
} from "../../../redux/actions/step2";
import translate from "../../../translates/translations";
import { useForm, Controller } from "react-hook-form";
import {
  validateOrganization,
  rewardValidate,
  sumEquipment,
  partners,
} from "../../../services/api";
import { handleError, handleWarning } from "../../../utils/error";
import ButtonLoader from "../atoms/ButtonLoader";

function ClientType(props) {
  const {
    typeClient,
    discountNumber,
    regardsNumber,
    cupom,
    setPartner,
    partner,
    corporation,
    validation,
    agency,
    check,
    infoAgency,
    infoCorporate,
    airlines,
    frequent,
    body,
    totalCharge,
    totalTax,
    taxes,
    userId,
    equipment,
  } = props;
  const { info1, info2, info3, customer1, customer2, customer3, optional } =
    translate.step2;
  const { error2, error6, error21, success1, error5, success2, success3 } =
    translate.general.error;

  const [type, setType] = useState("");
  const [codeCupom, setCodeCupom] = useState("");
  const [codeRegardsNumber, setCodeRegardsNumber] = useState("");
  const [loaderButtons, setLoaderButtons] = useState({
    cupom: false,
    rewards: false,
    parceiros: false,
    company: false,
    agency: false,
  });

  const handlerLoaderButtons = (name, boolean) => {
    setLoaderButtons((prev) => ({
      ...prev,
      [name]: boolean,
    }));
  };

  const handleClient = (e) => {
    setType(e);
  };

  const infoClient = {
    title: info1.text9,
    items: [customer1, customer2, customer3],
  };

  useEffect(() => {
    typeClient(type.value);
  }, [type]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm();

  const {
    control: control3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
  } = useForm();

  const formUser = (data) => {
    let name = "";
    let request = {};

    if (data.emailCorporate) {
      name = "company";
      request = {
        ...body,
        equipment: equipment,
        email: data.emailCorporate,
        password: data.senhaCorporate,
        identifier: data.operatorCorporate,
        type: 2,
      };
    } else {
      name = "agency";
      request = {
        ...body,
        equipment: equipment,
        email: data.emailAgency,
        password: data.senhaAgency,
        type: 1,
        identifier: data.operatorAgency,
      };
    }

    handlerLoaderButtons(name, true);
    validateOrganization(request)
      .then((response) => response.data)
      .then((res) => validationUser(res, name))
      .catch((error) => {
        handlerLoaderButtons(name, false);
        if (error.response.data.error) {
          handleError(error.response.data.error, 1);
        } else {
          handleError(error2, 1);
        }
      });
  };

  const validationUser = async (response, name) => {
    handlerLoaderButtons(name, false);
    if (response.validation === "true") {
      if (type.value === customer2) {
        await corporation(response.information);
      }
      if (type.value === customer3) {
        await agency(response.information);
      }
      await handleError(success1, 2);
      await validation();
      userId(response.information.user_id);
      totalCharge(response.TotalCharge);
      totalTax(response.VehicleCharges.total_tax);
      taxes(response.VehicleCharges.taxes);
      reset();
      reset2();
    } else {
      await handleError(error6, 1);
    }
  };

  const OnRewards = () => {
    if (!codeRegardsNumber) {
      return setError("codeRegardsNumber", {
        type: "manual",
        message: "Required",
      });
    } else {
      handlerLoaderButtons("rewards", true);
      const body = {
        rewards_number: codeRegardsNumber,
      };
      rewardValidate(body)
        .then((response) => response.data)
        .then((res) => {
          if (res.message === "validado") {
            handleError(success2, 2);
            regardsNumber(codeRegardsNumber);
          } else {
            setCodeRegardsNumber("");
            handleError(error5, 1);
          }
          handlerLoaderButtons("rewards", false);
        })
        .catch((error) => {
          handlerLoaderButtons("rewards", false);
          handleError(error5, 1);
          setCodeRegardsNumber("");
          console.log(error);
        });
    }
  };

  const onCodes = () => {
    const code = {};

    if (!codeCupom) {
      return setError("cupom", {
        type: "manual",
        message: "Required",
      });
    } else {
      handlerLoaderButtons("cupom", true);
      code.coupon = codeCupom;
      code.coupon_validate = "true";

      sumEquipment({ ...body, ...code })
        .then((response) => response.data)
        .then((res) => {
          if (res.total_tax) {
            handleError(success2, 2);
            totalCharge(res.TotalCharge);
            totalTax(res.total_tax);
            taxes(res.taxes);
            cupom(codeCupom);
          } else {
            handleError(error5, 1);
          }
          handlerLoaderButtons("cupom", false);
        })
        .catch((error) => {
          setCodeCupom("");
          handlerLoaderButtons("cupom", false);
          if (error.response.status === 422) {
            handleError(error21, 1);
          } else if (error.response.status === 404) {
            handleError(error5, 1);
          } else {
            handleError(error5, 1);
          }
        });
    }
  };

  const parceiros = (data) => {
    handlerLoaderButtons("parceiros", true);
    partners({ ...body, partner_code: data.id, equipment: equipment })
      .then((response) => response.data)
      .then((info) => {
        totalCharge(info.TotalCharge);
        totalTax(info.VehicleCharges.total_tax);
        taxes(info.VehicleCharges.taxes);
        return info;
      })
      .then((res) => {
        handleWarning(success3);
        handleClient({ label: customer1, value: customer1 });
        validation();
        setPartner({
          partner_code: data.id,
          partner_name: res.partner_name,
        });
        discountNumber(res.discount_number);
        handlerLoaderButtons("parceiros", false);
      })
      .catch((error) => {
        handleError(error5, 1);
        console.log(error.response);
        handlerLoaderButtons("parceiros", false);
      });
  };

  return (
    <div>
      <div className="s2-ClientType" id="s2-ClientType">
        {sessionStorage.parceiros === "true" ? (
          <div>
            <h6>{info1.title4}</h6>
            <hr />
            {!check && (
              <form
                key={23}
                onSubmit={handleSubmit3(parceiros)}
                id="form2"
                className="parceiros  m-0 p-0"
              >
                <Row className="m-0 p-0 w-100">
                  <Col xs={12} md="7" className="p-0 pr-0 pr-md-3 mb-3 mb-md-0">
                    <Controller
                      name="id"
                      control={control3}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          placeholder={info1.text10}
                          onChange={onChange}
                          value={value}
                          type="text"
                          autoComplete="off"
                          className={errors3.id && "help"}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} md="5" className="p-0">
                    <ButtonLoader
                      text={info2.button1}
                      form="form2"
                      loader={loaderButtons.parceiros}
                      submit="submit"
                    />
                  </Col>
                </Row>
              </form>
            )}
          </div>
        ) : (
          <div>
            <h6>{info1.title1}</h6>
            <hr />
            {!check && (
              <DropdownFlame
                info={infoClient}
                handle={handleClient}
                state={type}
              />
            )}
          </div>
        )}

        {check && type.value === customer1 && (
          <div className="s2-ClientType-welcome">
            <p>
              {info2.text1}
              <span>{partner.partner_name} </span>!
            </p>
          </div>
        )}
        {check && type.value === customer2 && (
          <div className="s2-ClientType-welcome">
            <p>
              {info2.text1}
              <span>{infoCorporate.name_user} </span>
              {info2.text2} {infoCorporate.name_organization}!
            </p>
            <p>{info2.text3}</p>
          </div>
        )}
        {check && type.value === customer3 && (
          <div className="s2-ClientType-welcome">
            <p>
              {info3.text1}
              <span>{infoAgency.name_user} </span>
              {info3.text2} {infoAgency.name_organization}!
            </p>
            <p>{info3.text3}</p>
            <div>
              <Input
                type="text"
                placeholder={info3.text7}
                onChange={(e) => setCodeRegardsNumber(e.target.value)}
                className={errors.codeRegardsNumber && "help"}
              />
              <ButtonLoader
                text={info3.button2}
                onClick={OnRewards}
                loader={loaderButtons.rewards}
              />
            </div>
          </div>
        )}
        {type.value === customer2 && !check && (
          <form key={20} onSubmit={handleSubmit(formUser)} id="formCompany">
            <Row className="s2-ClientType-agency m-0 p-0">
              <Col xs={12} sm={6} className="m-0 p-0 pr-0 pr-sm-3">
                <Controller
                  name="emailCorporate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Debe ser un email válido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="text"
                      placeholder={info2.text4}
                      onChange={onChange}
                      value={value}
                      className={errors.emailCorporate && "help"}
                    />
                  )}
                />
              </Col>
              <Col xs={12} sm={6} className="m-0 p-0">
                <Controller
                  name="senhaCorporate"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="password"
                      placeholder={info3.text5}
                      onChange={onChange}
                      value={value}
                      className={errors.senhaCorporate && "help"}
                    />
                  )}
                />
              </Col>
              <Col xs={12} sm={6} className="m-0 p-0 pr-0 pr-sm-3">
                <Controller
                  name="operatorCorporate"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="text"
                      placeholder={info2.text6}
                      onChange={onChange}
                      value={value}
                      className={errors.operatorCorporate && "help"}
                    />
                  )}
                />
              </Col>
              <Col xs={12} sm={6} className="m-0 p-0">
                <ButtonLoader
                  text={info2.button1}
                  form="formCompany"
                  loader={loaderButtons.company}
                  submit="submit"
                />
              </Col>
            </Row>
          </form>
        )}
        {type.value === customer3 && !check && (
          <form key={21} onSubmit={handleSubmit2(formUser)} id="form3">
            <Row className="s2-ClientType-agency m-0 p-0">
              <Col xs={12} sm={6} className="m-0 p-0 pr-0 pr-sm-3">
                <Controller
                  name="emailAgency"
                  control={control2}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Debe ser un email válido",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="text"
                      placeholder={info3.text4}
                      onChange={onChange}
                      value={value}
                      className={errors2.emailAgency && "help"}
                    />
                  )}
                />
              </Col>
              <Col xs={12} sm={6} className="m-0 p-0">
                <Controller
                  name="senhaAgency"
                  control={control2}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="password"
                      placeholder={info2.text5}
                      onChange={onChange}
                      value={value}
                      className={errors2.senhaAgency && "help"}
                    />
                  )}
                />
              </Col>
              <Col xs={12} sm={6} className="m-0 p-0 pr-0 pr-sm-3">
                <Controller
                  name="operatorAgency"
                  control={control2}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="text"
                      placeholder={info3.text6}
                      onChange={onChange}
                      value={value}
                      className={errors2.operatorAgency && "help"}
                    />
                  )}
                />
              </Col>
              <Col xs={12} sm={6} className="m-0 p-0">
                <ButtonLoader
                  text={info3.button1}
                  form="form3"
                  loader={loaderButtons.agency}
                  submit="submit"
                />
              </Col>
            </Row>
          </form>
        )}
      </div>
      {type.value === customer1 && !check && (
        <div>
          <DataClient airlines={airlines} frequent={frequent} body={body} />
          <div className="s2-ClientType-info2">
            <h6>{optional.title}</h6>
            <hr />
            <div>
              <div>
                <Input
                  type="text"
                  placeholder={optional.text2}
                  onChange={(e) => setCodeCupom(e.target.value)}
                  className={errors.cupom && "help"}
                />
                <ButtonLoader
                  text={optional.button2}
                  onClick={onCodes}
                  loader={loaderButtons.cupom}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {check && (
        <div>
          <DataClient airlines={airlines} frequent={frequent} body={body} />
          <div className="s2-ClientType-info2">
            <h6>{optional.title}</h6>
            <hr />
            <div>
              <div>
                <Input
                  type="text"
                  placeholder={optional.text2}
                  onChange={(e) => setCodeCupom(e.target.value)}
                  className={errors.cupom && "help"}
                />
                <ButtonLoader
                  text={optional.button2}
                  onClick={onCodes}
                  loader={loaderButtons.cupom}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    check: state.step2.validation,
    infoAgency: state.step2.agency,
    infoCorporate: state.step2.corporation,
    partner: state.step2.partner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    typeClient: (payload) => dispatch(typeClient(payload)),
    cupom: (payload) => dispatch(cupom(payload)),
    regardsNumber: (payload) => dispatch(regardsNumber(payload)),
    discountNumber: (payload) => dispatch(discountNumber(payload)),
    corporation: (payload) => dispatch(corporation(payload)),
    validation: () => dispatch(validation()),
    agency: (payload) => dispatch(agency(payload)),
    totalCharge: (payload) => dispatch(totalCharge(payload)),
    totalTax: (payload) => dispatch(totalTax(payload)),
    taxes: (payload) => dispatch(taxes(payload)),
    userId: (payload) => dispatch(userId(payload)),
    setPartner: (payload) => dispatch(setPartner(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientType);
