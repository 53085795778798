import { useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import translate from "../../../translates/translations";
import {
  standarName,
  standarDays,
  standarPrice,
  standarDateTimeText,
} from "../../../utils/standard";
import { cancelReserve, resendEmail } from "../../../services/api";
import { useHistory } from "react-router-dom";
import { to } from "../../../utils/redirect";
import { clear_home } from "../../../redux/actions/home";
import { clearStep1 } from "../../../redux/actions/step1";
import { clearStep2 } from "../../../redux/actions/step2";
import { clearStep4 } from "../../../redux/actions/step4";
import { clear_filter } from "../../../redux/actions/filter";
import { handleError, handleWarning } from "../../../utils/error";
import { Button, Container, Row, Col } from "reactstrap";
import ModalCancel from "../atoms/modalCancel";
import ModalSendEmail from "../atoms/ModalSendEmail";

function Confirmation(props) {
  const {
    location,
    car,
    reservation,
    rental,
    body,
    clearApp,
    data,
    changeSaleStatus,
  } = props;
  const { PickUpLocation, ReturnLocation } = location;
  const {
    title,
    text1,
    text2,
    text3,
    text4,
    text5,
    text9,
    text6,
    text7,
    text8,
    button1,
    button2,
    button3,
    button4,
  } = translate.step3.confirm;
  const { error10, error16, error2, success4 } = translate.general.error;
  const history = useHistory();
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setShowModalCancel(false);
    setShowModalEmail(false);
  };

  const deleteReserve = () => {
    setLoader(true);
    cancelReserve(body)
      .then((response) => response.data)
      .then((res) => {
        if (res.reservation && res.reservation.message === "Cancelled") {
          if (
            changeSaleStatus === 4 ||
            changeSaleStatus === 5 ||
            changeSaleStatus === 1
          ) {
            handleWarning(error16);
          } else {
            handleError(error10, 2);
          }
          setShowModalCancel(false);
          exit();
        } else {
          setShowModalCancel(false);
          handleError(error2, 1);
          // console.log(res);
        }
        setLoader(false);
      })
      .catch((error) => {
        handleError(error2, 1);
        console.log(error, "error");
        setShowModalCancel(false);
        setLoader(false);
      });
  };

  const exit = () => {
    history.push(to.home);
    clearApp();
  };

  const sendEmail = ({ email }) => {
    setLoader(true);
    const body = {
      email: email,
      passenger_lastname: reservation.customer.last_name,
      id_reservation: reservation.id,
      language: "pt",
    };
    resendEmail(body)
      .then((res) => res.data)
      .then((response) => {
        handleClose();
        setLoader(false);
        handleError(success4, 2);
        // console.log(response);
      })
      .catch((error) => {
        handleError(error2, 1);
        handleClose();
        setLoader(false);
        console.log(error);
      });
  };

  const handlePrint = () => window.open(reservation.pdf);

  return (
    <div className="s3-confirmation-container">
      <div className="s3-confirmation-square">
        <div className="s3-confirmation-divide">
          <h6>{title}</h6>
          <hr />
          <div className="s3-confirmation-reserve d-flex align-items-center">
            <p>{text1}</p>
            <img src="/icon/tag.svg" alt="" />
            <span>{reservation.id}</span>
          </div>
          <hr />
          <div>
            <p>
              {text2}:
              <span>
                {standarName(
                  reservation.customer.first_name,
                  reservation.customer.last_name
                )}
              </span>
            </p>
            <p>
              {text3}:
              <span>
                {standarDays(rental.PickUpDateTime, rental.ReturnDateTime)}
              </span>
            </p>
            <p>
              {text4}:
              <span>
                {car.TotalCharge.CurrencyCode +
                  " " +
                  standarPrice(car.TotalCharge.EstimatedTotalAmount)}
              </span>
            </p>
            {reservation.prepaid === "1" &&
            car.VehicleCharges.total_tax.Total_Equipment ? (
              <p>
                {text9}:{" "}
                <span>
                  {car.VehicleCharges.total_tax.CurrencyCode}
                  {" " +
                    standarPrice(car.VehicleCharges.total_tax.Total_Equipment)}
                </span>
              </p>
            ) : null}
          </div>
        </div>
        <div className="s3-confirmation-location">
          <h6>{text5}:</h6>
          <hr />
          <div>
            <img src="/icon/calendar.svg" alt="" />
            <p>
              {standarDateTimeText(null, null, "pt", rental.PickUpDateTime)}
            </p>
          </div>
          <hr />
          <span>{text6}:</span>
          <div className="mt-2">
            <img src="/icon/map-pin.svg" alt="" />
            <p>
              {PickUpLocation.Name} ({PickUpLocation.Code})
            </p>
          </div>
        </div>
        <div className="s3-confirmation-location">
          <h6>{text7}:</h6>
          <hr />
          <div>
            <img src="/icon/calendar.svg" alt="" />
            <p>
              {standarDateTimeText(null, null, "pt", rental.ReturnDateTime)}
            </p>
          </div>
          <hr />
          <span>{text8}:</span>
          <div className="mt-2">
            <img src="/icon/map-pin.svg" alt="" />
            <p>
              {ReturnLocation.Name} ({ReturnLocation.Code})
            </p>
          </div>
        </div>
      </div>
      <Row className="s3-confirmation-buttons m-0">
        <Col xs={12} sm={6} md={3} className="mb-3 mb-sm-0 p-0">
          <Button onClick={handlePrint} color="primary">
            <img src="/icon/printer.svg" alt="" />
            {button1}
          </Button>
        </Col>
        <Col xs={12} sm={6} md={3} className="mb-3 mb-md-0 p-0 pl-sm-3 px-md-3">
          <Button onClick={() => setShowModalEmail(true)} color="primary">
            <img src="/icon/send.svg" alt="" />
            {button2}
          </Button>
        </Col>
        <Col xs={12} sm={6} md={3} className="mb-3 mb-sm-0 p-0">
          <Button onClick={exit} color="primary">
            <img src="/icon/plus.svg" alt="" />
            {button3}
          </Button>
        </Col>
        <Col xs={12} sm={6} md={3} className="p-0 pl-sm-3">
          <Button onClick={() => setShowModalCancel(true)} color="primary">
            <img src="/icon/x.svg" alt="" />
            {button4}
          </Button>
        </Col>
      </Row>
      {showModalCancel && (
        <ModalCancel
          handleClose={handleClose}
          deleteReserve={deleteReserve}
          loader={loader}
          reservation={reservation}
        />
      )}
      {showModalEmail && (
        <ModalSendEmail
          handleClose={handleClose}
          loader={loader}
          sendEmail={sendEmail}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    info_user: state.home.info_user,
    data: state.step4.data,
    changeSaleStatus: state.step4.saleStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearApp: () => {
      dispatch(clear_home());
      dispatch(clearStep1());
      dispatch(clearStep2());
      dispatch(clearStep4());
      dispatch(clear_filter());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
