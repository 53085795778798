import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { standarDays } from "../../../utils/standard";
import InfoRate from "./infoRate";

function Summary({ rate, rental }) {
  const { title1, title2, text1 } = translate.step2.prices;

  return (
    <div className="s2-summary">
      <div className="s2-summary-icon">
        <p>{title1}</p>
      </div>
      <hr />
      <div className="s2-summary-icon">
        <img src="/icon/list.svg" alt="" />
        <p>{title2}</p>
      </div>
      <hr />
      <InfoRate car={rate} />
      <hr />
      <div className="s2-summary-icon mb-3">
        <img src="/icon/clock.svg" alt="" />
        <p>
          {text1} {standarDays(rental.pickup_date, rental.dropoff_date)}
        </p>
      </div>
      <hr />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    totalCharge: state.step2.totalCharge,
    rental: state.home.info_user,
  };
};

export default connect(mapStateToProps)(Summary);
