import { useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { Button } from "reactstrap";
import ModalLoja from "../atoms/modalLoja";
import ModalStep0 from "../atoms/modalStep0";

function CardLocation(props) {
  const { title, date, place } = props;
  const { text1, text2, button1 } = translate.step1.location;
  const [showModal, setShowModal] = useState(false);
  const [showTrocar, setShowTrocar] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleTrocar = () => {
    setShowTrocar(false);
  };

  return (
    <div className="s1-cardLocal">
      <h5>{title}</h5>
      <div className="s1-startDate">
        <div className="s1-cardLocal-date">
          <img src="/icon/calendar.svg" alt="" className="ge-in-ico" />
          <span>{date}</span>
        </div>
        <Button
          color="primary"
          className="s1-cardLocal-buttons"
          onClick={() => setShowTrocar(true)}
        >
          {button1}
        </Button>
      </div>
      <div className="address">
        <img src="/icon/map-pin.svg" alt="" />
        <div>
          <span>
            {place.Name} ({place.Code})
          </span>
          {place.Address && (
            <p>
              {place.Address.StreetNmbr} - {place.Address.PostalCode}{" "}
              {place.Address.CountryName &&
                `- ${place.Address.CountryName.Code}`}
            </p>
          )}
          <p>
            {text1}: {place.Telephone}
          </p>
          <span
            className="address-map cursor"
            onClick={() => setShowModal(true)}
          >
            {text2}
          </span>
        </div>
      </div>
      {showModal && <ModalLoja place={place} handleClose={handleClose} />}
      {showTrocar && <ModalStep0 handleClose={handleTrocar} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    place_pickup: state.home.place_pickup,
    place_dropoff: state.home.place_dropoff,
    info_user: state.home.info_user,
  };
};

export default connect(mapStateToProps)(CardLocation);
