import translate from "../../../translates/translations";
import { connect } from "react-redux";
import Step0 from "./step0";

function Widget() {
  return (
    <div className="widget">
      <div className="d-flex justify-content-center">
        <img src={"/icon/compass.svg"} alt="" />
        <h6>
          {translate.home.widget.title}
          <span>{translate.home.widget.title2}</span>
        </h6>
      </div>
      <div className="w-100 my-10">
        <Step0 />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  };
};

export default connect(mapStateToProps)(Widget);
