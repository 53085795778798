import { Container, Row, Col } from "reactstrap";
import translate from "../../../translates/translations";
import CopyStep0 from "../organism/copyStep0";

function ModalStep0(props) {
  const { handleClose } = props;
  const { title1 } = translate.modal.step0;

  return (
    <Container fluid className="modalstep0-container fade-in">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100">
          <Container fluid className="modalstep0">
            <Row className="d-flex justify-content-between w-100 modalstep0-row1">
              <Col sm="10" className="w-100 p-0 modalstep0-span">
                <span>{title1}</span>
              </Col>
              <Col sm="2" className="d-flex justify-content-end cursor p-0">
                <img
                  src="/icon/x_black.svg"
                  alt=""
                  onClick={() => handleClose()}
                />
              </Col>
            </Row>
            <Row className="modalstep0-row2 px-3">
              <Col>
                <CopyStep0 handleClose={handleClose} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalStep0;
