import { connect } from "react-redux";
import NamePrice from "./namePrice";
import translate from "../../../translates/translations";
import { standarPriceSwitch } from "../../../utils/standard";

function Equipment(props) {
    const { optionalEquipment, totalTax, stateSwitch, cotization } = props;
    const { title4, title5, title6 } = translate.step2.prices;

    return (
        <div>
            <div className="s2-equipment-title">
                <h6>{title4}</h6>
            </div>
            <div className="s2-equipment">
                {optionalEquipment &&
                    optionalEquipment.map((item, i) => (
                        <div>
                            <NamePrice
                                text={
                                    item.quantity
                                        ? `(${item.quantity}) ${item.name}`
                                        : item.name
                                }
                                price={
                                    item.quantity
                                        ? item.quantity * item.Amount
                                        : item.Amount
                                }
                                currency={item.CurrencyCode}
                                key={`${i}${item}`}
                                stateSwitch={stateSwitch}
                                cotization={cotization}
                            />
                        </div>
                    ))}
                <hr />
                <div className="s2-equipment-total">
                    <div>
                        <h6>{title5}:</h6>
                        <p>
                            <span>
                                {totalTax.Total_Tax
                                    ? standarPriceSwitch(
                                          totalTax.Total_Tax,
                                          cotization
                                      )
                                    : standarPriceSwitch(0, cotization)}
                            </span>
                        </p>
                    </div>
                    <div>
                        <h6>{title6}:</h6>
                        <p>
                            <span>
                                {totalTax.Total_Equipment
                                    ? standarPriceSwitch(
                                          totalTax.Total_Equipment,
                                          cotization
                                      )
                                    : standarPriceSwitch(0, cotization)}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
        optionalEquipment: state.step2.optionalEquipment,
        totalTax: state.step2.totalTax,
        cotization: state.step1.cotization,
    };
};

export default connect(mapStateToProps)(Equipment);
