import translate from "../../../translates/translations";
import Item from "../atoms/Item";

export default function CarSelected({ car, company, step }) {
  const {
    title1,
    title2,
    alert,
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7,
    item8,
  } = translate.step2.card;
  const icon = "/icon/chevron-right.svg";

  const carDetails = [
    `${car.Features2.seats} ${item1}`,
    `${car.Features2.largeSuitcase} ${
      car.Features2.largeSuitcase === "1" ? item2 : item7
    }`,
    `${car.Features2.smallSuitcase} ${
      car.Features2.smallSuitcase === "1" ? item3 : item8
    }`,
    `${car.Features2.doors} ${item4}`,
    `${
      car.Features2.transmition === "Automatic"
        ? item5
        : car.Features2.transmition
    }`,
    `${car.Features2.air === "Air Conditioning" ? item6 : car.Features2.air}`,
  ];
  const logo =
    company === "Budget"
      ? "/img/logo-budget-negative.svg"
      : "/img/logo-avis-negative.svg";

  return (
    <div className={step ? `s2-car-container` : ""}>
      <div className="s2-car-title">
        <h5>{title1}: </h5>
        <h5>
          <span>
            {car.Features2.category} ({car.Code})
          </span>
        </h5>
      </div>
      <hr className="w-100" />
      {step ? (
        <>
          <div className="s2-car">
            <div className="s2-car-con">
              <div className="s2-car-brand">
                <img src={logo} alt={company} />
                <p>{car.Name}</p>
              </div>
              <div className="s2-car-img">
                <img src={car.PictureURL || '/img/no-car.webp'} alt="car" />
              </div>
            </div>

            <div className="s2-charac">
              <h5>{title2}</h5>
              <div className="s2-items">
                {carDetails.map((detail) => (
                  <Item icon={icon} key={detail} text={detail} />
                ))}
              </div>
            </div>
          </div>
          <div className="s2-car-alert">
            <img src="/icon/alert-circle.svg" alt="icon" />
            <p>{alert}</p>
          </div>
        </>
      ) : (
        <div className="s3-car">
          <div className="s3-car-con">
            <div className="s3-car-brand">
              <img src={logo} alt={company} />
              <p>{car.Name}</p>
            </div>
            <div className="s3-car-img">
              <img src={car.PictureURL || '/img/no-car.webp'} alt="car" />
            </div>
          </div>
          <div className="s3-car-divide">
            <div className="s3-charac">
              <h5>{title2}</h5>
              <div className="s3-items">
                {carDetails.map((detail) => (
                  <Item icon={icon} key={detail} text={detail} />
                ))}
              </div>
            </div>
            <div className="s3-car-alert">
              <img src="/icon/alert-circle.svg" alt="icon" />
              <p>{alert}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
