
const spanish = {
  text: 'spanish',
  general : {
    navbar: {
      home: 'Inico',
      offices: 'Oficinas',
      searchReserve: 'Buscar reserva',
      signUp: 'Registarse',
      signIn: 'Iniciar sesión ',
      contact: 'Contáctenos'
    }
  }
}

export default spanish;