import { useState } from "react";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";

const items = [
  {
    src: "/img/imagen_slider_2.webp",
    altText: "Slide 1",
  },
  {
    src: "/img/imagen_slider_1.webp",
    altText: "Slide 1",
  },
  {
    src: "/img/imagen_slider_3.webp",
    altText: "Slide 1",
  },
  {
    src: "/img/imagen_slider_4.webp",
    altText: "Slide 1",
  },
];

const Example = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item, i) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`${item.altText}${i}`}
      >
        <img src={item.src} alt={item.altText} width="100%" height="auto" />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="ho-ca"
    >
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default Example;
