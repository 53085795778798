import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { to } from "../../../utils/redirect";
import { clear_home, setShowReserve } from "../../../redux/actions/home";
import { clearStep1 } from "../../../redux/actions/step1";
import { clearStep2 } from "../../../redux/actions/step2";
import { clearStep4 } from "../../../redux/actions/step4";
import { clear_filter } from "../../../redux/actions/filter";
import DropDownMobile from "./DropDownMobile";
import { connect } from "react-redux";

function NavbarMobile(props) {
  const { home, offices, searchReserve, signUp, signIn, contact, clearApp } =
    props;
  const [openDropdown, setOpenDropdown] = useState(false);
  const allClean = () => clearApp();

  return (
    <>
      <Container fluid className="m-0 p-0 ge-na">
        <Row className="d-flex justify-content-center align-items-center w-100 headerRowMobile">
          {/* <div className="ge-na"> */}
          <Navbar
            light
            expand="xs"
            className="m-0 p-0 w-100 navBarwrapperMobile"
          >
            <NavbarBrand className="navBarLogo">
              <Link to={to.home} onClick={allClean}>
                {" "}
                <img
                  src={"/img/avisbudget_logo.svg"}
                  alt="Avis Budget"
                  className="ge-img"
                />
              </Link>
            </NavbarBrand>

            <Nav
              fullwidth
              className="d-flex navbar-nav w-100 justify-content-end"
              navbar
            >
              <NavItem>
                <NavLink className="dropDownMenuContainer">
                  <Link
                    className="nav-link"
                    onClick={() => setOpenDropdown(!openDropdown)}
                  >
                    <img src="/icon/menu.svg" alt="" />
                  </Link>
                </NavLink>
              </NavItem>
            </Nav>
          </Navbar>
        </Row>
      </Container>
      {openDropdown ? (
        <DropDownMobile
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
          home={home}
          offices={offices}
          searchReserve={searchReserve}
          signUp={signUp}
          signIn={signIn}
          contact={contact}
        />
      ) : (
        ""
      )}
      {openDropdown ? <div className="blackGroundMobileMenu"></div> : ""}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowReserve: (payload) => dispatch(setShowReserve(payload)),
    clearApp: () => {
      dispatch(clear_home());
      dispatch(clearStep1());
      dispatch(clearStep2());
      dispatch(clearStep4());
      dispatch(clear_filter());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarMobile);
