import Subscribe from "../molecules/subscribe";
import Links from "../molecules/links";
import Copyright from "../atoms/copyright";
import { Container, Row } from "reactstrap";

export default function Footer() {
  return (
    <Container fluid className="m-0 p-0 footer ge-footer">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Container
          sm="12"
          md="10"
          lg="7"
          className="m-0 p-0 ge-fo-pa my-4 widthFixedDesktopSubscribe"
        >
          <Subscribe />
          <hr className="ge-fo-hr" />
          <Links />
        </Container>
      </Row>
      <Row className="ge-fo-con-copy">
        <Container sm="12" md="10" lg="7" className="m-0 p-0 widthFixedDesktop">
          <Copyright />
        </Container>
      </Row>
    </Container>
  );
}
