import translate from "../../../translates/translations";

export default function Copyright() {
  const { text1, text2, text3 } = translate.general.footer.copyright;

  return (
    <div className="container ge-fo-copyright">
      <div className="row">
        <div className="col-12 col-md-5 d-flex justify-content-center align-items-center justify-content-md-start m-0 p-0 fix-margin-copyright">
          <p className="text-center">{text1}</p>
        </div>
        <div className="col-12 col-md-7 d-flex flex-column flex-sm-row justify-content-center justify-content-md-end m-0 p-0">
          <div className="d-flex justify-content-center align-items-center pagarme-style">
            <p>{text2}</p>
            <img src="/img/pagarme_logo.svg" alt="" className="mb-1" />
          </div>
          <div className="d-flex ml-0 ml-sm-3 ml-lg-5 justify-content-center justify-content-md-end align-items-center">
            <p>{text3}</p>
            <img src="/img/greenflame_logo.svg" alt="greenflame" />
          </div>
        </div>
      </div>
    </div>
  );
}
