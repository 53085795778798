import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { standarPriceSwitch, standarPrice } from "../../../utils/standard";

function Total(props) {
    const { prepaid, totalCharge, cotization } = props;
    const { title7, title8 } = translate.step2.prices;

    return (
        <div className="s2-total">
            {prepaid === 0 ? (
                <div>
                    <h6>{title7}</h6>
                    <p>
                        <span>
                            {standarPriceSwitch(
                                totalCharge.EstimatedTotalAmount,
                                cotization
                            )}
                        </span>
                    </p>
                </div>
            ) : (
                <div className="d-flex flex-column">
                    <div>
                        <h6>{title7}</h6>
                        <p>
                            <span>
                                {standarPriceSwitch(
                                    totalCharge.EstimatedTotalAmount,
                                    cotization
                                )}
                            </span>
                        </p>
                    </div>
                    <hr />
                    <div>
                        <h6>{title8}</h6>
                        <p>
                            <span>
                                {totalCharge.CurrencyCode}{" "}
                                {standarPrice(
                                    totalCharge.EstimatedTotalAmountPrepaid
                                )}
                            </span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
        prepaid: state.step1.prepaid,
        totalCharge: state.step2.totalCharge,
        cotization: state.step1.cotization,
    };
};

export default connect(mapStateToProps)(Total);
