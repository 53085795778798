import { isProduction } from '../services/api';

const links = {};
if (isProduction) {
  links.api = process.env.REACT_APP_API_PRODUCTION;
  links.vs = process.env.REACT_APP_VS_PRODUCTION;
} else {
  links.api = process.env.REACT_APP_API_TEST;
  links.vs = process.env.REACT_APP_VS_TEST;
}

export const to = {
  home: '/',
  parceiros: '/parceiros',
  step1: '/step1',
  step2: '/step2',
  step3: '/step3',
  step4: '/step4',
  step5: '/step5',
  offices: '/offices',
  error: '/error',
  achados: 'https://www.avislostandfound.com/',
  faq: '/faq',
  cancellationPolicy: '/cancellation-policy',
  privacyPolicy: '/privacy-policy',
  conditions: '/conditions',
  cookies: '/cookies',
  contact: '/contato',
  reserve: '/reserve',
  GDS: '',
  agents: `${links.api}/organizations/create`,
  business: `${links.api}/organizations/create`,
  access: `${links.api}/login`,
  voucherSystem: links.vs,
  facebook: 'https://www.facebook.com/GSA-AVIS-Budget-108046560815737',
  instagram: 'https://www.instagram.com/gsaavisbudget/',
  avis: 'https://avis-int.com/conditions/',
  budget: 'https://budget-int.com/conditions/',
  pre: `${document.location.origin}/pdf/pre.pdf`,
  pod: `${document.location.origin}/pdf/pod.pdf`,
};

export const moveTo = (elementId) => {
  window.location.hash = `#${elementId}`;
};
