import React from 'react'; // eslint-disable-line
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import translate from '../../../translates/translations';
import { to } from '../../../utils/redirect';
import { setShowReserve } from '../../../redux/actions/home';
import { Container, Row, Col } from 'reactstrap';

function Links(props) {
  const {
    title1,
    title2,
    title3,
    title4,
    text1_1,
    text2_1,
    text2_2,
    text2_3,
    text2_4,
    text3_1,
    text3_2,
    text3_3,
    text3_4,
    text4_1,
    text4_2,
    text4_3,
    text4_4,
  } = translate.general.footer.links;
  const { setShowReserve } = props;

  return (
    <Container fluid className=' ge-fo-links p-0'>
      <div className='row d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between'>
        <div className='col-12 col-sm-6 col-md-3 d-flex flex-column columnFooter align-items-center align-items-sm-start'>
          <h5 className='mb-3'>{title1}</h5>
          <div>
            <p className='m-0 aboutUs-FooterText link-center'>{text1_1}</p>
          </div>
          <div>
            <a href={to.facebook} target='blank'>
              <img src='/icon/footer-facebook-logo.svg' alt='' />
            </a>
            <a href={to.instagram} target='blank'>
              <img src='/icon/footer-instagram-logo.svg' alt='' />
            </a>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-3 d-flex flex-column columnFooter justify-content-between align-items-center align-items-sm-start'>
          <h5 className='mb-3'>{title2}</h5>
          <div className='d-flex flex-column justify-content-around h-100 link-center'>
            <div>
              <a target='blank' href={to.business}>
                {text2_1}
              </a>
            </div>
            <div>
              <a target='blank' href={to.agents}>
                {text2_2}
              </a>
            </div>
            <div>
              <a target='blank' href={to.access}>
                {text2_3}
              </a>
            </div>
            <div>
              <a className='tagAdisabled' target='blank' href={to.GDS}>
                {text2_4}
              </a>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-3 d-flex flex-column columnFooter justify-content-between align-items-center align-items-sm-start'>
          <h5 className='mb-3'>{title3}</h5>
          <div className='d-flex flex-column justify-content-around h-100 link-center'>
            <div>
              <Link to={to.faq}>{text3_1}</Link>
            </div>
            <div>
              <Link onClick={() => setShowReserve(true)}>{text3_2}</Link>
            </div>
            <div>
              <Link to={to.contact}>{text3_3}</Link>
            </div>
            <div>
              <a href={to.achados} target='blank'>
                {text3_4}
              </a>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-3 d-flex flex-column columnFooter justify-content-between align-items-center align-items-sm-start'>
          <h5 className='mb-3'>{title4}</h5>
          <div className='d-flex flex-column justify-content-around h-100 link-center'>
            <div>
              <Link className='tagAdisabled' to={to.conditions}>
                {text4_1}
              </Link>
            </div>
            <div>
              <Link className='tagAdisabled' to={to.cookies}>
                {text4_2}
              </Link>
            </div>
            <div>
              <Link className='tagAdisabled' to={to.privacyPolicy}>
                {text4_3}
              </Link>
            </div>
            <div>
              <Link className='tagAdisabled' to={to.cancellationPolicy}>
                {text4_4}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowReserve: (payload) => dispatch(setShowReserve(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Links);
