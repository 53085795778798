import { connect } from "react-redux";
import Summary from "../atoms/summary";
import Summary2 from "../atoms/summary2";
import Taxes from "../atoms/taxes";
import Equipment from "../atoms/equipment";
import Total from "../atoms/total";
import Confirm from "../atoms/confirm";

function Price({ taxes, countryCode, updateTaxes, stateSwitch, cotization }) {
    return (
        <div className="s2-prices">
            <Summary rate={taxes} />
            <Summary2 stateSwitch={stateSwitch} />
            <Taxes
                taxes={updateTaxes}
                stateSwitch={stateSwitch}
                cotization={cotization}
            />
            <Equipment stateSwitch={stateSwitch} />
            <Total stateSwitch={stateSwitch} />
            <Confirm countryCode={countryCode} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
        updateTaxes: state.step2.taxes,
        cotization: state.step1.cotization,
    };
};

export default connect(mapStateToProps)(Price);
