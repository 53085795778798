export default function Banner({ text1, text2, icon, img, style }) {
  return (
    <div className={`ho-ba ${style}`}>
      <div className="ho-con-img">
        <img src={img} alt="" width="100%" height="auto" />
        <div className="rotate"></div>
      </div>
      <div className="ho-division">
        <div className="ho-con-icon">
          <img src={icon} alt="" className="ho-ba-ico" />
        </div>
        <div className="mb-2 d-flex flex-column align-items-end">
          <p className="ho-text">{text1}</p>
          <span>{text2}</span>
        </div>
      </div>
    </div>
  );
}
