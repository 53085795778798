import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { standarPrice } from "../../../utils/standard";

function HeaderCreditCard(props) {
  const { data, price, type, last4 } = props;
  const { opcion2 } = translate.step5;

  return (
    <div className="s5-HeaderCreditCard-container">
      <div className="s5-HeaderCreditCard-img">
        <img src="/img/credit-payment.webp" alt="" />
      </div>
      <div className="s5-HeaderCreditCard-mobile">
        <h5>{opcion2.title1}</h5>
        <p>
          {opcion2.text1}
          <span>
            {price.CurrencyCode}{" "}
            {type === "gross"
              ? standarPrice(price.EstimatedTotalAmountPrepaid)
              : standarPrice(price.EstimatedTotalAmountPrepaidNetoCCTax)}{" "}
          </span>
          {opcion2.text2} {last4}.
        </p>
        <p>
          {opcion2.text3}: <span>{data.customer.email}</span>
        </p>
        <p>{opcion2.text5}</p>
        <div className="s5-HeaderCreditCard-card">
          <div>
            <img src="/icon/tag.svg" alt="" />
            <p>{opcion2.title2}</p>
          </div>
          <div className="s5-HeaderCreditCard-hr"></div>
          <div>
            <span>{data.id}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    type: state.step4.type,
    last4: state.step4.card,
  };
};

export default connect(mapStateToProps)(HeaderCreditCard);
