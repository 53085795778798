import React, { useEffect, useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import HeaderStep4 from "../components/atoms/headerStep4";
import TypePayment from "../components/atoms/typePayment";
import PaymentDetails from "../components/molecules/paymentDetails";
import Transfer from "../components/atoms/transfer";
import Invoiced from "../components/atoms/invoiced";
import CreditCard from "../components/organism/creditCard";
import StepProgress from "../components/molecules/stepProgress";
import translate from "../../translates/translations";
import { Container, Row } from "reactstrap";
import { to } from "../../utils/redirect";
import { useHistory } from "react-router-dom";
import { viewReserve, cancelReserve } from "../../services/api";
import Loader from "../components/atoms/loader";
import { handleError, handleWarning } from "../../utils/error";
import ModalCancel from "../components/atoms/modalCancel";
import { clear_home } from "../../redux/actions/home";
import { clearStep1, setPrepaid } from "../../redux/actions/step1";
import { clearStep2, typeClient } from "../../redux/actions/step2";
import {
  clearStep4,
  data,
  changeSaleStatus,
  agencyGrossNet,
} from "../../redux/actions/step4";
import { clear_filter } from "../../redux/actions/filter";

function Step4(props) {
  const {
    typePayment,
    reserve,
    dataReservation,
    company,
    data,
    typeClient,
    changeSaleStatus,
    clearApp,
    agencyGrossNet,
  } = props;
  const { typepayment } = translate.step4;
  const { error10, error16, error2, success4, error18 } =
    translate.general.error;
  const { customer1, customer2, customer3 } = translate.step2;
  const [showModalCancel, setShowModalCancel] = useState(false);
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const handleClose = () => setShowModalCancel(!showModalCancel);

  const body = dataReservation && {
    passenger_lastname: dataReservation.last_name,
    id_reservation: dataReservation.id,
    company: company == 2 ? "Budget" : "Avis",
    language: "pt",
  };
  window.onbeforeunload = function (e) {
    return "Texto de aviso";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    viewReserve(body)
      .then((response) => response.data)
      .then((res) => {
        data(res);
        setPrepaid(res.reservation.prepaid);
        changeSaleStatus(res.reservation.sale_status);
        if (res.reservation.organization) {
          if (res.reservation.organization.type === 1) {
            typeClient(customer3);
            agencyGrossNet("net");
          } else {
            typeClient(customer2);
          }
        } else {
          typeClient(customer1);
        }
      })
      .catch((error) => {
        history.push(to.error);
        console.log(error);
        handleError(error18, 1);
      });
  }, []);

  const exit = () => {
    history.push(to.home);
    clearApp();
  };

  const deleteReserve = () => {
    setLoader(true);
    cancelReserve(body)
      .then((response) => response.data)
      .then((res) => {
        if (res.reservation && res.reservation.message === "Cancelled") {
          if (
            changeSaleStatus === 4 ||
            changeSaleStatus === 5 ||
            changeSaleStatus === 1
          ) {
            handleWarning(error16);
          } else {
            handleError(error10, 2);
          }
          setShowModalCancel(false);
          exit();
        } else {
          setShowModalCancel(false);
          handleError(error2, 1);
        }
        setLoader(false);
      })
      .catch((error) => {
        handleError(error2, 1);
        console.log(error, "error");
        setShowModalCancel(false);
        setLoader(false);
      });
  };

  if (reserve) {
    return (
      <Container fluid className="m-0 p-0">
        <Row className="d-flex justify-content-center align-items-center w-100 m-0">
          <Container
            sm="12"
            md="10"
            lg="7"
            className="m-0 p-0 widthFixedDesktop"
          >
            <StepProgress valueOne={100} valueTwo={100} valueThree={100} />
            <HeaderStep4
              id={reserve.reservation.id}
              total={reserve.car.TotalCharge}
              handleClose={handleClose}
            />
            <TypePayment data={reserve.reservation} />
            {typePayment.value === typepayment.opcion1 && (
              <div>
                <PaymentDetails
                  car={reserve.car}
                  equipment={reserve.car.Equipment}
                />
                <Transfer />
              </div>
            )}
            {typePayment.value === typepayment.opcion2 && (
              <div>
                <PaymentDetails
                  car={reserve.car}
                  equipment={reserve.car.Equipment}
                  creditCard={true}
                />
                <CreditCard />
              </div>
            )}
            {typePayment.value === typepayment.opcion3 && (
              <div>
                <PaymentDetails
                  car={reserve.car}
                  equipment={reserve.car.Equipment}
                />
                <Invoiced car={reserve.car.TotalCharge} />
              </div>
            )}
          </Container>
          {showModalCancel && (
            <ModalCancel
              handleClose={handleClose}
              deleteReserve={deleteReserve}
              loader={loader}
              reservation={reserve.reservation}
            />
          )}
        </Row>
      </Container>
    );
  } else {
    return <Loader />;
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    typePayment: state.step4.typePayment,
    reserve: state.step4.data,
    dataReservation: state.step4.reservation,
    company: state.step1.companySelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    data: (payload) => dispatch(data(payload)),
    typeClient: (payload) => dispatch(typeClient(payload)),
    setPrepaid: (payload) => dispatch(setPrepaid(payload)),
    changeSaleStatus: (payload) => dispatch(changeSaleStatus(payload)),
    agencyGrossNet: (payload) => dispatch(agencyGrossNet(payload)),
    clearApp: () => {
      dispatch(clear_home());
      dispatch(clearStep1());
      dispatch(clearStep2());
      dispatch(clearStep4());
      dispatch(clear_filter());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
