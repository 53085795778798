import React, { useState, useEffect, useRef } from "react";
import { DropdownItems } from "../atoms/DropdownItems";
import { to } from "../../../utils/redirect";

export const DropDownAgency = (props) => {
  const { openDropdown, setOpenDropdown } = props;
  const dropdownRef = useRef(null);
  const [menuClasses, setMenuClasses] = useState("slide-in-top");
  const agency = { name: "Área Administrativa", url: to.access };
  const voucher = { name: "Emissão de vouchers", url: to.voucherSystem };

  function useOutsideHandler(refNode) {
    useEffect(() => {
      function handleClickOutside(event) {
        //handler
        if (refNode.current && !refNode.current.contains(event.target)) {
          setMenuClasses("slide-out-top");
          setTimeout(() => {
            setOpenDropdown();
          }, 300);
        }
      }

      // agrega el event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind el event listener
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [refNode]);
  }

  //llama a la funcion
  useOutsideHandler(dropdownRef);

  return (
    <div className={`dropdown ${menuClasses}`} ref={dropdownRef}>
      <div className="menu">
        <div className="separador">
          <DropdownItems object={agency} />
        </div>
        <div className="separador">
          <DropdownItems object={voucher} />
        </div>
      </div>
    </div>
  );
};
