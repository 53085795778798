import React, { useState } from "react";

export const useSwitch = () => {
    const [price, setPrice] = useState(true);
    const handleSwitch = (el, state) => {
        localStorage.setItem("price", state);
        setPrice(!price);
    };

    return {
        price,
        handleSwitch,
    };
};
