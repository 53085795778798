import { useState, useEffect } from "react";

export default function useScreenSize(initial) {
  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView, actualWith } = state;
  const initialResponsive = initial ? initial : 992;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < initialResponsive
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return {
    mobileView,
    actualWith,
  };
}
