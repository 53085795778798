import React, { useState, useEffect } from "react"; // eslint-disable-line
import Routes from "./routes";

function App() {
  useEffect(() => {
    if (window.location.pathname === "/parceiros") {
      sessionStorage.parceiros = true;
    } else {
      sessionStorage.parceiros = false;
    }
  }, []);

  return <Routes />;
}

export default App;
