import { connect } from "react-redux";
import { to } from "../../../utils/redirect";
import { useHistory } from "react-router-dom";
import translate from "../../../translates/translations";
import { standarPrice } from "../../../utils/standard";
import { Button } from "reactstrap";

function HeaderTransfer(props) {
  const { data, price, type } = props;
  const { opcion1 } = translate.step5;
  let history = useHistory();

  return (
    <div className="s5-HeaderTransfer-container">
      <div className="s5-HeaderTransfer-img">
        <img src="/img/transfer-payment.webp" alt="" />
      </div>
      <div className="s5-HeaderTransfer-text">
        {data.sale_status == 5 ? (
          <h5>{opcion1.title2}</h5>
        ) : (
          <h5>{opcion1.title}</h5>
        )}
        <p>
          {opcion1.text1}: <span>{data.customer.email}</span>
        </p>
        <p>
          {opcion1.text2}: <span>{opcion1.text6}</span>
        </p>
        <p>
          {opcion1.text3}{" "}
          <span>
            {" "}
            {price.CurrencyCode}{" "}
            {type === "gross"
              ? standarPrice(price.EstimatedTotalAmountPrepaid)
              : standarPrice(price.EstimatedTotalAmountPrepaidNeto)}
          </span>
        </p>

        <div className="s5-HeaderTransfer-card">
          <img src="/icon/alert-circle.svg" alt="" />
          <div>
            <div className="s5-HeaderTransfer-col1">
              <div>
                <p>{opcion1.item1}: </p>
                <span>{opcion1.res1}</span>
              </div>
              <div>
                <p>{opcion1.item2}: </p>
                <span>{opcion1.res2}</span>
              </div>
              <div>
                <p>{opcion1.item3}: </p>
                <span>{opcion1.res3}</span>
              </div>
              <div>
                <p>{opcion1.item4}: </p>
                <span>{opcion1.res4}</span>
              </div>
              <div>
                <p>{opcion1.item5}: </p>
                <span>{opcion1.res5}</span>
              </div>
            </div>
          </div>
        </div>
        {data.sale_status === 3 && (
          <Button color="primary" onClick={() => history.push(to.step4)}>
            {opcion1.button1}
          </Button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    type: state.step4.type,
  };
};

export default connect(mapStateToProps)(HeaderTransfer);
