export default function Code({ icon, text, code }) {
  return (
    <div className="s3-codes-div" key={text}>
      <div className="s3-codes-circle">
        <img src={icon} alt="icon" />
      </div>
      <div className="code-fix-mobile">
        <p>{text}</p>
        <h5>{code ? code : "-"}</h5>
      </div>
    </div>
  );
}
