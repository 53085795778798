import { combineReducers } from 'redux'
import { langReducer } from 'react-redux-multilang'

import home from './reducers/home'
import step1 from './reducers/step1'
import step2 from './reducers/step2'
import step4 from './reducers/step4'
import filter from './reducers/filter'

export default combineReducers({
    lang: langReducer, home, step1, step2, step4, filter,
});
