import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { standarPrice } from "../../../utils/standard";

function Taxes2(props) {
  const { car } = props;
  const { text1, text2, text3, title1, title2, title3, title4, title5 } =
    translate.step3.taxes;

  return (
    <div className="s3-taxes2-container">
      <div className="s3-taxes2-tarifa align-items-center">
        <h6>{title1}</h6>
        <hr />
        <div className="chargeCurrency-Container">
          <p>{title2}:</p>
          <span>
            {car.TotalCharge.CurrencyCode}{" "}
            {standarPrice(car.TotalCharge.RateTotalAmount)}
          </span>
        </div>
        <hr />
        <p>{text1}</p>
        <p>{text2}</p>
        <p>{text3}</p>
      </div>
      <div className="s3-taxes2-impostos">
        <div>
          <h6>{title3}</h6>
          {car.VehicleCharges.taxes &&
            car.VehicleCharges.taxes.map((tax, i) => (
              <div key={`${i}${tax}`}>
                <img src="/icon/chevron-right.svg" alt="" />
                <p>{tax.Translated}</p>
                <span>
                  {tax.CurrencyCode} {standarPrice(tax.Amount)}
                </span>
              </div>
            ))}
        </div>
        <div className="s3-taxes2-total">
          <hr />
          <div>
            <p>{title5}:</p>
            <span>
              {car.VehicleCharges.total_tax.CurrencyCode}{" "}
              {car.VehicleCharges.total_tax.Total_Tax
                ? standarPrice(car.VehicleCharges.total_tax.Total_Tax)
                : "0.00"}
            </span>
          </div>
        </div>
      </div>
      <div className="s3-taxes2-impostos mb-0">
        <div>
          <h6>{title4}</h6>
          {car.Equipment &&
            car.Equipment.map((equipment, i) => (
              <div key={`${i}${equipment}`}>
                <img src="/icon/chevron-right.svg" alt="" />
                <p>{equipment.name}</p>
                <span>
                  {equipment.CurrencyCode} {standarPrice(equipment.Amount)}
                </span>
              </div>
            ))}
        </div>
        <div className="s3-taxes2-total">
          <hr />
          <div>
            <p>{title5}:</p>
            <span>
              {car.VehicleCharges.total_tax.CurrencyCode}{" "}
              {car.VehicleCharges.total_tax.Total_Equipment
                ? standarPrice(car.VehicleCharges.total_tax.Total_Equipment)
                : "0.00"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
  };
};

export default connect(mapStateToProps)(Taxes2);
