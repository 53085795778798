export const conditions = {
    avis: {
        latinAmerica: {
            name: 'América Latina',
            Argentina: {
                name: 'Argentina',
                url: 'https://avis-int.com/conditions/AR?language=PT'
            },
            Belize: {
                name: 'Belize',
                url: 'https://avis-int.com/conditions/BZ?language=PT'
            },
            Chile: {
                name: 'Chile',
                url: 'https://avis-int.com/conditions/CL?language=PT'
            },
            Colombia: {
                name: 'Colômbia',
                url: 'https://avis-int.com/conditions/CO?language=PT'
            },
            CostaRica: {
                name: 'Costa Rica',
                url: 'https://avis-int.com/conditions/CR?language=PT'
            },
            ElSalvador: {
                name: 'El Salvador',
                url: 'https://avis-int.com/conditions/EL?language=PT'
            },

            Equador: {
                name: 'Equador',
                url: 'https://avis-int.com/conditions/EC?language=PT'
            },
            Guatemala: {
                name: 'Guatemala',
                url: 'https://avis-int.com/conditions/GT?language=PT'
            },
            Honduras: {
                name: 'Honduras',
                url: 'https://avis-int.com/conditions/HD?language=PT'
            },
            Mexico: {
                name: 'México',
                url: 'https://avis-int.com/conditions/MX?language=PT'
            },
            Nicaragua: {
                name: 'Nicarágua',
                url: 'https://avis-int.com/conditions/NI?language=PT'
            },
            Panama: {
                name: 'Panamá',
                url: 'https://avis-int.com/conditions/PA?language=PT'
            },
            Paraguai: {
                name: 'Paraguai',
                url: 'https://avis-int.com/conditions/PY?language=PT'
            },
            Peru: {
                name: 'Peru',
                url: 'https://avis-int.com/conditions/PE?language=PT'
            },
            Uruguai: {
                name: 'Uruguai',
                url: 'https://avis-int.com/conditions/UY?language=PT'
            },
        },
        caribbean: {
            name: 'Caribe',
            Anguila: {
                name: 'Anguila',
                url: 'https://avis-int.com/conditions/AI?language=PT'
            },
            Aruba: {
                name: 'Aruba',
                url: 'https://avis-int.com/conditions/AB?language=PT  '
            },
            Curazao: {
                name: 'Curaçao',
                url: 'https://avis-int.com/conditions/CW?language=PT'
            },
            IlhasCayman: {
                name: 'Ilhas Cayman',
                url: 'https://avis-int.com/conditions/CT?language=PT'
            },
            Jamaica: {
                name: 'Jamaica',
                url: 'https://avis-int.com/conditions/JM?language=PT'
            },
            RepDominicana: {
                name: 'R.Dominicana',
                url: 'https://avis-int.com/conditions/DO?language=PT'
            },

            StBarthelemy: {
                name: 'St. Barthelemy',
                url: 'https://avis-int.com/conditions/BL?language=PT'
            },
            StMaarten: {
                name: 'St. Maarten',
                url: 'https://avis-int.com/conditions/SX?language=PT'
            },
        },
        europe: {
            name: 'Europa',
            Alemania: {
                name: 'Alemanha',
                url: 'https://avis-int.com/conditions/DE?language=PT'
            },
            Austria: {
                name: 'Áustria',
                url: 'https://avis-int.com/conditions/AT?language=PT'
            },
            Belgica: {
                name: 'Bélgica',
                url: 'https://avis-int.com/conditions/BE?language=PT'
            },
            Croacia: {
                name: 'Croacia',
                url: 'https://avis-int.com/conditions/HR?language=PT'
            },
            Espanha: {
                name: 'Espanha',
                url: 'https://avis-int.com/conditions/ES?language=PT'
            },
            Francia: {
                name: 'França',
                url: 'https://avis-int.com/conditions/FR?language=PT'
            },
            Grecia: {
                name: 'Grécia',
                url: 'https://avis-int.com/conditions/GR?language=PT'
            },

            Holanda: {
                name: 'Países Baixos',
                url: 'https://avis-int.com/conditions/NL?language=PT'
            },
            Italia: {
                name: 'Itália',
                url: 'https://avis-int.com/conditions/IT?language=PT'
            },
            Polonia: {
                name: 'Polônia',
                url: 'https://avis-int.com/conditions/PL?language=PT'
            },
            Portugal: {
                name: 'Portugal',
                url: 'https://avis-int.com/conditions/PT?language=PT'
            },
            ReinoUnido: {
                name: 'Reino Unido',
                url: 'https://avis-int.com/conditions/GB?language=PT'
            },
            RepCheca: {
                name: 'República Tcheca ',
                url: 'https://avis-int.com/conditions/CZ?language=PT'
            },

            Suecia: {
                name: 'Suécia',
                url: 'https://avis-int.com/conditions/SE?language=PT'
            },
            Suiza: {
                name: 'Suíça',
                url: 'https://avis-int.com/conditions/CH?language=PT'
            },
        },
        Canada: {
            name: 'Canadá',
            url: 'https://avis-int.com/conditions/CA?language=PT'
        },
        EstadosUnidos: {
            name: 'Estados Unidos ',
            url: 'https://avis-int.com/conditions/US?language=PT'
        },
        Israel: {
            name: 'Israel',
            url: 'https://avis-int.com/conditions/IL?language=PT'
        }
    },


    budget: {
        latinAmerica: {
            name: 'América Latina',
            Argentina: {
                name: 'Argentina',
                url: 'https://budget-int.com/conditions/AR?language=PT'
            },
            Belize: {
                name: 'Belize',
                url: 'https://budget-int.com/conditions/BZ?language=PT'
            },
            Chile: {
                name: 'Chile',
                url: 'https://budget-int.com/conditions/CL?language=PT'
            },
            Colombia: {
                name: 'Colômbia',
                url: 'https://budget-int.com/conditions/CO?language=PT'
            },
            CostaRica: {
                name: 'Costa Rica',
                url: 'https://budget-int.com/conditions/CR?language=PT'
            },
            ElSalvador: {
                name: 'El Salvador',
                url: 'https://budget-int.com/conditions/EL?language=PT'
            },
            Equador: {
                name: 'Equador',
                url: 'https://budget-int.com/conditions/EC?language=PT'
            },
            Guatemala: {
                name: 'Guatemala',
                url: 'https://budget-int.com/conditions/GT?language=PT'
            },
            Honduras: {
                name: 'Honduras',
                url: 'https://budget-int.com/conditions/HD?language=PT'
            },
            Mexico: {
                name: 'México',
                url: 'https://budget-int.com/conditions/MX?language=PT'
            },
            Nicaragua: {
                name: 'Nicarágua',
                url: 'https://budget-int.com/conditions/NI?language=PT'
            },
            Panama: {
                name: 'Panamá',
                url: 'https://budget-int.com/conditions/PA?language=PT'
            },
            Peru: {
                name: 'Peru',
                url: 'https://budget-int.com/conditions/PE?language=PT'
            },
            Uruguai: {
                name: 'Uruguai',
                url: 'https://budget-int.com/conditions/UY?language=PT'
            },
        },
        caribbean: {
            name: 'Caribe',
            AntíguaEBarbuda: {
                name: 'Antígua e Barbuda',
                url: 'https://budget-int.com/conditions/AG?language=PT'
            },
            Aruba: {
                name: 'Aruba',
                url: 'https://budget-int.com/conditions/AB?language=PT'
            },
            IlhasCayman: {
                name: 'Ilhas Cayman',
                url: 'https://budget-int.com/conditions/CT?language=PT'
            },
            Jamaica: {
                name: 'Jamaica',
                url: 'https://budget-int.com/conditions/JM?language=PT'
            },
            RepDominicana: {
                name: 'R.Dominicana',
                url: 'https://budget-int.com/conditions/DO?language=PT'
            },
        },

        europe: {
            name: 'Europa',
            Alemania: {
                name: 'Alemanha',
                url: 'https://budget-int.com/conditions/DE?language=PT'
            },
            Austria: {
                name: 'Áustria',
                url: 'https://budget-int.com/conditions/AT?language=PT  '
            },
            Belgica: {
                name: 'Bélgica',
                url: 'https://budget-int.com/conditions/BE?language=PT'
            },
            Croacia: {
                name: 'Croacia',
                url: 'https://budget-int.com/conditions/HR?language=PT'
            },
            Espanha: {
                name: 'Espanha',
                url: 'https://budget-int.com/conditions/ES?language=PT'
            },
            Francia: {
                name: 'França',
                url: 'https://budget-int.com/conditions/FR?language=PT'
            },
            Grecia: {
                name: 'Grécia',
                url: 'https://budget-int.com/conditions/GR?language=PT'
            },
            Italia: {
                name: 'Itália',
                url: 'https://budget-int.com/conditions/IT?language=PT'
            },
            Portugal: {
                name: 'Portugal',
                url: 'https://budget-int.com/conditions/PT?language=PT'
            },
            ReinoUnido: {
                name: 'Reino Unido',
                url: 'https://budget-int.com/conditions/GB?language=PT'
            },
            Suiza: {
                name: 'Suíça',
                url: 'https://budget-int.com/conditions/CH?language=PT'
            },
        },
        Canada: {
            name: 'Canadá',
            url: 'https://budget-int.com/conditions/CA?language=PT'
        },
        EstadosUnidos: {
            name: 'Estados Unidos ',
            url: 'https://budget-int.com/conditions/US?language=PT'
        },
        Marrocos: {
            name: 'Marrocos',
            url: 'https://budget-int.com/conditions/MA?language=PT'
        }
    },
}