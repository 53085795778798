import { useState } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import NamePrice from "../atoms/namePrice";
import translate from "../../../translates/translations";
import { addEquipment } from "../../../redux/actions/step2";
import Select from "react-select";
import { FormGroup, Label, Input } from "reactstrap";

function EquiOptional({
    car,
    equipment,
    addEquipment,
    stateSwitch,
    cotization,
}) {
    const { title, text1, text2 } = translate.step2.equipment;

    const [elevatorio, setElevatorio] = useState(0);
    const [baby, setBaby] = useState(0);
    const [children, setChildren] = useState(0);

    const handleSelect = (value, item) => {
        if (item.id === "9") {
            setElevatorio(value);
        }
        if (item.id === "7") {
            setBaby(value);
        }
        if (item.id === "8") {
            setChildren(value);
        }
        const optionalEquipment = equipment.map((x) => {
            return x;
        });
        let newOptionalEquipment = [];
        if (value == 0) {
            newOptionalEquipment = optionalEquipment.filter(
                (x) => x.name !== item.name
            );
        } else {
            newOptionalEquipment = optionalEquipment.filter(
                (x) => x.name !== item.name
            );
            item.quantity = value;
            newOptionalEquipment.push(item);
        }
        addEquipment(newOptionalEquipment);
    };

    const handleOnChange = (e, item) => {
        const optionalEquipment = equipment.map((item) => {
            return item;
        });
        let newOptionalEquipment = [];
        if (!e.target.checked) {
            newOptionalEquipment = optionalEquipment.filter(
                (x) => x.name !== item.name
            );
        } else {
            newOptionalEquipment = optionalEquipment;
            newOptionalEquipment.push(item);
        }
        addEquipment(newOptionalEquipment);
    };

    return (
        <div className="s2-equipOptional">
            <div>
                <h6>{title}</h6>
            </div>
            <hr />
            <div className="con-phrase">
                <p>{text1}</p>
            </div>
            <div>
                {car &&
                    car.map((x, i) => {
                        if (x.id === "9") {
                            return (
                                <div
                                    className="d-flex w-100 align-items-center optionSelectContainer"
                                    key={i}
                                >
                                    <div className="s2-equipOptional-three align-items-center">
                                        <div className="d-flex w-100 align-items-center">
                                            <img
                                                src="/icon/plus-circle.svg"
                                                alt=""
                                            />
                                            <NamePrice
                                                text={x.name}
                                                price={x.Amount}
                                                currency={x.CurrencyCode}
                                                stateSwitch={stateSwitch}
                                                cotization={cotization}
                                            />
                                        </div>
                                    </div>
                                    <Select
                                        className="react-select react-select-info mt-2"
                                        onChange={(e) =>
                                            handleSelect(e.value, x)
                                        }
                                        classNamePrefix="react-select"
                                        placeholder="0"
                                        closeMenuOnSelect={true}
                                        isSearchable={false}
                                        name="9"
                                        options={[
                                            { value: 0, label: "0" },
                                            { value: 1, label: "1" },
                                            { value: 2, label: "2" },
                                            { value: 3, label: "3" },
                                        ]}
                                    ></Select>
                                    <p className="Quantidade">
                                        {text2}: <span>{elevatorio}</span>
                                    </p>
                                </div>
                            );
                        }
                        if (x.id === "7") {
                            return (
                                <div
                                    className="d-flex w-100 align-items-center optionSelectContainer"
                                    key={i}
                                >
                                    <div className="s2-equipOptional-three align-items-center">
                                        <div className="d-flex w-100 align-items-center">
                                            <img
                                                src="/icon/plus-circle.svg"
                                                alt=""
                                            />
                                            <NamePrice
                                                text={x.name}
                                                price={x.Amount}
                                                currency={x.CurrencyCode}
                                                stateSwitch={stateSwitch}
                                                cotization={cotization}
                                            />
                                        </div>
                                    </div>
                                    <Select
                                        className="react-select react-select-info mt-2"
                                        onChange={(e) =>
                                            handleSelect(e.value, x)
                                        }
                                        classNamePrefix="react-select"
                                        placeholder="0"
                                        closeMenuOnSelect={true}
                                        isSearchable={false}
                                        name="7"
                                        options={[
                                            { value: 0, label: "0" },
                                            { value: 1, label: "1" },
                                            { value: 2, label: "2" },
                                            { value: 3, label: "3" },
                                        ]}
                                    ></Select>
                                    <p className="Quantidade">
                                        {text2}: <span>{baby}</span>
                                    </p>
                                </div>
                            );
                        }
                        if (x.id === "8") {
                            return (
                                <div
                                    className="d-flex w-100 align-items-center optionSelectContainer"
                                    key={i}
                                >
                                    <div className="s2-equipOptional-three align-items-center">
                                        <div className="d-flex w-100 align-items-center align-items-center">
                                            <img
                                                src="/icon/plus-circle.svg"
                                                alt=""
                                            />
                                            <NamePrice
                                                text={x.name}
                                                price={x.Amount}
                                                currency={x.CurrencyCode}
                                                stateSwitch={stateSwitch}
                                                cotization={cotization}
                                            />
                                        </div>
                                    </div>
                                    <Select
                                        className="react-select react-select-info mt-2"
                                        onChange={(e) =>
                                            handleSelect(e.value, x)
                                        }
                                        classNamePrefix="react-select"
                                        placeholder="0"
                                        closeMenuOnSelect={true}
                                        isSearchable={false}
                                        name="8"
                                        options={[
                                            { value: 0, label: "0" },
                                            { value: 1, label: "1" },
                                            { value: 2, label: "2" },
                                            { value: 3, label: "3" },
                                        ]}
                                    ></Select>
                                    <p className="Quantidade">
                                        {text2}: <span>{children}</span>
                                    </p>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    className="s2-equipOptional-select optionSelectContainer align-items-center"
                                    key={i}
                                >
                                    <div className="d-flex w-100 align-items-center textWithoutCheckButton">
                                        <img
                                            src="/icon/plus-circle.svg"
                                            alt=""
                                        />
                                        <NamePrice
                                            text={x.name}
                                            price={x.Amount}
                                            currency={x.CurrencyCode}
                                            stateSwitch={stateSwitch}
                                            cotization={cotization}
                                        />
                                    </div>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                onClick={(e) =>
                                                    handleOnChange(e, x)
                                                }
                                            />
                                            <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                </div>
                            );
                        }
                    })}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
        equipment: state.step2.optionalEquipment,
        cotization: state.step1.cotization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addEquipment: (payload) => dispatch(addEquipment(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquiOptional);
