import { connect } from "react-redux";
import { Button } from "reactstrap";
import { to } from "../../utils/redirect";
import { useHistory } from "react-router-dom";
import { clear_home } from "../../redux/actions/home";
import { clearStep1 } from "../../redux/actions/step1";
import { clearStep2 } from "../../redux/actions/step2";
import { clearStep4 } from "../../redux/actions/step4";
import { clear_filter } from "../../redux/actions/filter";
import translate from "../../translates/translations";

function Error(props) {
  const history = useHistory();
  const { clearApp } = props;
  const { error1, error2, error3 } = translate.general.error;
  const allClean = () => {
    clearApp();
    history.push(to.home);
  };

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-column">
      <div>
        <h2 className="display-3">{error1}</h2>
      </div>
      <div>
        <h6>{error2}</h6>
      </div>
      <Button onClick={allClean} color="primary">
        {error3}
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearApp: () => {
      dispatch(clear_home());
      dispatch(clearStep1());
      dispatch(clearStep2());
      dispatch(clearStep4());
      dispatch(clear_filter());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
