import React, { useState, useEffect } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import Car from "../molecules/car";
import Rates from "../molecules/rates";
import translate from "../../../translates/translations";
import { Button } from "reactstrap";

function CardFleet(props) {
    const { car, logo, clase, company, ratesSelected, stateSwitch } = props;
    const { button1 } = translate.step1.card;

    return (
        <div className="carFleet">
            <div className="s1-card">
                <Car car={car} />
                <div className="s1-con-table">
                    <div className="s1-con-table-title">
                        <img src={logo} alt="" className={clase} />
                        <Button
                            type="submit"
                            form="rates"
                            color="primary"
                            disabled={ratesSelected ? false : true}
                        >
                            {button1}
                        </Button>
                    </div>
                    <Rates
                        car={car.Rates}
                        carType={car}
                        company={company}
                        stateSwitch={stateSwitch}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
        place_pickup: state.home.place_pickup,
        place_dropoff: state.home.place_dropoff,
        info_user: state.home.info_user,
        ratesSelected: state.step1.ratesSelected,
    };
};

export default connect(mapStateToProps)(CardFleet);
