import {
    CLEAR_STEP4,
    TYPE_PAYMENT,
    DATA,
    RESERVATION,
    AGENCY_GROSS_NET,
    LAST_NUMBER,
    SALE_STATUS
} from '../actions/step4';

const initialState = {
    typePayment: '',
    reservation: '',
    data: '',
    type: 'gross',
    card: '',
    saleStatus: '',
}
const step4 = (state = initialState, action) => {
    switch (action.type) {
        case TYPE_PAYMENT: {
            return {
            ...state,
            typePayment: action.payload
            }
        }
        case RESERVATION: {
            return {
            ...state,
            reservation: action.payload
            }
        }
        case DATA: {
            return {
            ...state,
            data: action.payload
            }
        }
        case AGENCY_GROSS_NET: {
            return {
            ...state,
            type: action.payload
            }
        }
        case LAST_NUMBER: {
            return {
            ...state,
            card: action.payload
            }
        }
        case SALE_STATUS: {
            return {
            ...state,
            saleStatus: action.payload
            }
        }
        case CLEAR_STEP4: {
            return {
                typePayment: '',
                reservation: '',
                data: '',
                type: 'gross',
                card: '',
                saleStatus: '',
            }
        }
        default: {
            return state
        }
    }
}

export default step4;