import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";

export const standarName = (name, lastname) => {
    if (lastname) {
        const standard1 = name
            .toLowerCase()
            .replace(/\b[a-z]/g, (string) => string.toUpperCase());
        const standard2 = lastname
            .toLowerCase()
            .replace(/\b[a-z]/g, (string) => string.toUpperCase());
        return standard1 + " " + standard2;
    } else {
        const standard = name
            .toLowerCase()
            .replace(/\b[a-z]/g, (string) => string.toUpperCase());
        return standard;
    }
};

export const standarPrice = (number) => {
    const price = parseFloat(number);
    const standard = new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
    }).format(price.toFixed(2));
    return standard;
};

export const standarPriceSwitch = (number, { cotization, dolar, real }) => {
    if (localStorage.getItem("price") === "false") {
        const price = parseFloat(number / cotization);
        const standard = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
        }).format(price.toFixed(2));
        return (
            <span>
                <label className="m-0 d-inline pr-1">{dolar}</label>
                {standard}
            </span>
        );
    } else {
        const price = parseFloat(number);
        const standard = new Intl.NumberFormat("pt-BR", {
            minimumFractionDigits: 2,
        }).format(price.toFixed(2));
        return (
            <span>
                <label className="m-0 d-inline pr-1">{real}</label>
                {standard}
            </span>
        );
    }
};

export const standarDateTime = (date, time, language) => {
    const fullDate = date + ":" + time;
    return moment(fullDate, "YYYY-MM-DD:HH:mm").locale(language);
};

export const standarDateTimeText = (date, time, language, fulldate) => {
    if (fulldate) {
        let days = moment(fulldate).format("YYYY-MM-DD");
        let time = moment(fulldate).format("HH:mm");
        let message = moment(days).locale(language).format("LL");

        return message + ", " + time + " hs.";
    } else {
        let message = moment(date).locale(language).format("LL");
        return message + ", " + time + " hs.";
    }
};

export const standarDays = (x, y) => {
    let pickup = moment(x).format("YYYY-MM-DD");
    let days = moment(y).diff(pickup, "days");
    return `${days} días`;
};
