export const CAR_SELECTED = "CAR_SELECTED";
export const RATES_SELECTED = "RATES_SELECTED";
export const COMPANY_SELECTED = "COMPANY_SELECTED";
export const PLACE_SELECTED = "PLACE_SELECTED";
export const SETPREPAID = "SETPREPAID";
export const CLEAR_STEP1 = "CLEAR_STEP1";
export const FILTER_COMPANY = "FILTER_COMPANY";
export const RELOAD = "RELOAD";
export const RENDER_FLEET = "RENDER_FLEET";
export const COTIZATION = "COTIZATION";

export const setCotization = (payload) => {
    return {
        type: COTIZATION,
        payload,
    };
};

export const carSelected = (payload) => {
    return {
        type: CAR_SELECTED,
        payload,
    };
};

export const ratesSelected = (payload) => {
    return {
        type: RATES_SELECTED,
        payload,
    };
};

export const companySelected = (payload) => {
    return {
        type: COMPANY_SELECTED,
        payload,
    };
};

export const placeSelected = (payload) => {
    return {
        type: PLACE_SELECTED,
        payload,
    };
};

export const setPrepaid = (payload) => {
    return {
        type: SETPREPAID,
        payload,
    };
};

export const filterCompany = (payload) => {
    return {
        type: FILTER_COMPANY,
        payload,
    };
};

export const reload = () => {
    return {
        type: RELOAD,
    };
};

export const renderFleet = (payload) => {
    return {
        type: RENDER_FLEET,
        payload,
    };
};

export const clearStep1 = () => {
    return {
        type: CLEAR_STEP1,
    };
};
