
const english = {
  text: 'english',
  general : {
    navbar: {
      home: 'Home',
      offices: 'Offices',
      searchReserve: 'Search reservation',
      signUp: 'Sign up',
      signIn: 'Sign In',
      contact: 'Contact Us'
    }
  }
}

export default english;