import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import translate from "../../../translates/translations";
import ButtonLoader from "./ButtonLoader";
import { useForm, Controller } from "react-hook-form";

function ModalCancel(props) {
  const { handleClose, sendEmail, loader } = props;
  const { title1, text1, text2 } = translate.modal.email;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Container fluid className="modal-cancel-container fade-in m-0 p-0">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100 p-0 m-0">
          <form
            key={1}
            onSubmit={handleSubmit(sendEmail)}
            id="form-contact"
            className="w-100 d-flex justify-content-center align-items-center"
          >
            <Container fluid className="modal-cancel m-0">
              <Row className="d-flex justify-content-between w-100">
                <Col xs={10} sm="10" className="w-100 p-0">
                  <span>{title1}</span>
                </Col>
                <Col
                  xs={2}
                  sm="2"
                  className="d-flex justify-content-end cursor p-0"
                >
                  <img
                    src="/icon/x_black.svg"
                    alt=""
                    onClick={() => handleClose()}
                  />
                </Col>
              </Row>
              <Row className="d-flex w-100 m-0 mt-2">
                <Col sm="12" className="p-0 mt-2">
                  <div className={errors.email && "help2"}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="ge-in-img">
                          <img
                            src={"/icon/mail.svg"}
                            alt=""
                            className="ge-in-ico"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Debe ser un email válido",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            placeholder={text1}
                            className="ge-in-pla"
                            name="email"
                            onChange={onChange}
                            value={value}
                            type="text"
                            autoComplete="off"
                          />
                        )}
                      />
                    </InputGroup>
                  </div>
                  {errors.email && <span>{errors.email.message}</span>}
                </Col>
              </Row>
              <Row className="w-100 p-0 mt-2 d-flex">
                <Col className="p-0 m-0">
                  <ButtonLoader
                    loader={loader}
                    text={text2}
                    form="form-contact"
                    submit="submit"
                  />
                </Col>
              </Row>
            </Container>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalCancel;
