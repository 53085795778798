import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import translate from "../../../translates/translations";
import { to } from "../../../utils/redirect";
import { data } from "../../../redux/actions/step4";
import { Button } from "reactstrap";

function HeaderStep3(props) {
  const { prepaid, reservation, data } = props;
  const { title1, title2, text1, text2, text3, text4, button1 } =
    translate.step3.header;
  let history = useHistory();

  const next = async () => {
    await data(reservation);
    await history.push(to.step4);
  };

  return (
    <div className="s3-headerStep3-container">
      <div className="s3-headerStep3-img">
        <img src="/img/confirmation-step3.webp" alt="" />
      </div>
      <div className="s3-headerStep3-div">
        {prepaid == 0 && (
          <div>
            <h5>{title1}</h5>
            <span>{text1}</span>
            <p>{text2}</p>
            <div className="s3-headerStep3-alert">
              <img src="/icon/alert-circle.svg" alt="" />
              <p>{text3}</p>
            </div>
          </div>
        )}
        {prepaid == 1 && (
          <div className="s3-headerStep3-pre">
            <h5 className="mb-0">{title1}</h5>
            <h5>{title2}</h5>
            <p>{text4}</p>
            <div>
              <Button color="primary" onClick={next}>
                {button1}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    prepaid: state.step1.prepaid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    data: (payload) => dispatch(data(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStep3);
