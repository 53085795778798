import { useState } from "react"; // eslint-disable-line
import { Link } from "react-router-dom";
import translate from "../../../translates/translations";
import { connect } from "react-redux";
import { to } from "../../../utils/redirect";
import { clear_home, setShowReserve } from "../../../redux/actions/home";
import { clearStep1 } from "../../../redux/actions/step1";
import { clearStep2 } from "../../../redux/actions/step2";
import { clearStep4 } from "../../../redux/actions/step4";
import { clear_filter } from "../../../redux/actions/filter";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
} from "reactstrap";
import { DropDownAvisBudged } from "./DropdownMenu";
import { DropDownAgency } from "../atoms/DropDownAgency";
import useScreenSize from "../../../utils/useScreenSize";
import NavbarMobile from "./navbarMobile";

function NavBar(props) {
  const { home, offices, searchReserve, signUp, signIn, contact } =
    translate.general.navbar;
  const [isOpen, setOpen] = useState(false);
  const { clearApp, setShowReserve } = props;
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropAgency, setOpenDropAgency] = useState(false);
  const { mobileView } = useScreenSize();
  const allClean = () => clearApp();

  const reserve = () => {
    setShowReserve(true);
  };

  const displayMobile = () => {
    return (
      <NavbarMobile
        home={home}
        offices={offices}
        searchReserve={[searchReserve, reserve]}
        signUp={signUp}
        signIn={signIn}
        contact={contact}
      />
    );
  };

  const displayDropDowns = (drop) => {
    if (drop) {
      setOpenDropdown(false);
      setOpenDropAgency(true);
    } else {
      setOpenDropAgency(false);
      setOpenDropdown(true);
    }
  };

  const displayDesktop = () => {
    return (
      <Container fluid className="m-0 p-0 ge-na">
        <Row className="d-flex justify-content-center align-items-center w-100 headerRow">
          <Container
            sm="12"
            md="10"
            lg="7"
            className="m-0 p-0 widthFixedDesktop"
          >
            {/* <div className="ge-na"> */}
            <Navbar light expand="xs" className="m-0 p-0">
              <NavbarBrand className="navBarLogo">
                <Link to={to.home} onClick={allClean}>
                  {" "}
                  <img
                    src={"/img/avisbudget_logo.svg"}
                    alt="Avis Budget"
                    className="ge-img"
                  />
                </Link>
              </NavbarBrand>
              {/* <NavbarToggler onClick={toggle} /> */}
              <Collapse isOpen={true} navbar className="navBarItems-Container">
                <Nav className="d-flex navbar-nav" navbar>
                  <NavItem>
                    <NavLink>
                      <Link
                        to={to.home}
                        onClick={allClean}
                        className="nav-link"
                      >
                        {home}
                      </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <Link to={to.offices} className="nav-link">
                        {offices}
                      </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <Link onClick={reserve} className="nav-link">
                        {searchReserve}
                      </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="dropDownMenuContainer">
                      <Link
                        className="nav-link"
                        onClick={() => displayDropDowns(false)}
                      >
                        {signUp}
                      </Link>
                      {openDropdown ? (
                        <DropDownAvisBudged
                          openDropdown={openDropdown}
                          setOpenDropdown={setOpenDropdown}
                        />
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="dropDownMenuContainer">
                      <Link
                        className="nav-link"
                        onClick={() => displayDropDowns(true)}
                      >
                        {signIn}
                      </Link>
                      {openDropAgency ? (
                        <DropDownAgency
                          openDropdown={openDropAgency}
                          setOpenDropdown={setOpenDropAgency}
                        />
                      ) : (
                        ""
                      )}
                      {/* <a className="nav-link" href={to.access} target="blank">{signIn}</a> */}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <Link to={to.contact} className="nav-link">
                        {contact}
                      </Link>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Container>
        </Row>
      </Container>
    );
  };

  return <>{mobileView ? displayMobile() : displayDesktop()}</>;
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowReserve: (payload) => dispatch(setShowReserve(payload)),
    clearApp: () => {
      dispatch(clear_home());
      dispatch(clearStep1());
      dispatch(clearStep2());
      dispatch(clearStep4());
      dispatch(clear_filter());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
