import React from "react";
import Switch from "react-bootstrap-switch";

export const SwitchPrice = ({ text, onclick, state }) => {
    //
    const { currency1, currency2, item1, item2 } = text;
    let price = state === "false" ? false : true;

    return (
        <div className="switch-price__container">
            <p>
                <span>{currency1} - </span>
                {item1}
            </p>
            <Switch
                offText=""
                onColor=""
                onText=""
                onChange={(el, state) => onclick(el, state)}
                defaultValue={price}
            />
            <p>
                <span>{currency2} - </span>
                {item2}
            </p>
        </div>
    );
};
