import { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Container,
  Row,
} from "reactstrap";

const down = (
  <svg
    className="downIcon"
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="css-i6dzq1"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);
const up = (
  <svg
    className="upIcon SecondaryColor"
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="css-i6dzq1"
  >
    <polyline points="18 15 12 9 6 15"></polyline>
  </svg>
);

export const Accordion = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("Closed");

  const onEntering = () => setStatus("Opening");

  const onEntered = () => setStatus("Opened");

  const onExiting = () => setStatus("Closing");

  const onExited = () => setStatus("Closed");

  const toggle = () => setCollapse(!collapse);

  const { title, response } = props.text;

  return (
    <>
      <Card className='faqContainer m-0 p-0"'>
        <Row
          className={`d-flex justify-content-between align-items-center faqTitle-container ${
            collapse ? "bottomBorder" : ""
          }`}
          onClick={toggle}
        >
          <CardTitle
            color="primary"
            tag="h6"
            className={`p-0 m-0 ${
              status === "Opened" || status === "Opening"
                ? "SecondaryColor"
                : ""
            }`}
          >
            {title}
          </CardTitle>
          {collapse ? up : down}
        </Row>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onEntered={onEntered}
          onExiting={onExiting}
          onExited={onExited}
        >
          <Container
            className={`faqBody-container p-0 m-0 ${
              status === "Opening" ? "slide-out-topfaq" : ""
            }`}
          >
            <CardBody className="faqBody-text">{response}</CardBody>
          </Container>
        </Collapse>
      </Card>
    </>
  );
};
