import React, { useEffect, useState } from "react"; // eslint-disable-line
import { Col, Container, Row, Input } from "reactstrap";
import translate from "../../translates/translations";
import { useForm, Controller } from "react-hook-form";
import Map from "../components/molecules/googleMaps";
import { handleError } from "../../utils/error";
import { sendContact } from "../../services/api";
import ButtonLoader from "../components/atoms/ButtonLoader";

const Contact = () => {
  const {
    title1,
    title2,
    title3,
    title4,
    title5,
    text1,
    text2,
    text3,
    text4,
    text5,
    button1,
  } = translate.contact;
  const { error2 } = translate.general.error;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const formContact = (data) => {
    setLoader(true);
    reset();
    const body = {
      email: data.Email,
      full_name: data.Nome,
      subject: data.Assunto,
      text_message: data.Mensagem,
    };
    sendContact(body)
      .then((res) => res.data)
      .then((response) => {
        handleError(text5, 2);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error-contact");
        handleError(error2, 1);
        setLoader(false);
      });
  };

  return (
    <>
      <Container fluid className="m-0 p-0">
        <Row className="d-flex justify-content-center w-100 m-0 p-0">
          <Row className="d-flex justify-content-center w-100 m-0 p-0 heroImageContainer overflow-hidden">
            <img
              src="/img/heroImages/hero1.webp"
              alt="city panoramic"
              width="100%"
              height="auto"
            />
          </Row>
          <Container
            sm="12"
            md="7"
            lg="7"
            className="offices-container__col m-0 p-0 widthFixedDesktopOffices"
          >
            <Row className="m-0 p-0 ">
              <Col className="contant-map m-0 p-0 ">
                <Map
                  lat={-23.553142404592183}
                  lon={-46.65230565651489}
                  zoom={12}
                />
              </Col>
              <Col className="contact-container d-flex flex-column m-0 p-0 ">
                <p className="mb-0">{title1}</p>
                <h5 className="mb-3">{title2}</h5>
                <p className="mb-1">{title3}</p>
                <p className="mb-1">{title4}</p>
                <p className="mb-4">{title5}</p>
                <form
                  key={1}
                  onSubmit={handleSubmit(formContact)}
                  id="form-contact"
                >
                  <div className="form-contact">
                    <Controller
                      name="Nome"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          type="text"
                          placeholder={text1}
                          onChange={onChange}
                          value={value}
                          className={errors.Nome && "help"}
                        />
                      )}
                    />
                    <Controller
                      name="Email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Debe ser un email valido",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          type="text"
                          placeholder={text2}
                          onChange={onChange}
                          value={value}
                          className={errors.Email && "help"}
                        />
                      )}
                    />
                    <Controller
                      name="Assunto"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          type="text"
                          placeholder={text3}
                          onChange={onChange}
                          value={value}
                          className={errors.Assunto && "help"}
                        />
                      )}
                    />
                    <Controller
                      name="Mensagem"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          placeholder={text4}
                          onChange={onChange}
                          value={value}
                          rows="4"
                          type="textarea"
                          className={errors.Mensagem && "help"}
                        />
                      )}
                    />
                    <ButtonLoader
                      loader={loader}
                      text={button1}
                      form="form-contact"
                      submit="submit"
                    />
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
