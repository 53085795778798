import Banner from "../atoms/banner";
import translate from "../../../translates/translations";
import Carousel from "react-elastic-carousel";

function Banners() {
  const {
    title1,
    title2,
    text1_1,
    text1_2,
    text2_1,
    text2_2,
    text3_1,
    text3_2,
  } = translate.home.banner;

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemPadding: [0, 30] },
    {
      width: 550,
      itemsToShow: 2,
      itemsToScroll: 2,
      pagination: false,
      itemPadding: [0, 10],
    },
    { width: 850, itemsToShow: 3, itemPadding: [0, 0] },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  const banners = [
    {
      text1: text1_1,
      text2: text1_2,
      icon: "/icon/check-circle.svg",
      img: "/img/image_banner_1.webp",
      style: "banner-pr-10",
    },
    {
      text1: text2_1,
      text2: text2_2,
      icon: "/icon/credit-card.svg",
      img: "/img/image_banner_2.webp",
      style: "banner-px-10",
    },
    {
      text1: text3_1,
      text2: text3_2,
      icon: "/icon/tag.svg",
      img: "/img/image_banner_3.webp",
      style: "banner-pl-10",
    },
  ];

  return (
    <div className="ho-banners">
      <div className="ho-ba-con">
        <h6>
          {title1}
          <span>{title2}</span>
        </h6>
        <div className="ho-promo">
          <Carousel
            breakPoints={breakPoints}
            showArrows={false}
            pagination={false}
            enableAutoPlay={true}
            autoPlaySpeed={4000}
          >
            {banners.map((x, i) => (
              <Banner
                text1={x.text1}
                text2={x.text2}
                icon={x.icon}
                img={x.img}
                key={`${i}${x}`}
                style={x.style}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Banners;
