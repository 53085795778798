import axios from "axios";

export const isProduction = process.env.REACT_APP_ENV === "PRODUCTION";

const host = `${
  isProduction
    ? process.env.REACT_APP_API_PRODUCTION
    : process.env.REACT_APP_API_TEST
}/api/`;

// const headers = {
//     'Access-Control-Allow-Origin': true,
//     'Content-Type': 'application/json',
// }

//Requests

export const findLocation = (body, language) =>
  axios.post(`${host}locations`, { ...body, language });

export const findFleet = (body) => axios.post(`${host}find_fleet`, body);

export const findCar = (body) => axios.post(`${host}find_car`, body);

export const sumEquipment = (body) => axios.post(`${host}sum_equipment`, body);

export const createReserve = (body) => axios.post(`${host}create`, body);

export const viewReserve = (body) => axios.post(`${host}view`, body);

export const cancelReserve = (body) => axios.post(`${host}cancel`, body);

export const validateOrganization = (body) =>
  axios.post(`${host}validate_organization`, body);

export const rewardValidate = (body) =>
  axios.post(`${host}reward_validate`, body);

export const saleStatus = (body) =>
  axios.post(`${host}change_sale_status`, body);

export const pay = (body) => axios.post(`${host}pay`, body);

export const installments = (body) => axios.post(`${host}installments`, body);

export const partners = (body) => axios.post(`${host}partners`, body);

export const newsletter = (body) => axios.post(`${host}newsletter`, body);

export const resendEmail = (body) => axios.post(`${host}resend_email`, body);

export const sendContact = (body) => axios.post(`${host}contact`, body);
