import Item from "./Item";

export default function InfoRate({ car }) {
  return (
    <div className="s3-infoRates-container">
      <div className="s2-summary-icon">
        <p>
          {car.RateQualifier} - {car.RateData.name}
        </p>
      </div>
      <div className="s2-summary-charac">
        {car.RateData.inclusions &&
          car.RateData.inclusions.map((inclusion) => (
            <Item icon={"/icon/check.svg"} text={inclusion} />
          ))}
      </div>
    </div>
  );
}
