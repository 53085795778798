import { useState } from "react"; // eslint-disable-line
import translate from "../../../translates/translations";
import { connect } from "react-redux";
import { setShowReserve } from "../../../redux/actions/home";
import { companySelected } from "../../../redux/actions/step1";
import {
  reservation,
  typePayment,
  data,
  agencyGrossNet,
  lastNumber,
} from "../../../redux/actions/step4";
import { Container, Row, Col, Input } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { viewReserve } from "../../../services/api";
import { useHistory } from "react-router-dom";
import { to } from "../../../utils/redirect";
import { handleError } from "../../../utils/error";
import ButtonLoader from "../atoms/ButtonLoader";

function SearchReserve(props) {
  const {
    showReserve,
    setShowReserve,
    reservation,
    typePayment,
    data,
    companySelected,
    agencyGrossNet,
    lastNumber,
  } = props;
  const { opcion1, opcion2, opcion3 } = translate.step4.typepayment;
  const { title1, title2, title3, title4 } = translate.modal.search;
  const { error14, error15, error2, error12 } = translate.general.error;
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const objTypePayment = {
    1: opcion2,
    2: opcion3,
    3: opcion1,
    4: opcion3,
    5: opcion1,
  };

  const onSubmit = (info) => {
    setLoader(true);
    const body = {
      passenger_lastname: info.name,
      id_reservation: info.id,
      language: "pt",
    };
    const reserve = {
      id: info.id,
      last_name: info.name,
    };
    viewReserve(body)
      .then((response) => response.data)
      .then((res) => {
        reservation(reserve);
        const company = res.reservation.company === "Budget" ? 2 : 1;
        companySelected(company);
        setShowReserve(false);

        if (res.reservation.sale_status === 0) {
          const step = res.reservation.prepaid == 1 ? "step4" : "step3";
          history.push(to[step]);
          reset();
          setLoader(false);
        } else {
          res.reservation.card_data &&
            lastNumber(res.reservation.card_data.last4 || "XXXX");
          typePayment({ value: objTypePayment[res.reservation.sale_status] });
          agencyGrossNet(res.reservation.sale_type);
          data(res);
          history.push(to.step5);
          reset();
          setLoader(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          handleError(error14, 1);
        }
        if (error.response.status === 422) {
          handleError(error15, 1);
        }
        if (error.response.status === 500) {
          handleError(error2, 1);
        }
        setLoader(false);
      });
  };

  if (showReserve) {
    return (
      <Container fluid className="search-reserve-container fade-in">
        <Row>
          <Col sm="8" md="5" lg="4">
            <form onSubmit={handleSubmit(onSubmit)} id="form-search">
              <Container fluid className="form-search-reserve">
                <Row className="mt-3">
                  <Col xs="10">
                    <span>{title1}</span>
                  </Col>
                  <Col xs="2" className="d-flex justify-content-end">
                    <img
                      src="/icon/x_black.svg"
                      alt=""
                      onClick={() => setShowReserve(false)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          placeholder={title2}
                          onChange={onChange}
                          name="name"
                          value={value}
                          className={errors.name ? "help" : ""}
                          autoComplete="off"
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Controller
                      name="id"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          placeholder={title3}
                          onChange={onChange}
                          name="id"
                          value={value}
                          className={errors.id ? "help" : ""}
                          autoComplete="off"
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col sm="5">
                    <ButtonLoader
                      loader={loader}
                      text={title4}
                      form="form-search"
                      submit="submit"
                    />
                  </Col>
                </Row>
              </Container>
            </form>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    showReserve: state.home.showReserve,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowReserve: (payload) => dispatch(setShowReserve(payload)),
    reservation: (payload) => dispatch(reservation(payload)),
    typePayment: (payload) => dispatch(typePayment(payload)),
    data: (payload) => dispatch(data(payload)),
    companySelected: (payload) => dispatch(companySelected(payload)),
    agencyGrossNet: (payload) => dispatch(agencyGrossNet(payload)),
    lastNumber: (payload) => dispatch(lastNumber(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchReserve);
