import { standarPrice } from "../../../utils/standard";

function RightNamePrice(props) {
  const { title, price, currency, key } = props;

  return (
    <div className="ge-RightNamePrice" key={key}>
      <img src="/icon/chevron-right.svg" alt="" />
      <p>{title}</p>
      <span>
        {currency} {price ? standarPrice(price) : "0.00"}
      </span>
    </div>
  );
}

export default RightNamePrice;
