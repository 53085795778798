import { connect } from "react-redux";
import DropdownFlame from "./dropdownFlame";
import { typePayment } from "../../../redux/actions/step4";
import translate from "../../../translates/translations";

function TypePayment(props) {
  const { typePayment, payment, data } = props;
  const { title1, title2, opcion1, opcion2, opcion3 } =
    translate.step4.typepayment;

  const infoDrown = {
    title: title2,
    items: [
      data.payment_method.wire_transfer && opcion1,
      data.payment_method.credit_card && opcion2,
      data.payment_method.invoiced_payment && opcion3,
    ].filter((x) => x !== false),
  };

  const setPayment = (payload) => {
    typePayment(payload);
  };

  return (
    <div className="s4-typePayment-container">
      <h6>{title1}</h6>
      <hr />
      <div className="s4-typePayment-div">
        <div className="s4-typePayment-drown">
          <DropdownFlame info={infoDrown} handle={setPayment} state={payment} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    info_user: state.home.info_user,
    payment: state.step4.typePayment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    typePayment: (payload) => dispatch(typePayment(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TypePayment);
