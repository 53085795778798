import { connect } from "react-redux";
import { standarPrice, standarPriceSwitch } from "../../../utils/standard";

function NamePrice(props) {
    const { text, price, currency, key, cotization } = props;
    // TODO si es Additional Day Charge no se debe mostar
    if (text === "Additional Day Charge") {
        return null;
    }

    if (
        document.location.pathname === "/step1" ||
        document.location.pathname === "/step2"
    ) {
        return (
            <div className="s2-name-price" key={key}>
                <p className="s2-name col-8 p-0 m-0">{text}:</p>
                <p className="s2-price col-4 p-0 m-0">
                    <span>{standarPriceSwitch(price, cotization)}</span>
                </p>
            </div>
        );
    }

    return (
        <div className="s2-name-price" key={key}>
            <p className="s2-name col-8 p-0 m-0">{text}:</p>
            <p className="s2-price col-4 p-0 m-0">
                <span>
                    {currency} {standarPrice(price)}
                </span>
            </p>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.code,
    };
};

export default connect(mapStateToProps)(NamePrice);
