export default function Agency({ info }) {
  const guion = "-";

  return (
    <div className="s3-agency-container">
      <h6>{info.title}</h6>
      <hr />
      <div className="s3-agency-div">
        <div>
          <img src="/icon/chevron-right.svg" alt="icon" />
          <p>{info.item1}:</p>
          <span>{info.result1 || guion}</span>
        </div>
        <div>
          <img src="/icon/chevron-right.svg" alt="icon" />
          <p>{info.item2}:</p>
          <span>{info.result2 || guion}</span>
        </div>
        <div>
          <img src="/icon/chevron-right.svg" alt="icon" />
          <p>{info.item3}:</p>
          <span>{info.result3 || guion}</span>
        </div>
        <div>
          <img src="/icon/chevron-right.svg" alt="icon" />
          <p>{info.item4}:</p>
          <span>{info.result4 || guion}</span>
        </div>
      </div>
    </div>
  );
}
