import { Container, Row, Col } from "reactstrap";
import translate from "../../../translates/translations";

function ModalPre(props) {
  const { handleClose } = props;
  const {
    title1,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9,
    text10,
    text11,
    link,
  } = translate.modal.pre;

  return (
    <Container fluid className="modalpre-container fade-in">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100">
          <Container fluid className="modalpre">
            <Row className="d-flex justify-content-between w-100 modalpre-row1">
              <Col sm="10" className="w-100 p-0">
                <span>{title1}</span>
              </Col>
              <Col sm="2" className="d-flex justify-content-end cursor p-0">
                <img
                  src="/icon/x_black.svg"
                  alt=""
                  onClick={() => handleClose()}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="modalpre-img">
                <img
                  src="/img/imagen-modal.webp"
                  alt="friends"
                  width="100%"
                  height="auto"
                />
              </Col>
            </Row>
            <Row className="w-100 modalpre-row2">
              <Col sm="12" className="modalpre-text p-0">
                <div>
                  <img
                    src="/icon/check-green.svg"
                    alt="check"
                    width="100%"
                    height="auto"
                  />
                  <p>
                    {text1} <span className="modalpre-text-link">{text2}</span>{" "}
                    {text3}.
                  </p>
                </div>
                <div>
                  <img
                    src="/icon/check-green.svg"
                    alt="check"
                    width="100%"
                    height="auto"
                  />
                  <p>
                    {text4} <span className="modalpre-text-link">{text5}</span>{" "}
                    {text6}.
                  </p>
                </div>
                <div>
                  <img
                    src="/icon/check-green.svg"
                    alt="check"
                    width="100%"
                    height="auto"
                  />
                  <p>
                    {text7} <span className="modalpre-text-link">{text8}</span>{" "}
                    {text9}.
                  </p>
                </div>
                <div>
                  <img
                    src="/icon/check-green.svg"
                    alt="check"
                    width="100%"
                    height="auto"
                  />
                  <p>
                    {text10}{" "}
                    <a
                      href={link}
                      className="modalpre-text-link"
                      target="blank"
                    >
                      {text11}
                    </a>
                    .
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalPre;
