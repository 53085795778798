export const TYPE_CLIENT = 'TYPE_CLIENT';
export const PASSENGER = 'PASSENGER';
export const AGENCY = 'AGENCY';
export const CORPORATION = 'CORPORATION';
export const VALIDATION = 'VALIDATION';
export const WIZARD = 'WIZARD';
export const CUPOM = 'CUPOM';
export const REGARDS_NUMBER = 'REGARDS_NUMBER';
export const DISCOUNT_NUMBER = 'DISCOUNT_NUMBER';
export const ADD_EQUIPMENT = 'ADD_EQUIPMENT';
export const TOTAL_TAX = 'TOTAL_TAX';
export const TAXES = 'TAXES';
export const TOTAL_CHARGE = 'TOTAL_CHARGE';
export const CLEAR_STEP2 = 'CLEAR_STEP2';
export const USER_ID = 'USER_ID';
export const LOADER_STEP2 = 'LOADER_STEP2';
export const SET_PARTNER = 'SET_PARTNER';

export const typeClient = (payload) => {
    return {
        type: TYPE_CLIENT,
        payload
    }
}

export const setPartner = (payload) => {
    return {
        type: SET_PARTNER,
        payload
    }
}

export const passenger = (payload) => {
    return {
        type: PASSENGER,
        payload
    }
}

export const agency = (payload) => {
    return {
        type: AGENCY,
        payload
    }
}

export const corporation = (payload) => {
    return {
        type: CORPORATION,
        payload
    }
}

export const validation = () => {
    return {
        type: VALIDATION      
    }
}

export const wizard = (payload) => {
    return {
        type: WIZARD,
        payload      
    }
}

export const cupom = (payload) => {
    return {
        type: CUPOM,
        payload
    }
}

export const regardsNumber = (payload) => {
    return {
        type: REGARDS_NUMBER,
        payload      
    }
}

export const discountNumber = (payload) => {
    return {
        type: DISCOUNT_NUMBER,
        payload     
    }
}

export const addEquipment = (payload) => {
    return {
        type: ADD_EQUIPMENT,
        payload     
    }
}
export const totalCharge = (payload) => {
    return {
        type: TOTAL_CHARGE,
        payload     
    }
}

export const totalTax = (payload) => {
    return {
        type: TOTAL_TAX,
        payload     
    }
}

export const taxes = (payload) => {
    return {
        type: TAXES,
        payload     
    }
}

export const userId = (payload) => {
    return {
        type: USER_ID,
        payload     
    }
}

export const loaderStep2 = (payload) => {
    return {
        type: LOADER_STEP2,
        payload     
    }
}

export const clearStep2 = () => {
    return {
        type: CLEAR_STEP2,
    }
}
