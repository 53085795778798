import { Container, Row, Col } from "reactstrap";
import Map from "../molecules/googleMaps";
import translate from "../../../translates/translations";

export default function ModalLoja(props) {
  const { handleClose, place } = props;
  const { title1 } = translate.modal.loja;
  const { days, phone } = translate.general;

  return (
    <Container fluid className="modalloja-container fade-in">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100">
          <Container fluid className="modalpre">
            <Row className="d-flex justify-content-between w-100 modalpre-row1">
              <Col xs={10} sm="10" className="w-100 p-0">
                <span>{title1}</span>
              </Col>
              <Col
                xs={2}
                sm="2"
                className="d-flex justify-content-end cursor p-0"
              >
                <img
                  src="/icon/x_black.svg"
                  alt=""
                  onClick={() => handleClose()}
                />
              </Col>
            </Row>
            <Row className="w-100">
              <Col sm="12" className="modalloja-img p-0">
                <Map
                  lat={parseFloat(place.Latitude)}
                  lon={parseFloat(place.Longitude)}
                  zoom={13}
                />
              </Col>
            </Row>
            <Row className="w-100 modalloja-row2">
              <Col sm="12" className="p-0">
                <div className="d-flex flex-column modal-data-loja">
                  <p className="shedule-bold">
                    {place.Name} ({place.Code})
                  </p>
                  <p>
                    {place.Address.StreetNmbr} - {place.Address.PostalCode}
                  </p>
                  <p>
                    {phone}: {place.Telephone}
                  </p>
                </div>
                <div>
                  <div className="shedule-text">
                    {Object.keys(place.Schedule).map((day) => (
                      <div>
                        <p className="point m-0 p-0">•</p>
                        <p>
                          <span className="shedule-bold">{days[day]}:</span>
                          {`${place.Schedule[day].start} - ${place.Schedule[day].end}`}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
