import React, { useEffect } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import HeaderCreditCard from "../components/atoms/headerCreditCard";
import HeaderTransfer from "../components/atoms/headerTransfer";
import HeaderInvoiced from "../components/atoms/headerInvoiced";
import Confirmation from "../components/molecules/confirmation";
import Taxes2 from "../components/atoms/taxes2";
import CarSelected from "../components/molecules/carSelected";
import Loja from "../components/atoms/loja";
import Agency from "../components/atoms/agency";
import Codes from "../components/atoms/codes";
import InfoRate from "../components/atoms/infoRate";
import StepProgress from "../components/molecules/stepProgress";
import translate from "../../translates/translations";
import { Container, Row } from "reactstrap";
import { to } from "../../utils/redirect";
import { useHistory } from "react-router-dom";

function Step5(props) {
  const { typePayment, data, dataReservation, company } = props;
  const { card, location, info } = translate.step3;
  const { opcion1, opcion2, opcion3 } = translate.step4.typepayment;
  const history = useHistory();

  window.onbeforeunload = function (e) {
    return "Texto de aviso";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const body = {
    passenger_lastname: dataReservation.last_name,
    id_reservation: dataReservation.id,
    company: company == 2 ? "Budget" : "Avis",
    language: "pt",
  };

  let agency = {
    title: info.title1,
    item1: info.text1,
    item2: info.text2,
    item3: info.text3,
    item4: info.text4,
  };
  let voo = {
    title: info.title2,
    item1: info.text5,
    item2: info.text6,
    item3: info.text7,
    item4: info.text8,
  };

  if (data) {
    if (data.reservation?.organization) {
      agency = {
        ...agency,
        result1: data.reservation.organization.name,
        result2: data.reservation.organization.email,
        result3: data.reservation.organization.rewards,
        result4: data.reservation.organization.name_user,
      };

      if (data.reservation?.organization?.type === 2) {
        agency = {
          ...agency,
          title: info.title3,
          item1: info.text9,
          item2: info.text2,
          item3: info.text10,
          item4: info.text11,
          result3: data.reservation.organization.identifier_user,
        };
      }
    }

    voo = {
      ...voo,
      result1: data.reservation?.airline?.name,
      result2: data.reservation?.airline?.flight_number,
      result3: data.reservation?.frequent_flier?.program,
      result4: data.reservation?.frequent_flier?.code,
    };

    return (
      <Container fluid className="m-0 p-0">
        <Row className="d-flex justify-content-center align-items-center w-100 m-0">
          <Container
            sm="12"
            md="10"
            lg="7"
            className="m-0 p-0 widthFixedDesktop"
          >
            <StepProgress valueOne={100} valueTwo={100} valueThree={100} />
            {typePayment.value === opcion1 && (
              <HeaderTransfer
                data={data.reservation}
                price={data.car.TotalCharge}
              />
            )}
            {typePayment.value === opcion2 && (
              <HeaderCreditCard
                data={data.reservation}
                price={data.car.TotalCharge}
              />
            )}
            {typePayment.value === opcion3 && (
              <HeaderInvoiced
                data={data.reservation}
                price={data.car.TotalCharge}
              />
            )}
            <Confirmation
              reservation={data.reservation}
              location={data.location}
              car={data.car}
              rental={data.rental_core}
              body={body}
            />
            <Taxes2 car={data.car} />
            <div className="s3-container-car">
              <CarSelected car={data.car} company={body.company} step={false} />
              <div className="s3-container-tarifa">
                <h6>{card.title3}</h6>
                <hr />
                <InfoRate car={data.car} />
              </div>
            </div>
            <div className="s3-container-loja">
              <h6>{location.title3}</h6>
              <div className="s3-container-divide">
                <Loja
                  title={location.title1}
                  location={data.location.PickUpLocation}
                />
                <Loja
                  title={location.title2}
                  location={data.location.ReturnLocation}
                />
              </div>
            </div>
            <div className="s3-container-div">
              <Agency info={agency} items={info} />
              <Agency info={voo} items={info} />
            </div>
            <Codes info={data.reservation} />
          </Container>
        </Row>
      </Container>
    );
  } else {
    return <div>{history.push(to.error)}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    typePayment: state.step4.typePayment,
    data: state.step4.data,
    dataReservation: state.step4.reservation,
    company: state.step1.companySelected,
  };
};

export default connect(mapStateToProps)(Step5);
