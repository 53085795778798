import Swal from 'sweetalert2';

export const handleError = (text, type) =>{

  if(type === 1){
      return  Swal.fire({
        html: `
        <div>
        <h5>Atenção!</h5>
        <p>${text}</p>
        </div>`,
        showConfirmButton: false,
        icon: 'error',
        position: 'top',
        timer: 4000,
      })
  } 
  if(type === 3){
      return  Swal.fire({
        html: `
        <div>
        <h5>Atenção!</h5>
        <p>${text}</p>
        </div>`,
        showConfirmButton: false,
        icon: 'info',
        position: 'top',
        timer: 6000,
      })
  } 
  else {
    return Swal.fire({
      html: `
      <div>
      <h5>Sucesso!</h5>
      <p>${text}</p>
      </div>`,
      showConfirmButton: false,
      icon: 'success',
      position: 'top',
      timer: 4000,
    })
  }   
      
};

export const handleWarning = (text) =>{

  return  Swal.fire({
    html: `
    <div>
    <h5>Sucesso!</h5>
    <p>${text}</p>
    </div>`,
    showConfirmButton: false,
    icon: 'success',
    position: 'top',
    showCloseButton: true,
  })
  
      
};