import Map from "../molecules/googleMaps";
import translate from "../../../translates/translations";
import Item from "./Item";

export default function Loja({ location, title }) {
  const { Schedule = {} } = location;
  const { days, phone } = translate.general;
  const icon = "/icon/chevron-right.svg";

  return (
    <div className="s3-loja-container">
      <h6>{title}:</h6>
      <span>
        {location.Name} ({location.Code})
      </span>
      <hr />
      <div className="mainBody-container">
        <div className="m-0 p-0 adress-container">
          <p>
            {location.Address.StreetNmbr} - {location.Address.PostalCode}{" "}
            {location.Address.CountryName &&
              `- ${location.Address.CountryName.Code}`}
          </p>
          <p>
            {phone}: {location.Telephone}
          </p>
        </div>
        <div className="s3-loja-div">
          {Object.keys(Schedule).map((day) => (
            <Item
              icon={icon}
              text={`${days[day]}: ${Schedule[day].start} - ${Schedule[day].end}`}
            />
          ))}
        </div>
        <div className="s3-loja-google">
          <Map
            lat={parseFloat(location.Latitude)}
            lon={parseFloat(location.Longitude)}
            zoom={13}
          />
        </div>
      </div>
    </div>
  );
}
