import { css } from "@emotion/core";
import PuffLoader from "react-spinners/PuffLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Loader(props) {
  return (
    <div className="container-loading">
      <div className="general-loading">
        <PuffLoader color="red" loading={true} css={override} size={90} />
      </div>
    </div>
  );
}

export default Loader;
