import { Container, Row, Col } from "reactstrap";
import translate from "../../../translates/translations";

function ModalPayment(props) {
  const { handleClose } = props;
  const { title3, title13, text } = translate.step4.payment;

  return (
    <Container fluid className="modal-inclusive-container fade-in">
      <Row className="d-flex justify-content-center align-items-center w-100">
        <Col className="d-flex justify-content-center align-items-center w-100">
          <Container fluid className="modal-inclusive">
            <Row className="d-flex justify-content-between w-100 modal-inclusive-row1">
              <Col sm="10" className="w-100 p-0">
                <span>{title3}</span>
              </Col>
              <Col sm="2" className="d-flex justify-content-end cursor p-0">
                <img
                  src="/icon/x_black.svg"
                  alt=""
                  onClick={() => handleClose()}
                />
              </Col>
            </Row>
            <Row className="w-100 p-0">
              <Col sm="12" className="modal-inclusive-img">
                <span>{title13}</span>
              </Col>
            </Row>
            <Row className="w-100 modal-inclusive-row2">
              <Col sm="12" className="modal-inclusive-text p-0">
                <div className="d-flex flex-column">
                  <p className="mb-3">{text}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalPayment;
